import React, { useCallback, useEffect, useState } from 'react';
import styles from './card.module.css'
import { Link, useNavigate } from 'react-router-dom';
import addimage from "../../../../../Assets/Images/addimage.png";
import cancelcross from "../../../../../Assets/Images/cancelcross.png"
import { AiOutlineDown, AiOutlineMore, AiOutlineUp } from 'react-icons/ai';
import { FiMoreVertical } from 'react-icons/fi';
import { deleteProperty } from '../../../../../Api/user';
import useLocalStorage from '../../../../../utils/useLocalStorage';
import { toast } from 'react-toastify';
import ConfirmDelete from '../popup/ConfirmDelete';

import { useRef } from "react";
import PropTypes from "prop-types";

const Card = ({ handleAddImage, data, fetchData }) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    alert("You clicked outside of me!");
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;

    const [img_notify, setImg_notify] = useState([]);
    const [option, setOption] = useState({ open: false, id: "" });
    const [deletepop, setDeletepop] = useState(false);

    const navigate = useNavigate();

    const handleAddImagecross = useCallback((id) => {
        const ids = [...img_notify]
        ids.push(id)
        setImg_notify(ids)
    }, [img_notify])

    const [cardlimit, setCardlimit] = useState(3)
    const handleLoadmore = useCallback((val) => {
        setCardlimit(val)
    }, [cardlimit])
    const handleOption = useCallback((id) => {
        if (Number(id) === option.id) {
            setOption({ open: !option.open, id: Number(id) })
        } else {
            setOption({ open: true, id: Number(id) })
        }
    }, [option])
    const handleDelete = useCallback(async (id) => {
        const apiResponse = await deleteProperty(id);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeUser();
                navigate('/login')
            } else {
                fetchData()
            }
        } else {
            // setPropertyServices([]);
            // setTotalPages(0);
        }
    }, [])
    const handleDeletePop = useCallback(() => {
        setDeletepop(!deletepop)
        setOption({ ...option, open: false })
    }, [deletepop, option])

    function useOutsideAlerter(ref) {
        // useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOption({ open: false, id: "" })
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // }, [ref]);
    }

    const a = data && data?.length
    return (
        <>
            {deletepop && <ConfirmDelete handleDeletePop={handleDeletePop} handleDelete={handleDelete} id={option.id} />}
            {data && data.slice(0, cardlimit)
                .map(val => (
                    <div className={styles.main_container} key={val.id}>
                        <div className={val.is_approved === 1 && val.is_active === 1 && val.sold_out === 1 ? styles.card_container_sold : styles.card_container}>
                            <div className={styles.card_flex_img}>
                                <img onClick={val.thumbnail.length === 0 ? () => handleAddImage(val) : undefined} alt='img' src={val.thumbnail.length === 0 ? addimage : BASE_URL + val.thumbnail} height='100%' width="100%" className={val.images.length === 0 ? styles.prpty_add_img : styles.prpty_img} />
                                {/* <div className='section-image' /> */}
                                {val.is_approved === 1 ? val.is_active === 1 ?
                                    <label className={styles.status_tag}>Active</label>
                                    : val.sold_out === 1 ? <label className={styles.status_tag}>Sold Out</label>
                                        : <label className={styles.status_tag}>Not Active</label>
                                    :
                                    <label className={styles.status_tag}>Verification Pending</label>
                                }
                            </div>
                            <div className={styles.card_flex_detail}>
                                <div className={styles.more_option_div}>
                                    <h6 className={styles.prp_name}>{val.name}</h6>
                                    <div >
                                        <FiMoreVertical id={val.id} style={{ cursor: "pointer" }} onClick={() => handleOption(val.id)} />
                                        {(option.open && option.id === val.id) &&
                                            <div ref={wrapperRef}   >
                                                {/* <div className={styles.triangle_up}></div> */}
                                                <div className={styles.edit_del_btns}>
                                                    <p onClick={() => navigate(`/editproperties/${val.id}`)}>Edit</p>
                                                    <p onClick={handleDeletePop}>Delete</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <ul className={styles.location}>
                                    <li>{val.address}, {val.city}, {val.state} </li>
                                    <li className={styles.dot}></li>
                                    <li>Show on map  </li>
                                    <li className={styles.dot}></li>
                                    <li className={styles.location_helper} >8.2 miles from center:</li>
                                </ul>
                                <p className={styles.about_prp}>{val.property_type_name}</p>

                                <ul className={styles.prprty_response}>
                                    <span>
                                        {/* <li>0 Views</li> */}
                                        {/* <li className={styles.dot}></li> */}
                                        {/* <li>0 Responsees</li> */}
                                    </span>
                                    <Link to={`/propertylists/${val.id}`}>
                                        <button type='submit'  >View Property</button>
                                    </Link>
                                </ul>
                            </div>
                        </div >
                        {
                            (val.thumbnail.length === 0 && !img_notify?.includes(val.id)) ?
                                <div className={styles.image_notify}>
                                    <label className={styles.image_notify_txt}>
                                        Properties with images has high chance of getting responses.<u style={{ cursor: "pointer" }} onClick={() => handleAddImage(val)}> Add Photo</u>
                                    </label>
                                    <img onClick={() => handleAddImagecross(val.id)} src={cancelcross} style={{ cursor: "pointer" }} height="12px" width='12px' alt="icon" />
                                </div> : ""
                        }
                    </div >
                ))}
            {
                data && data?.length > 3 && (
                    (cardlimit < a) ?
                        (<div div className={styles.load_more_btn}>
                            <label onClick={() => handleLoadmore(data && data?.length)}>Load More  <AiOutlineDown color="#0084D1" /></label>
                        </div >)
                        : (<div className={styles.load_more_btn}>
                            <label onClick={() => handleLoadmore(3)}> Show Less <AiOutlineUp color="#0084D1" /></label>
                        </div>)

                )
            }
        </>
    );
}

Card.propTypes = {
    children: PropTypes.element.isRequired
};
export default Card;