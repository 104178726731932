import api from "../utils/api";
import { toast } from "react-toastify";
import useLocalStorage from "../utils/useLocalStorage";

export const serviceProviderLogin = async (data) => {
  const apiResponse = await api.applicationApi(
    "serviceProvider/login",
    "POST",
    data,
    null
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      useLocalStorage.setServiceProvider(apiResponse.data.token);
      return apiResponse.data.data;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const serviceProviderLogout = async () => {
  const apiResponse = await api.applicationApi(
    "serviceProvider/logout",
    "POST",
    null,
    2
  );
  console.log(apiResponse, "response");
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const fetchServiceProviderDetails = async () => {
  const apiResponse = await api.applicationApi(
    "serviceProvider/fetchServiceProviderDetails",
    "POST",
    null,
    2
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const editServiceProvider = async (data) => {
  const apiResponse = await api.multipartApi(
    "serviceProvider/editServiceProvider",
    "PUT",
    data,
    2
  );
  console.log(apiResponse, "response");
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const fetchServiceProviders = async (data) => {
  const apiResponse = await api.applicationApi(
    "serviceProvider/fetchServiceProviders",
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const addServiceProvider = async (data) => {
  const apiResponse = await api.applicationApi(
    "serviceProvider/addServiceProvider",
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const editServiceProviderById = async (id, data) => {
  const apiResponse = await api.applicationApi(
    `serviceProvider/editServiceProvider/${id}`,
    "PUT",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const activateDeactivateServiceProvider = async (id, data) => {
  const apiResponse = await api.applicationApi(
    `serviceProvider/activateDeactivateServiceProvider/${id}`,
    "PATCH",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

// Service Povider Panel Api

export const addProperty = async (data) => {
  const apiResponse = await api.multipartApi(
    "property/addProperty",
    "POST",
    data,
    2
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const editProperty = async (id, data) => {
  const apiResponse = await api.multipartApi(
    `property/editProperty/${id}`,
    "PUT",
    data,
    2
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const fetchPropertyAddEditData = async (data) => {
  const apiResponse = await api.applicationApi(
    "property/fetchPropertyAddEditData",
    "POST",
    data,
    2
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const fetchProperties = async (data) => {
  const apiResponse = await api.applicationApi(
    "property/fetchProperties",
    "POST",
    data,
    2
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const activateDeactivateProperty = async (id, data) => {
  const apiResponse = await api.applicationApi(
    `property/activateDeactivateProperty/${id}`,
    "PATCH",
    data,
    2
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const fetchActivePropertyTypes = async (data) => {
  const apiResponse = await api.applicationApi(
    "propertyTypes/fetchActivePropertyTypes",
    "POST",
    data,
    2
  );
  console.log(apiResponse, "apiresponse");
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else {
      if (apiResponse.status === 401) {
        return 401;
      }
      if (apiResponse.status === 403) {
        toast.error(apiResponse.data.message);
      }
      return false;
    }
  } else {
    return false;
  }
};

export const serviceProviderChangePassword = async (data) => {
  const apiResponse = await api.applicationApi(
    `serviceProvider/changePassword`,
    "PATCH",
    data,
    2
  );
  console.log(apiResponse, "response");
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const forgotPassword = async (data) => {
  const apiResponse = await api.applicationApi(
    `serviceProvider/forgotPassword`,
    "POST",
    data,
    0
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else {
      return apiResponse.data.message;
    }
  } else {
    return false;
  }
};

export const resetPassword = async (data) => {
  const apiResponse = await api.applicationApi(
    `serviceProvider/resetPassword`,
    "PATCH",
    data,
    0
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const serviceProviderVerification = async (data) => {
  const apiResponse = await api.applicationApi(
    `serviceProvider/serviceProviderVerification`,
    "PATCH",
    data,
    0
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const addBid = async (data) => {
  const apiResponse = await api.applicationApi(
    "serviceProvider/addBid",
    "POST",
    data,
    2
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const fetchUserProperties = async (data) => {
  const apiResponse = await api.applicationApi(
    "property/fetchUserProperties",
    "POST",
    data,
    2
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const fetchPropertyById = async (id) => {
  const apiResponse = await api.applicationApi(`property/fetchProperty/${id}`, 'POST', null, 2);
  if (apiResponse) {
      if (apiResponse.status === 200) {
          return apiResponse.data.data;
      } else {
          return false;
      }
  } else {
      return false;
  }
}
