import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmBox from "../../../../Components/Popup/User/ConfirmBox";
import useLocalStorage from "../../../../utils/useLocalStorage";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import src from "../../../../Assets/Images/Card 5.jpg";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
function SellProperty(user) {
  const [confirmBox, showConfirmBox] = useState(false);
  const navigate = useNavigate();

  const sellPropertyHandler = useCallback(() => {
    if (user.user.isAuthenticated && useLocalStorage.getUser()) {
      navigate("/services/realestate/sellproperty");
    } else {
      showConfirmBox(true);
    }
  }, []);
  const confirmBoxHandler = () => {
    showConfirmBox(!confirmBox);
  };
  return (
    <div className="wrapper realestate-top">
      {confirmBox && <ConfirmBox id="" hideConfirmBox={confirmBoxHandler} />}
      <div className="content-wrapper">
        <div className="content">
          <div className="image-slider">
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={false}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              pagination={{ el: ".swiper-pagination", clickable: true }}
              modules={[EffectCoverflow, Pagination]}
              className="swiper_container"
            >
              <SwiperSlide>
                <img src={src} alt="slide_image" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={src} alt="slide_image" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={src} alt="slide_image" />
              </SwiperSlide>

              <div className="slider-controler">
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>
          </div>
          <div className="sell-property">
            <p>Sell your property with 100% trust and zero hassle </p>
            <button type="button" onClick={() => sellPropertyHandler()}>
              Post Your Property
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, null)(SellProperty);
