import React, { useCallback, useState, useEffect } from 'react';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { activateDeactivateSlot, fetchSlots } from '../../../Api/schedules';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../../../utils/useLocalStorage';


const ViewSlots = ({ viewData, hideViewModal, logout }) => {

    const navigate = useNavigate();
    const [slots, setSlots] = useState([]);

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchSlots(viewData.id);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                logout();
                navigate('/admin/login')
            } else {
                setSlots(apiResponse);
            }
        } else {
            setSlots([]);
        }
    }, [])

    const activateDeactivateHandler = async (data) => {
        let status = 0;
        if (data.is_active === 0) {
            status = 1;
        }

        const apiResponse = await activateDeactivateSlot(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                logout();
                navigate('/admin/login')
            } else {
                fetchData();
            }
        }
    }

    return (
        <div className='modal-container'>
            <div className='modal'>
                <div className='header'>
                    <h3>Slots</h3>
                    <button className='close-btn' onClick={hideViewModal}>
                        <FaTimes size={16} />
                    </button>
                </div>
                <div className='content'>
                {(slots && slots.length > 0) && (
                    <>
                        <div className='table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th >Sr. No.</th>
                                        <th>Slots</th>
                                        <th>Created At</th>
                                        <th>Last Updated On</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slots.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.time}</td>
                                            <td>{item.created_at}</td>
                                            <td>{item.updated_at}</td>
                                            <td>
                                                <span className={item.is_active === 1 ? 'active' : 'deactive'} >
                                                    {item.is_active === 1 ? 'Active' : 'Not Active'}
                                                </span>
                                            </td>
                                            <td>
                                                <div className='table-actions'>
                                                    <button className={item.is_active === 0 ? 'activate-btn' : 'deactivate-btn'} onClick={() => activateDeactivateHandler(item)}>
                                                        {item.is_active === 0 ? "Activate" : "Deactivate"}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(ViewSlots);