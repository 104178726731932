import React, { useEffect } from "react";
import Hero from "./Hero";
import Testimonials from "./Testimonials";
import Services from "./Services";
import Works from "./Works";
import Offer from "./Offer";
import Faqs from "./Faqs";
import Partners from "./Partners";
import "./landing.css";
import { addTotalVisit } from "../../Api/user";

const Landing = () => {
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await addTotalVisit();
  };

  return (
    <div className="container">
      <Hero />
      <Partners />
      <Testimonials />
      <Services />
      <Works />
      <Offer />
      <Faqs />
    </div>
  );
};

export default Landing;
