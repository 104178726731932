import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AiOutlineMenu,
  AiOutlineTransaction,
  AiTwotoneSetting,
} from "react-icons/ai";
import { BsFillBellFill, BsFillPeopleFill } from "react-icons/bs";
import { RiDashboardFill, RiAdminFill, RiBuildingFill } from "react-icons/ri";
import {
  FaUser,
  FaUserTie,
  FaRegUserCircle,
  FaTimes,
  FaUsers,
} from "react-icons/fa";
import { BiFilter } from "react-icons/bi";
import { GrLogout } from "react-icons/gr";
import {
  MdArticle,
  MdCalendarToday,
  MdDescription,
  MdListAlt,
  MdLiveHelp,
  MdPaid,
  MdRequestPage,
  MdSource,
  MdSubject,
  MdMapsHomeWork,
  MdOutlineContacts,
  MdPayments,
  MdPayment,
} from "react-icons/md";
import { CgMenuRight } from "react-icons/cg";
import {
  activateSidebarRequest,
  deactivateSidebarRequest,
} from "../../Actions/sidebar";
import { loadAdminRequest, logoutAdminRequest } from "../../Actions/admin";
import { adminLogout, fetchAdminDetails } from "../../Api/admin";
import useLocalStorage from "../../utils/useLocalStorage";
import "./navbar.css";
import { FiUser } from "react-icons/fi";

const AdminNavbar = ({
  sidebar,
  activate,
  deactivate,
  admin,
  loadAdmin,
  logout,
}) => {
  const navigate = useNavigate();

  const settingsBtn = useRef(null);
  const menuRef = useRef(null);

  const path = useLocation().pathname;
  const [show, setShow] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState(false);
  const [user, setUser] = useState(false);
  const [propertyServices, setPropertyServices] = useState(false);
  const [financialServices, setFinancialServices] = useState(false);

  useEffect(() => {
    if (
      path.includes("admin") &&
      admin.isAuthenticated &&
      !path.includes("/admin/login")
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [path, admin]);

  useEffect(() => {
    if (path.includes("admin") && useLocalStorage.getAdmin()) {
      fetchData();
    }
  }, []);

  const clickHandler = (event) => {
    if (
      settingsBtn.current?.contains(event.target) ||
      menuRef.current?.contains(event.target)
    ) {
      setSettingsMenu(true);
    } else {
      setSettingsMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, []);

  const logoutHandler = async () => {
    const apiResponse = await adminLogout();
    if (apiResponse) {
      useLocalStorage.removeAdmin();
      logout();
      navigate("/admin/login");
    }
  };

  const fetchData = async () => {
    const bactrackUrl = useLocalStorage.getBacktrackUrl();
    const apiResponse = await fetchAdminDetails();
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeAdmin();
        logout();
        navigate("/admin/login");
      } else {
        loadAdmin(apiResponse);
        if (
          bactrackUrl &&
          bactrackUrl.includes("admin") &&
          !bactrackUrl.includes("/admin/login")
        ) {
          navigate(bactrackUrl);
        } else {
          navigate("/admin/dashboard");
        }
      }
    } else {
      useLocalStorage.removeAdmin();
      logout();
      navigate("/admin/login");
    }
  };

  const showUser = () => {
    setUser(!user);
  };

  const showPropertyServices = () => {
    setPropertyServices(!propertyServices);
  };

  const showFinancialServices = () => {
    setFinancialServices(!financialServices);
  };

  return (
    <>
      {show && (
        <div className="admin-header">
          <div className={sidebar ? "sidebar active" : "sidebar"}>
            <div className="header">
              <p>LOGO</p>
              <button className="close-btn" onClick={deactivate}>
                <FaTimes size={20} />
              </button>
            </div>
            <ul className="tabs">
              <li
                className={
                  path.includes("/admin/dashboard") ? "link active" : "link"
                }
              >
                <Link to="/admin/dashboard">
                  <RiDashboardFill size={16} />
                  Dashboard
                </Link>
              </li>
              <li className="sub-tabs link">
                <button
                  className={
                    path.includes("/admin/superAdmin") ||
                    path.includes("/admin/admins") ||
                    path.includes("/admin/serviceProviders") ||
                    path.includes("/admin/users") ||
                    path.includes("/admin/builders")
                      ? "button active"
                      : "button"
                  }
                  onClick={showUser}
                >
                  <FaUsers size={16} />
                  Users
                </button>
                {user && (
                  <ul>
                    {admin.data.role === 1 && (
                      <li
                        className={
                          path.includes("/admin/superAdmin")
                            ? "link active"
                            : "link"
                        }
                      >
                        <Link to="/admin/superAdmin">
                          <BsFillPeopleFill size={16} />
                          Super Admin
                        </Link>
                      </li>
                    )}
                    {(admin.data.role === 1 || admin.data.role === 2) && (
                      <>
                        <li
                          className={
                            path.includes("/admin/admins")
                              ? "link active"
                              : "link"
                          }
                        >
                          <Link to="/admin/admins">
                            <RiAdminFill size={16} />
                            Admins
                          </Link>
                        </li>
                        <li
                          className={
                            path.includes("/admin/serviceProviders")
                              ? "link active"
                              : "link"
                          }
                        >
                          <Link to="/admin/serviceProviders">
                            <FaUserTie size={16} />
                            Service Providers
                          </Link>
                        </li>
                      </>
                    )}
                    <li
                      className={
                        path.includes("users") ? "link active" : "link"
                      }
                    >
                      <Link to="/admin/users">
                        <FaUser size={16} />
                        Users
                      </Link>
                    </li>
                    {(admin.data.role === 1 || admin.data.role === 2) && (
                      <li
                        className={
                          path.includes("builders") ? "link active" : "link"
                        }
                      >
                        <Link to="/admin/builders">
                          <FiUser size={16} />
                          Builders
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>

              {(admin.data.role === 1 ||
                admin.data.role === 2 ||
                (admin.data.role === 3 &&
                  admin.data.service_ids.split(",").indexOf("1") !== -1)) && (
                <li className="sub-tabs link">
                  <button
                    className={
                      path.includes("/admin/properties") ||
                      path.includes("/admin/propertyTypes") ||
                      path.includes("/admin/propertyServices") ||
                      path.includes("/admin/filters") ||
                      path.includes("/admin/schedules") ||
                      path.includes("/admin/enquiries")
                        ? "button active"
                        : "button"
                    }
                    onClick={showPropertyServices}
                  >
                    <MdMapsHomeWork size={16} />
                    Property Services
                  </button>
                  {propertyServices && (
                    <ul>
                      <li
                        className={
                          path.includes("properties") ? "link active" : "link"
                        }
                      >
                        <Link to="/admin/properties">
                          <RiBuildingFill size={16} />
                          Properties
                        </Link>
                      </li>
                      {(admin.data.role === 1 || admin.data.role === 2) && (
                        <>
                          <li
                            className={
                              path.includes("propertyTypes")
                                ? "link active"
                                : "link"
                            }
                          >
                            <Link to="/admin/propertyTypes">
                              <MdSource size={16} />
                              Types
                            </Link>
                          </li>
                          <li
                            className={
                              path.includes("propertyServices")
                                ? "link active"
                                : "link"
                            }
                          >
                            <Link to="/admin/propertyServices">
                              <MdArticle size={16} />
                              Services
                            </Link>
                          </li>
                          <li
                            className={
                              path.includes("filters") ? "link active" : "link"
                            }
                          >
                            <Link to="/admin/filters">
                              <BiFilter size={16} />
                              Filters
                            </Link>
                          </li>
                          <li
                            className={
                              path.includes("schedules")
                                ? "link active"
                                : "link"
                            }
                          >
                            <Link to="/admin/schedules">
                              <MdCalendarToday size={16} />
                              Schedules
                            </Link>
                          </li>
                        </>
                      )}
                      <li
                        className={
                          path.includes("realestateEnquiries")
                            ? "link active"
                            : "link"
                        }
                      >
                        <Link to="/admin/realestateEnquiries">
                          <MdLiveHelp size={16} />
                          RealEstate Enquiries
                        </Link>
                      </li>
                      <li
                        className={
                          path.includes("priceconsultation")
                            ? "link active"
                            : "link"
                        }
                      >
                        <Link to="/admin/priceconsultation">
                          <MdLiveHelp size={16} />
                          Price Consultation Queries
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              )}

              {(admin.data.role === 1 ||
                admin.data.role === 2 ||
                (admin.data.role === 3 &&
                  admin.data.service_ids.split(",").indexOf("2") !== -1)) && (
                <li className="sub-tabs link">
                  <button
                    className={
                      path.includes("categories") ||
                      path.includes("plans") ||
                      path.includes("addons")
                        ? "button active"
                        : "button"
                    }
                    onClick={showFinancialServices}
                  >
                    <MdMapsHomeWork size={16} />
                    Financial Services
                  </button>
                  {financialServices && (
                    <ul>
                      <li
                        className={
                          path.includes("categories") ? "link active" : "link"
                        }
                      >
                        <Link to="/admin/financial/categories">
                          <MdDescription size={16} />
                          Categories
                        </Link>
                      </li>
                      <li
                        className={
                          path.includes("plans") ? "link active" : "link"
                        }
                      >
                        <Link to="/admin/financial/plans">
                          <MdRequestPage size={16} />
                          Plans
                        </Link>
                      </li>
                      <li
                        className={
                          path.includes("addons") ? "link active" : "link"
                        }
                      >
                        <Link to="/admin/financial/addons">
                          <MdPaid size={16} />
                          Addons
                        </Link>
                      </li>
                      <li
                        className={
                          path.includes("pricings") ? "link active" : "link"
                        }
                      >
                        <Link to="/admin/financial/pricings">
                          <MdPaid size={16} />
                          Pricings
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              )}

              {(admin.data.role === 1 ||
                admin.data.role === 2 ||
                admin.data.role === 3) && (
                <li
                  className={
                    path.includes("admin/appointments") ? "link active" : "link"
                  }
                >
                  <Link to="/admin/appointments">
                    <MdOutlineContacts size={16} />
                    Appointments
                  </Link>
                </li>
              )}

              {(admin.data.role === 1 || admin.data.role === 2) && (
                <>
                  <li
                    className={path.includes("cases") ? "link active" : "link"}
                  >
                    <Link to="/admin/cases">
                      <MdListAlt size={16} />
                      Cases
                    </Link>
                  </li>
                  <li
                    className={
                      path.includes("customPayments") ? "link active" : "link"
                    }
                  >
                    <Link to="/admin/customPayments">
                      <MdPayment size={16} />
                      Custom Payments
                    </Link>
                  </li>
                  <li
                    className={
                      path.includes("transactions") ? "link active" : "link"
                    }
                  >
                    <Link to="/admin/transactions">
                      <AiOutlineTransaction size={16} />
                      Transactions
                    </Link>
                  </li>
                  <li
                    className={
                      path.includes("testimonials") ? "link active" : "link"
                    }
                  >
                    <Link to="/admin/testimonials">
                      <FaUsers size={16} />
                      Testimonials
                    </Link>
                  </li>
                  <li
                    className={
                      path.includes("generalEnquiries") ? "link active" : "link"
                    }
                  >
                    <Link to="/admin/generalEnquiries">
                      <MdLiveHelp size={16} />
                      General Enquiries
                    </Link>
                  </li>
                  <li
                    className={path.includes("faqs") ? "link active" : "link"}
                  >
                    <Link to="/admin/faqs">
                      <MdSubject size={16} />
                      FAQs
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <button onClick={activate} className="open-btn">
            {sidebar ? <CgMenuRight size={16} /> : <AiOutlineMenu size={16} />}
          </button>
          <button ref={settingsBtn}>
            <AiTwotoneSetting size={16} />
          </button>
          {settingsMenu && (
            <ul className="admin-settings-menu" ref={menuRef}>
              <li>
                <Link to="/admin/profile">
                  <FaRegUserCircle size={16} />
                  My Profile
                </Link>
              </li>
              <li>
                <button onClick={logoutHandler} type="button">
                  <GrLogout size={16} />
                  Logout
                </button>
              </li>
            </ul>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      activate: activateSidebarRequest,
      deactivate: deactivateSidebarRequest,
      loadAdmin: loadAdminRequest,
      logout: logoutAdminRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
