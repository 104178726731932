import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Store from './Store';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import AdminNavbar from './Components/Navbar/AdminNavbar';
import AllRoutes from './AllRoutes';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css'
import './style.css';
import './static.css'
import ServiceProviderNavbar from './Components/Navbar/ServiceProviderNavbar';

function App() {

  return (
    <Provider store={Store}>
      <BrowserRouter>
        <ToastContainer />
        <AdminNavbar />
        <ServiceProviderNavbar />
        <Navbar />
        <AllRoutes />
        <Footer />
      </BrowserRouter>
    </Provider>
  )
}

export default App
