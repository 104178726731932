import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AiOutlineMenu, AiTwotoneSetting } from "react-icons/ai";
import { BsFillBellFill } from "react-icons/bs";
import { RiBuildingFill } from "react-icons/ri";
import { FaRegUserCircle, FaTimes } from "react-icons/fa";
import { GrLogout } from "react-icons/gr";
import { CgMenuRight } from "react-icons/cg";
import {
  activateSidebarRequest,
  deactivateSidebarRequest,
} from "../../Actions/sidebar";
import useLocalStorage from "../../utils/useLocalStorage";
import "./navbar.css";
import {
  loadServiceProviderRequest,
  logoutServiceProviderRequest,
} from "../../Actions/serviceProvider";
import {
  fetchServiceProviderDetails,
  serviceProviderLogout,
} from "../../Api/serviceProvider";

const ServiceProviderNavbar = ({
  sidebar,
  activate,
  deactivate,
  serviceProvider,
  loadServiceProvider,
  logout,
}) => {
  const navigate = useNavigate();

  const settingsBtn = useRef(null);
  const menuRef = useRef(null);

  const path = useLocation().pathname;
  const [show, setShow] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState(false);

  useEffect(() => {
    if (
      path.includes("serviceProvider") &&
      serviceProvider.isAuthenticated &&
      !path.includes("/serviceProvider/login")
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [path, serviceProvider]);

  useEffect(() => {
    if (
      path.includes("serviceProvider") &&
      useLocalStorage.getServiceProvider()
    ) {
      fetchData();
    }
  }, []);

  const clickHandler = (event) => {
    if (
      settingsBtn.current?.contains(event.target) ||
      menuRef.current?.contains(event.target)
    ) {
      setSettingsMenu(true);
    } else {
      setSettingsMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, []);

  const logoutHandler = async () => {
    const response = await serviceProviderLogout();
    if (response) {
      useLocalStorage.removeServiceProvider();
      navigate("/serviceProvider/login");
    }
  };

  const fetchData = async () => {
    const bactrackUrl = useLocalStorage.getBacktrackUrl();
    const apiResponse = await fetchServiceProviderDetails();
    if (apiResponse) {
      if (apiResponse === 401) {
        logout();
        navigate("/serviceProvider/login");
      } else {
        loadServiceProvider(apiResponse);
        if (
          bactrackUrl &&
          bactrackUrl.includes("serviceProvider") &&
          !bactrackUrl.includes("/serviceProvider/login")
        ) {
          navigate(bactrackUrl);
        } else {
          navigate("/serviceProvider/properties");
        }
      }
    } else {
      navigate("/serviceProvider/login");
    }
  };

  return (
    <>
      {show && (
        <div className="admin-header">
          <div className={sidebar ? "sidebar active" : "sidebar"}>
            <div className="header">
              <div className="logo" onClick={() => navigate("/")}>
                <div className="sign"></div>
              </div>
              <button className="close-btn" onClick={deactivate}>
                <FaTimes size={20} />
              </button>
            </div>
            <ul className="tabs">
              {serviceProvider.data.role === 4 && (
                <>
                  <li
                    className={
                      path.includes("properties") ? "link active" : "link"
                    }
                  >
                    <Link to="/serviceProvider/properties">
                      <RiBuildingFill size={18} />
                      Properties
                    </Link>
                  </li>
                  <li
                    className={
                      path.includes("userproperty") ? "link active" : "link"
                    }
                  >
                    <Link to="/serviceProvider/userproperty">
                      <RiBuildingFill size={18} />
                      User Properties
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <button onClick={activate} className="open-btn">
            {sidebar ? <CgMenuRight size={16} /> : <AiOutlineMenu size={16} />}
          </button>
          <button ref={settingsBtn}>
            <AiTwotoneSetting size={16} />
          </button>
          <button>
            <BsFillBellFill size={16} />
          </button>
          {settingsMenu && (
            <ul className="admin-settings-menu" ref={menuRef}>
              <li>
                <Link to="/serviceProvider/profile">
                  <FaRegUserCircle size={16} />
                  My Profile
                </Link>
              </li>
              <li>
                <button onClick={logoutHandler} type="button">
                  <GrLogout size={16} />
                  Logout
                </button>
              </li>
            </ul>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  serviceProvider: state.serviceProvider,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      activate: activateSidebarRequest,
      deactivate: deactivateSidebarRequest,
      loadServiceProvider: loadServiceProviderRequest,
      logout: logoutServiceProviderRequest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProviderNavbar);
