import React, { useState, useCallback, useEffect } from "react";
import { GrClose } from "react-icons/gr";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FaRegCheckCircle } from "react-icons/fa";
import { showSlots } from "../../Api/schedules";
import "./appointment.css";
import { bookAppointment, editAppointment } from "../../Api/appointment";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import "moment-timezone";

import useLocalStorage from "../../utils/useLocalStorage";

const Appointment = ({ close }) => {
  const navigate = useNavigate();

  const days = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [state, setState] = useState({
    date: "",
    time_type: "AM",
  });

  const [form, setForm] = useState({
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    query: "Hi, I am interested in this property.",
    date: "",
    service_id: 0,
    slot_id: 0,
  });

  const [weeklyDates, setWeeklyDates] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [selectedDate, setSelectedDate] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [emailError, setEmailError] = useState("");
  const { id } = useParams();
  const [slots, setSlots] = useState([]);
  const [error, setError] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);

  const changeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;

      // Create a regular expression pattern for email validation
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;

      // Check if the input name is "email" and validate the email format
      if (name === "email") {
        if (!emailPattern.test(value)) {
          setEmailError("Please enter a valid email address");
        } else {
          setEmailError(""); // Clear the email error if the input is valid
        }
      }
      setForm((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    },
    [form]
  );
  const fetchDates = useCallback(() => {
    const tempWeeklyData = [];
    for (let i = 0; i < 7; i++) {
      var date = new Date(
        new Date().setDate(new Date().getDate() + (currentWeek * 7 + i))
      );
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = date.getFullYear();
      const weekDay = date.getDay();
      tempWeeklyData.push({
        day: dd,
        month: mm,
        year: yyyy,
        week_day: weekDay,
      });
    }
    setWeeklyDates([...tempWeeklyData]);
    setSelectedDate(0);
    let timeType = "AM";
    if (currentWeek === 0 && selectedDate === 0) {
      if (
        new Date(
          new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
        ).getHours() >= 12
      ) {
        timeType = "PM";
      }
    }
    setState({
      date: `${tempWeeklyData[0].year}-${tempWeeklyData[0].month}-${tempWeeklyData[0].day}`,
      time_type: timeType,
    });
  }, [currentWeek]);

  useEffect(() => {
    fetchDates();
  }, [currentWeek]);

  const fetchSlots = useCallback(async () => {
    if (state.time_type && state.date) {
      const apiResponse = await showSlots(state);
      if (apiResponse) {
        const convertedSlots = convertTimeSlotsToUserTimezone(apiResponse);
        console.log(convertedSlots);
        setSlots([...convertedSlots]);
      } else {
        setSlots([]);
      }
    }
  }, [state]);

  useEffect(() => {
    fetchSlots();
  }, [state]);

  const timeTypeHandler = (value) => {
    setState({
      ...state,
      time_type: value,
    });
  };
  const convertTimeSlotsToUserTimezone = (slots) => {
    const userTimezone = moment.tz.guess(); // Get the user's timezone
    const istTimezone = "Asia/Kolkata"; // Assuming your default time is in IST
    const convertedSlots = [];

    slots.forEach((slot) => {
      const istTime = `${slot.time}:00`; // Add seconds to the time
      const utcTimestamp = moment
        .tz(istTime, "HH:mm:ss", istTimezone)
        .valueOf(); // Convert to UTC timestamp
      const timeInUserTimezone = moment(utcTimestamp)
        .tz(userTimezone)
        .format("hh:mm");
      convertedSlots.push({ ...slot, time: timeInUserTimezone });
    });

    return convertedSlots;
  };
  const selectedDateHandler = useCallback(
    (index) => {
      setSelectedDate(index);
      setState({
        ...state,
        date: `${weeklyDates[index].year}-${weeklyDates[index].month}-${weeklyDates[index].day}`,
      });
    },
    [selectedDate, state]
  );

  const nextWeekHandler = useCallback(() => {
    if (currentWeek < 3) {
      setCurrentWeek(currentWeek + 1);
    }
  }, [currentWeek]);

  const previousWeekHandler = useCallback(() => {
    if (currentWeek > 0) {
      setCurrentWeek(currentWeek - 1);
    }
  }, [currentWeek]);
  const bookAppointmentHandler = useCallback(
    async (e) => {
      e.preventDefault();
      const cleanData = {
        first_name: form.first_name.toLowerCase().trim(),
        last_name: form.last_name.toLowerCase().trim(),
        email: form.email.toLowerCase().trim(),
        phone: form.phone,
        query: form.query.trim(),
        date: state.date,
        slot_id: form.slot_id,
        service_id: form.service_id,
      };
      if (
        cleanData.first_name &&
        cleanData.last_name &&
        cleanData.email &&
        cleanData.phone &&
        cleanData.query &&
        cleanData.slot_id
      ) {
        if (cleanData.phone.length !== 10) {
          setError("Please enter a valid mobile number");
        } else {
          let apiResponse;
          if (appointmentId) {
            apiResponse = await editAppointment(appointmentId, cleanData);
          } else {
            apiResponse = await bookAppointment(cleanData);
          }
          if (apiResponse) {
            if (!appointmentId) {
              setAppointmentId(apiResponse.appointment_id);
            }
            fetchSlots();
            setShowConfirmation(!showConfirmation);
          }
          setForm({
            ...cleanData,
          });
        }
      } else {
        setError("Please fill all mandatory fields");
      }
    },
    [form, state]
  );

  const modifyAppointmentHandler = () => {
    setShowConfirmation(!showConfirmation);
  };

  const slotHandler = (id) => {
    setForm({
      ...form,
      slot_id: id,
    });
  };

  return (
    <>
      <div className="appointment-modal">
        {showConfirmation ? (
          <div className="appointment-wrapper">
            <button className="close-btn" onClick={close}>
              <GrClose size={20} />
            </button>
            <div className="book-appointment">
              <FaRegCheckCircle size={30} color="#00AE4D" />
              <p>Congratulations!</p>
              <p>
                Your appointment has been booked for <br />
                {weeklyDates && (
                  <span>
                    {`${days[weeklyDates[selectedDate].week_day]} ${
                      weeklyDates[selectedDate].day
                    } ${months[
                      parseInt(weeklyDates[selectedDate].month) - 1
                    ].substring(0, 3)} ${
                      weeklyDates[selectedDate].year
                    }, ${slots
                      .filter((slot) => slot.id === form.slot_id)
                      .map((slot) => `${slot.time} ${state.time_type}`)
                      .join(", ")}`}
                  </span>
                )}
                <br />
                Successfully !!!
                <p>Our Expert will reach out to you at the scheduled Time.</p>
              </p>
              <button className="action" onClick={modifyAppointmentHandler}>
                Modify Appointment
              </button>
            </div>
          </div>
        ) : (
          <div className="appointment-wrapper">
            <button className="close-btn" onClick={close}>
              <GrClose size={20} />
            </button>
            <section className="form">
              <h2>Appointment</h2>
              <p>
                Book a free 20 min appointment with our expert. Our experts will
                guide you through the complexities.
              </p>
              <div className="input-group">
                <div
                  className={form.firstName ? "input-box active" : "input-box"}
                >
                  <label>First Name</label>
                  <input
                    onChange={changeHandler}
                    value={form.first_name}
                    type="text"
                    name="first_name"
                    placeholder="First Name*"
                    required
                  />
                </div>
                <div
                  className={form.lastName ? "input-box active" : "input-box"}
                >
                  <label>Last Name</label>
                  <input
                    onChange={changeHandler}
                    value={form.last_name}
                    type="text"
                    name="last_name"
                    placeholder="Last Name*"
                    required
                  />
                </div>
              </div>
              <div className={form.email ? "input-box active" : "input-box"}>
                <label>Email</label>
                <input
                  onChange={changeHandler}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  value={form.email}
                  name="email"
                  placeholder="Enter your email*"
                  required
                />
              </div>
              {emailError && <p className="error-message">{emailError}</p>}
              <div className={form.phone ? "input-box active" : "input-box"}>
                <label>Phone</label>
                <input
                  onChange={changeHandler}
                  value={form.phone}
                  pattern="[1-9]{1}[0-9]{9}"
                  name="phone"
                  type="number"
                  placeholder="Enter your contact number*"
                  required
                />
              </div>
              <div
                className={
                  form.service_id !== 0 ? "input-box active" : "input-box"
                }
              >
                <select
                  name="service_id"
                  value={form.service_id}
                  onChange={changeHandler}
                >
                  <option value={0}>Select Services</option>
                  <option value={1}>Real Estate Services</option>
                  <option value={2}>Financial Services</option>
                </select>
              </div>
              <textarea
                rows={3}
                onChange={changeHandler}
                value={form.query}
                name="query"
                type="number"
                required
              >
                {form.query}
              </textarea>
            </section>
            <section className="schedules">
              <div className="availability">
                <p>Available Date and Timeslot</p>
                {weeklyDates && (
                  <span>{`${days[weeklyDates[selectedDate].week_day]}, ${
                    weeklyDates[selectedDate].day
                  } ${months[
                    parseInt(weeklyDates[selectedDate].month) - 1
                  ].substring(0, 3)} ${weeklyDates[selectedDate].year}`}</span>
                )}
                {form.slot_id === 0 ? (
                  <span></span>
                ) : (
                  <span>
                    {slots.map((slot) => {
                      if (slot.id === form.slot_id) {
                        return (
                          <>
                            {slot.time} {state.time_type}
                          </>
                        );
                      }
                    })}
                  </span>
                )}
              </div>
              <div className="scheduler">
                <h3>Date</h3>
                {weeklyDates && weeklyDates.length > 0 && (
                  <h4>{`${
                    months[parseInt(weeklyDates[selectedDate].month) - 1]
                  }, ${weeklyDates[selectedDate].year}`}</h4>
                )}
                <div className="days">
                  <button
                    disabled={currentWeek <= 0}
                    onClick={previousWeekHandler}
                  >
                    <AiOutlineLeft size={16} />
                  </button>
                  {weeklyDates && weeklyDates.length > 0 && (
                    <>
                      {weeklyDates.map((date, index) => (
                        <div className="day">
                          <span>{days[date.week_day]}</span>
                          <button
                            onClick={() => selectedDateHandler(index)}
                            className={selectedDate === index && "active"}
                          >
                            {date.day}
                          </button>
                        </div>
                      ))}
                    </>
                  )}
                  <button disabled={currentWeek >= 4} onClick={nextWeekHandler}>
                    <AiOutlineRight size={16} />
                  </button>
                </div>
              </div>
              <div className="slots">
                <div className="header">
                  <h3>Timeslots</h3>
                  <button
                    disabled={state.time_type === "AM"}
                    className={state.time_type === "AM" && "active"}
                    onClick={() => timeTypeHandler("AM")}
                  >
                    AM
                  </button>
                  <button
                    disabled={state.time_type === "PM"}
                    className={state.time_type === "PM" && "active"}
                    onClick={() => timeTypeHandler("PM")}
                  >
                    PM
                  </button>
                </div>
                {slots && slots.length > 0 && (
                  <ul>
                    {slots.map((slot) => (
                      <li>
                        <button
                          className={
                            slot.is_booked === 1
                              ? "disabled"
                              : form.slot_id === slot.id
                              ? "active"
                              : ""
                          }
                          onClick={
                            slot.is_booked === 0
                              ? () => slotHandler(slot.id)
                              : null
                          }
                        >
                          {slot.time}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {error && <p className="error-message">{error}</p>}
              <div className="action-button">
                {/* <button className="action" onClick={bookAppointmentHandler}>
                  Book Appointment
                </button> */}
                <button className="action" onClick={bookAppointmentHandler}>
                  Schedule a Call
                </button>
              </div>
              <p>Remark :The time slots are based on user's local timezone.</p>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export default Appointment;
