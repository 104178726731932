import React, { useCallback, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { adminChangePassword } from '../../../Api/admin';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLocalStorage from '../../../utils/useLocalStorage';
import { useNavigate } from 'react-router-dom';


const AdminChangePassword = ({ hidePasswordModal, logout }) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  })
  const [error, setError] = useState(false);

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      old_password: form.old_password.toLowerCase().trim(),
      new_password: form.new_password.toLowerCase().trim(),
      confirm_password: form.confirm_password.toLowerCase().trim(),
    }
    if (cleanData.old_password && cleanData.new_password && cleanData.confirm_password) {
      if (cleanData.new_password.length < 8 || cleanData.confirm_password.length < 8) {
        setError('password must have minimum 8 characters')
      } else if (cleanData.old_password === cleanData.new_password) {
        setError('old and new password cannot be same')
      } else if (cleanData.new_password !== cleanData.confirm_password) {
        setError('passwords does not match')
      } else {
        const finalData = {
          old_password: cleanData.old_password.trim(),
          password: cleanData.new_password.trim()
        }

        const response = await adminChangePassword(finalData);
        if (response) {
          if (response === 401) {
            useLocalStorage.removeAdmin();
            logout();
            navigate('/admin/login')
          } else {
            setForm({
              old_password: "",
              new_password: "",
              confirm_password: ""
            })
          }
          hidePasswordModal();
        } else {
          setForm({
            ...cleanData
          })
        }
      }
    } else {
      setError('Please fill all mandatory fields')
    }
  }, [form, hidePasswordModal, logout])

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Change Password</h3>
          <button className='close-btn' onClick={hidePasswordModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>Old Password<span className='mandatory-field'>&#42;</span></label>
                <input type='password' name="old_password" value={form.old_password} onChange={changeHandler} required />
              </div>
            </div>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>New Password<span className='mandatory-field'>&#42;</span></label>
                <input type='password' name="new_password" value={form.new_password} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Confirm Password<span className='mandatory-field'>&#42;</span></label>
                <input type='password' name="confirm_password" value={form.confirm_password} onChange={changeHandler} required />
              </div>
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button className='create'>
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(AdminChangePassword)