import React, { useCallback, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLocalStorage from '../../../utils/useLocalStorage';
import { addSchedule } from '../../../Api/schedules';
import { useNavigate } from 'react-router-dom';


const AddSchedule = ({ hideAddModal, fetchData, logout }) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    day: "",
    start_time: "",
    end_time: ""
  })
  const [error, setError] = useState(false);

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      day: form.day.trim(),
      start_time: form.start_time.trim(),
      end_time: form.end_time.trim(),
    }
    if (cleanData.day && cleanData.start_time && cleanData.end_time) {
      const response = await addSchedule(cleanData);
      if (response) {
        if (response === 401) {
          useLocalStorage.removeAdmin();
          logout();
          navigate('/admin/login')
        } else {
          setForm({
            day: "",
            start_time: "",
            end_time: ""
          })
        }
        hideAddModal();
        fetchData();
      } else {
        setForm({
          ...cleanData
        })
      }
    } else {
      setError('Please fill all mandatory fields')
    }
  }, [form, hideAddModal, logout])

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Add Schedule</h3>
          <button className='close-btn' onClick={hideAddModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>Day:<span className='mandatory-field'>&#42;</span></label>
                <select name='day' value={form.day} onChange={changeHandler}>
                  <option value={0}></option>
                  <option value={1}>Sunday</option>
                  <option value={2}>Monday</option>
                  <option value={3}>Tuesday</option>
                  <option value={4}>Wednesday</option>
                  <option value={5}>Thursday</option>
                  <option value={6}>Friday</option>
                  <option value={7}>Saturday</option>
                </select>
              </div>
              <div className='modal-input-box'>
                <label>Start Time<span className='mandatory-field'>&#42;</span></label>
                <input type="time" name="start_time" value={form.start_time} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>End Time<span className='mandatory-field'>&#42;</span></label>
                <input type="time" name="end_time" value={form.end_time} onChange={changeHandler} required />
              </div>
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button className='create'>
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(AddSchedule)