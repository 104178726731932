import React, { useCallback, useState, useEffect } from "react";
import { MdOutlineBrokenImage } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import "./../ServiceProviderProperties/ServiceProviderPropertyDetail.css"
import GoogleMapReact from "google-map-react";
import ConfirmBid from "../../../Components/Popup/ServiceProvider/ConfirmBid";
import useLocalStorage from '../../../utils/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { fetchPropertyById } from "../../../Api/serviceProvider";

function ServiceProviderSellerPropertyDetail() {
  const navigate = useNavigate();
  const [propertyData, setPropertyData] = useState(
    null
    // imageMain: "File_uploaded_1692780819661.png",
    // images: "File_uploaded_1692780819661.png,File_uploaded_1692780819661.png",
    // name: "Gaur City Mall",
    // price: "$500000",
    // thumbnail: "File_uploaded_1692780819661.png",
    // location_url: "www.googole.com",
  );
  const { id } = useParams();
  const [bid, setBid] = useState({
    price: "",
    comment: "Hi, I am interested to bid on this property.",
    property_id: id,
  });
  const [error, setError] = useState(false);
  const [imageMain, setImageMain] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [bidConfirm, setBidConfirm] = useState(false);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const apiResponse = await fetchPropertyById(id);
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeServiceProvider();
        navigate("/serviceProvider/login");
      } else {
        setPropertyData(apiResponse);
      }
    } else {
      setPropertyData(null)
    }
  }

  const setAsMainImage = (image) => {
    setImageMain(image);
  };

  const changeHandler = useCallback((e) => {
    setBid({ ...bid, [e.target.name]: e.target.value });
  }, []);

  const confirmHandler = () => {
    setConfirm(!confirm);
  };

  const submitHandler = useCallback(async () => {
    if (bid.price) {
      setConfirm(true)
    } else {
      setError('Please enter the price')
    }
  }, [bid])

  return (
    <>
      {confirm && (
        <ConfirmBid
          hideConfirmBox={confirmHandler}
          setBidConfirm={setBidConfirm}
          bid={bid}
        />
      )}
      <div className="admin-container">
        <div className="breadcrumbs">
          <p>
            ServiceProvider
            <b>
              / <Link to="/serviceProvider/userproperty">User Properties</Link>/
              User Property Details
            </b>
          </p>
          <span>User Property Details</span>
        </div>
        <div className="content-wrapper">
          {propertyData && (
            <>
              <div className="contents">
                <div className="section-view">
                  <section>
                    <div className="image">
                      {imageMain ? (
                        <img
                          src={`https://nriconnect.io/realEstate/Uploads/${imageMain}`}
                          alt="img"
                        />
                      ) : (propertyData.thumbnail ? (
                        <img
                          src={`https://nriconnect.io/realEstate/Uploads/${propertyData.thumbnail}`}
                          alt="img"
                        />): 'No Image'
                      )}
                    </div>
                    {propertyData?.images && (
                      <ul className="images">
                        <li>
                          <img
                            src={`https://nriconnect.io/realEstate/Uploads/${propertyData.thumbnail}`}
                            onClick={() =>
                              setAsMainImage(propertyData.thumbnail)
                            }
                            alt="img"
                          />
                        </li>
                        {propertyData?.images.split(",").map((image, index) => {
                          return (
                            <li>
                              <img
                                src={`https://nriconnect.io/realEstate/Uploads/${image}`}
                                onClick={() => setAsMainImage(image)}
                                alt="img"
                              />
                            </li>
                          );
                        })}
                        <span>
                          <MdOutlineBrokenImage size={16} />
                          {propertyData.images.split(",").length}
                        </span>
                      </ul>
                    )}
                  </section>
                  <section>
                    <div className="outer-box">
                      {bidConfirm ? (
                        <div className="confirm-box">
                          <div className="top">
                            <p>Congratulations !</p>
                            <p>your bid placed successfully. </p>
                          </div>
                          <div className="image">
                            <div className="load"></div>
                          </div>
                          <p>You can check your bid status on account</p>
                          <div className="bottom">
                            <p>Go to Accounts</p>
                          </div>
                        </div>
                      ) : (
                        (propertyData.bid_status === 'Rejected' || propertyData.bid_status === '') ? 
                        <div className="inner-box">
                          <div className="top">
                            <p>Make This property Yours !</p>
                            <p>Place your bid here</p>
                          </div>
                          <div className="amount">
                            <span>Starting Bid</span>
                            <span>{propertyData.price}</span>
                          </div>
                          <div className="bid-input">
                            <input
                              type="text"
                              placeholder="Enter bid amount(INR)"
                              name="price"
                              value={bid.price}
                              onChange={changeHandler}
                            />
                            <textarea rows={3} onChange={changeHandler} value={bid.comment} name="comment" type="text" >{bid.comment}</textarea>
                            {/* <textarea>Hi, I am interested in this property.</textarea> */}
                            {/* <p>Hi, I am interested to bid on this property.</p> */}
                          </div>
                          {error && (<p style={{ textAlign: 'center' }} className='error-message'>{error}</p>)}
                          <div className="bottom">
                            <button
                              type="button"
                              onClick={submitHandler}
                            >
                              Bid Now
                            </button>
                          </div>
                        </div> : 
                        <div className="inner-box">
                        <div className="top">
                        {(propertyData.bid_status === 'Approved') ? 
                          <p><center>Your Bid has been approved successfully.</center></p> :
                          <p><center>Your Bid has been successfully posted.</center></p>}
                        </div>
                        </div>
                      )}
                    </div>
                  </section>
                </div>
                <section>
                  <div className="modal-grid">
                    {propertyData.name && <div className="modal-box1">
                      <label>Name</label>
                      <p>{propertyData.name}</p>
                      <p>{propertyData.name}</p>
                    </div>}
                    <div className="modal-box2">
                      <label>Price</label>
                      <p>{propertyData.price}</p>
                      <p>Est. EMI Rs 41,972/mo*</p>
                    </div>
                  </div>
                  <div className="modal-box">
                    <label>Location</label>
                    <p>{propertyData.address}, {propertyData.city}, {propertyData.state}, {propertyData.country}</p>
                    <div style={{ height: "80vh", width: "90%" }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: process.env.REACT_APP_GOOGLE_LOCATION_API_KEY,
                        }}
                        defaultCenter={
                          {
                            //   lat: parseFloat(propertyData.lattitude),
                            //   lng: parseFloat(propertyData.longitude),
                          }
                        }
                        defaultZoom={11}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#000",
                          }}
                          //   lat={parseFloat(propertyData.lattitude)}
                          //   lng={parseFloat(propertyData.longitude)}
                          text="My Marker"
                        />
                      </GoogleMapReact>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ServiceProviderSellerPropertyDetail;
