import React, { useState } from "react";
import OTPverfication from "./OTPverfication";
import PhoneInput from "react-phone-input-2";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";

function FillDetails({ onDataEntered }) {
  const [newpage, setNewPage] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleSubmit = () => {
    const validationErrors = {};
    let isValid = true;

    if (!form.name.trim()) {
      validationErrors.name = "Name is required";
      isValid = false;
    }

    if (!form.email.trim()) {
      validationErrors.email = "Email is required";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email.trim())) {
      validationErrors.email = "Invalid email address";
      isValid = false;
    }

    if (!form.phone.trim()) {
      validationErrors.phone = "Phone number is required";
      isValid = false;
    }

    setErrors(validationErrors);

    if (isValid) {
      onDataEntered(form);
    }
  };
  const handleOTPSubmit = () => {
    // Send the entered data to the parent component
    onDataEntered(form);
  };
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    setForm({ ...form, [name]: e.target.value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  return (
    <div className="details">
      {/* {newpage ? (
        <div>
          <label htmlFor="otp">Please enter OTP sent to +91 - 98xxxxxx89</label>
          <div className="otp-details">
            <input
              type="number"
              placeholder="Enter the OTP"
              name="otp"
              id="otp"
            />
            <div className="otp-btn">
              <button type="button" onClick={handleOTPSubmit}>
                <p>Verify</p>
              </button>
              <Link to="">
                <p>Resend OTP</p>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <> */}
      <p>Another Email / Contact Number</p>
      <div className="personal-details">
        <input
          type="text"
          placeholder="Enter your name"
          value={form.name}
          name="name"
          onChange={handleChange}
        />
        <span className="error-message">{errors.name}</span>
        <input
          type="email"
          placeholder="Enter your email"
          value={form.email}
          name="email"
          onChange={handleChange}
        />
        <span className="error-message">{errors.email}</span>
        <PhoneInput
          country={"in"}
          countryCodeEditable={false}
          placeholder="Enter your phone number"
          value={form.phone}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
            onChange: handleChange,
          }}
          inputStyle={{
            width: "93%",
            backgroundColor: "white",
            height: "52px",
            outlined: "true",
            border: "1px solid #eeeeee",
            borderRadius: "24px",
            marginLeft: "10px",
          }}
          buttonStyle={{
            backgroundColor: "white",
            width: "50px",
            border: "1px solid #eeeeee",
            borderRadius: "24px",
          }}
        />
        <span className="error-message">{errors.phone}</span>
      </div>
      <button type="button" onClick={handleSubmit}>
        <p>Continue</p> <AiOutlineArrowRight />
      </button>
      {/* </> */}
      {/* )} */}
    </div>
  );
}

export default FillDetails;
