import api from "../utils/api";
import useLocalStorage from "../utils/useLocalStorage";
import { toast } from "react-toastify";

export const signup = async (data) => {
  const apiResponse = await api.applicationApi(
    "users/signup",
    "POST",
    data,
    null
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const login = async (data) => {
  const apiResponse = await api.applicationApi(
    "users/login",
    "POST",
    data,
    null
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      useLocalStorage.setUser(apiResponse.data.token);
      return apiResponse.data.data;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const editUser = async (data) => {
  const apiResponse = await api.multipartApi("users/editUser", "PUT", data, 3);
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const userLogout = async () => {
  const apiResponse = await api.applicationApi("users/logout", "POST", null, 3);
  console.log(apiResponse, "response");
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const fetchUserDetails = async () => {
  const apiResponse = await api.applicationApi(
    "users/fetchUserDetails",
    "POST",
    null,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const fetchUsers = async (data) => {
  const apiResponse = await api.applicationApi(
    "users/fetchUsers",
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const fetchUserDetailAdmin = async (id) => {
  const apiResponse = await api.applicationApi(
    `users/fetchUserDetails/${id}`,
    "POST",
    null,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const activateDeactivateUser = async (id, data) => {
  const apiResponse = await api.applicationApi(
    `users/activateDeactivateUser/${id}`,
    "PATCH",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const fetchUserDocs = async (data) => {
  const apiResponse = await api.applicationApi(
    "users/fetchDocs",
    "POST",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const addUserDocs = async (data) => {
  const apiResponse = await api.multipartApi("users/addDocs", "POST", data, 3);
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const deleteUserDoc = async (id) => {
  const apiResponse = await api.applicationApi(
    `users/deleteDoc/${id}`,
    "DELETE",
    null,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const userVerification = async (data) => {
  const apiResponse = await api.applicationApi(
    `users/userVerification`,
    "PATCH",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      // toast.success(apiResponse.data.message)
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const sendUserVerificationMail = async (data) => {
  const apiResponse = await api.applicationApi(
    `users/sendUserVerificationMail`,
    "POST",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      // toast.success(apiResponse.data.message)
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const userForgotPassword = async (data) => {
  const apiResponse = await api.applicationApi(
    `users/forgotPassword`,
    "POST",
    data,
    0
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else {
      return apiResponse.data.message;
    }
  } else {
    return false;
  }
};

export const userResetPassword = async (data) => {
  const apiResponse = await api.applicationApi(
    `users/resetPassword`,
    "PATCH",
    data,
    0
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const userChangePassword = async (data) => {
  const apiResponse = await api.applicationApi(
    `users/changePassword`,
    "PATCH",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

//Cases api
export const fetchCases = async (data) => {
  const apiResponse = await api.applicationApi(
    "cases/fetchCases",
    "POST",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

//transactions api
export const fetchTransactions = async (data) => {
  const apiResponse = await api.applicationApi(
    "transaction/fetchTransactions",
    "POST",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const addTotalVisit = async () => {
  const apiResponse = await api.applicationApi(
    "users/addTotalVisit",
    "POST",
    null,
    0
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// FETCH PROPERTY LIST API
export const addProperty = async (data) => {
  const apiResponse = await api.multipartApi(
    "property/addUserProperty",
    "POST",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};
export const fetchPropertylist = async (data) => {
  const apiResponse = await api.applicationApi(
    "property/fetchProperties",
    "POST",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else {
      if (apiResponse.status === 401) {
        return 401;
      }
      if (apiResponse.status === 403) {
        toast.error(apiResponse.data.message);
      }
      return false;
    }
  } else {
    return false;
  }
};
export const fetchPropertyDetail = async (id) => {
  const apiResponse = await api.applicationApi(
    `property/fetchProperty/${id}`,
    "POST",
    {},
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else {
      if (apiResponse.status === 401) {
        return 401;
      }
      if (apiResponse.status === 403) {
        toast.error(apiResponse.data.message);
      }
      return false;
    }
  } else {
    return false;
  }
};

export const deleteProperty = async (id) => {
  const apiResponse = await api.applicationApi(
    `property/deleteProperty/${id}`,
    "DELETE",
    null,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};
// FETCH BID API

export const fetchBidlist = async (id) => {
  const apiResponse = await api.applicationApi(
    `users/fetchBids/${id}`,
    "POST",
    {},
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else {
      if (apiResponse.status === 401) {
        return 401;
      }
      if (apiResponse.status === 403) {
        toast.error(apiResponse.data.message);
      }
      return false;
    }
  } else {
    return false;
  }
};
export const acceptRejectBid = async (id, data) => {
  const apiResponse = await api.applicationApi(
    `users/acceptRejectBid/${id}`,
    "PATCH",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return apiResponse;
    } else {
      if (apiResponse.status === 401) {
        return 401;
      }
      if (apiResponse.status === 403) {
        toast.error(apiResponse.data.message);
      }
      return false;
    }
  } else {
    return false;
  }
};
export const addImages = async (id, data) => {
  var bodyFormData = new FormData();

  console.log(data)
  typeof data.thumbnail === "string"
    ? bodyFormData.append("thumbnail", data.thumbnail)
    : data.thumbnail?.forEach(function (element, index) {
      bodyFormData.append(`thumbnail`, element);
    });
  bodyFormData.append("name", data.name);
  bodyFormData.append("contact_name", data.contact_name);
  bodyFormData.append("address", data.address);
  bodyFormData.append("city", data.city);
  bodyFormData.append("state", data.state);
  bodyFormData.append("country", data.country);
  bodyFormData.append("zipcode", data.zipcode);
  bodyFormData.append("price", data.price);
  bodyFormData.append("area", data.area);
  bodyFormData.append("property_type", data.property_type);
  bodyFormData.append("contact_number", data.contact_number);
  bodyFormData.append("contact_email", data.contact_email);
  bodyFormData.append("token_amount", data.token_amount);
  bodyFormData.append("title_deed", data.title_deed);
  bodyFormData.append("confidentiality_doc", data.confidentiality_doc);
  bodyFormData.append("three_months_mend", data.three_months_mend);
  bodyFormData.append("fard", data.fard);

  if (data.new_images && data.new_images.length > 0) {
    data.new_images.forEach((image, index) => {
      bodyFormData.append(`new_images`, image);
    });
  }
  if (data.images && data.images.length > 0) {
    data.images.forEach((image, index) => {
      bodyFormData.append(`images`, image);
    });
  }
  // data.images.forEach(function (element, index) {
  //   bodyFormData.append(`images`, element);
  // });

  // bodyFormData.append("thumbnail", data.thumbnail);
  // bodyFormData.append("new_images", data.new_images);
  // { "name": "Users Properties", "address": "123,Mahipalpur",city": "Delhi", "state": "Delhi", "country": "India", "zipcode": "123123","price": 12000, "area": "12 sq ft", "property_type": 2, "contact_number": "1231231231", "contact_email": "user@gmail.com","token_amount":1200,"title_deed":File,"three_months_mend":File,"confidentiality_doc":File,"fard":File,"thumbnail":File,"new_images":[Files]},
  const apiResponse = await api.multipartApi(
    `property/editUserProperty/${id}`,
    "PUT",
    bodyFormData,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return apiResponse;
    } else {
      if (apiResponse.status === 401) {
        return 401;
      }
      if (apiResponse.status === 403) {
        toast.error(apiResponse.data.message);
      }
      return false;
    }
  } else {
    return false;
  }
};

// Fetch Property Types
export const fetchPropertyAddEditData = async (data) => {
  const apiResponse = await api.applicationApi(
    "property/fetchPropertyAddEditData",
    "POST",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const handleSoldout = async (id, data, fetchDetails) => {
  const apiResponse = await api.applicationApi(
    `property/updateSoldOut/${id}`,
    "PATCH",
    data,
    3
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      fetchDetails();
      return apiResponse;
    } else {
      if (apiResponse.status === 401) {
        return 401;
      }
      if (apiResponse.status === 403) {
        toast.error(apiResponse.data.message);
      }
      return false;
    }
  } else {
    return false;
  }
};

export const needPriceConsultation = async (data) => {
  const apiResponse = await api.applicationApi(
    "users/needPriceConsultation",
    "POST",
    data
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};