import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import './auth.css'
import { userForgotPassword, userResetPassword } from '../../Api/user';

const ForgotPassword = () => {

    const navigate = useNavigate()

    const [otpView, setOtpView] = useState(false);
    const [passwordView, setPasswordView] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState(false);
    const [resendTime, setResendTime] = useState(31);
    const [otp, setOtp] = useState(null);

    const [form, setForm] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        otp: ""
    })

    const onChangeHandler = useCallback((e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }, [form])

    const showPasswordHandler = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])

    const showConfirmPasswordHandler = useCallback(() => {
        setShowConfirmPassword(!showConfirmPassword)
    }, [showConfirmPassword])

    const changeView = useCallback(async () => {
        const cleanData = {
            email: form.email.trim()
        }
        if (cleanData.email) {
            setError('');
            const apiResponse = await userForgotPassword(cleanData);
            if (apiResponse) {
                setOtp(apiResponse.otp)
                setOtpView(true)
            } else {
                setError('some error occurred')
            }

        } else {
            setError('please enter a valid email')
        }
    }, [otpView, form])

    const resendOtpHandler = useCallback(async () => {
        const cleanData = {
            email: form.email.trim()
        }
        const apiResponse = await userForgotPassword(cleanData);
        if (apiResponse) {
            setOtp(apiResponse.otp)
        }
    }, [form])

    useEffect(() => {
        resendTimeHandler();
    }, [resendTime])

    const resendTimeHandler = useCallback(() => {
        if (resendTime > 0) {
            setTimeout(() => {
                setResendTime(resendTime - 1)
            }, 1000)
        }
    }, [resendTime])

    const changeOtpView = useCallback(async () => {
        console.log(otp, form.otp)
        if (form.otp == otp) {
            setPasswordView(!passwordView)
            setError(false)
        } else {
            setError('one time password does not match')
        }
    }, [passwordView,form])

    const submitHandler = useCallback(async () => {
        const cleanData = {
            email: form.email.trim(),
            password: form.password.trim(),
            confirmPassword: form.confirmPassword.trim()
        }
        if(cleanData.password && cleanData.confirmPassword){
        if (cleanData.password.length < 8) {
            setError('password must contain atleast 8 charachters')
        } else if (cleanData.confirmPassword !== cleanData.password) {
            setError('passwords does not match')
        } else {
            setError(false);
            const apiResponse = await userResetPassword({
                email: cleanData.email,
                password: cleanData.password
            });

            if (apiResponse) {
                setPasswordView(false);
                setOtpView(false)
                navigate('/login')
            } else {
                setPasswordView(false);
                setOtpView(false)
                setError('some error occured')
            }
        }
        }else{
            setError('Please fill all mandatory fields')
        }
    }, [form])

    return (
        <div className='auth-container'>
            <div className='auth-background'></div>
            <div className='auth-wrapper'>
                <section>
                    <h1>Get Solutions to your Problems!</h1>
                    <Link to="">Get Started anyway</Link>
                </section>
                <section>
                {otpView ?
                    (passwordView ?
                        <div className='auth-box'>
                            <h2>Password set up</h2>
                            <p>Please set up your password</p>
                            <form>
                                <div className={form.password ? 'input-box active' : 'input-box'}>
                                    <label>New Password</label>
                                    <input onChange={onChangeHandler} value={form.password} name='password' type={showPassword ? 'text' : 'password'} placeholder='New Password*' />
                                    <button type='button' onClick={showPasswordHandler}>{showPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
                                </div>
                                <div className={form.confirmPassword ? 'input-box active' : 'input-box'}>
                                    <label>Password</label>
                                    <input onChange={onChangeHandler} value={form.confirmPassword} name='confirmPassword' type={showConfirmPassword ? 'text' : 'password'} placeholder='Confirm Password*' />
                                    <button type='button' onClick={showConfirmPasswordHandler}>{showConfirmPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
                                </div>
                                {error && (<p className='error-message'>{error}</p>)}
                                <button type='button' onClick={submitHandler}>Set Password</button>
                            </form>
                        </div>
                        :
                        <div className='auth-box'>
                            <p>Enter OTP</p>
                            <form>
                                <div className={form.otp ? 'input-box active' : 'input-box'}>
                                    <input name='otp' style={{ textAlign: 'center' }} value={form.otp} onChange={onChangeHandler} autoFocus maxLength={6} type="number" secure />
                                </div>
                                {resendTime > 0 ? <span className='resend-span'>resend otp in {resendTime} seconds</span> : <button className='resend-btn' onClick={resendOtpHandler} type='button'>Resend OTP</button>}
                                {error && (<p className='error-message'>{error}</p>)}
                                <button type='button' onClick={changeOtpView}>Send</button>
                            </form>
                        </div>
                        )
                        :
                        <div className='auth-box'>
                            <h2>Forgot Your Password?</h2>
                            <p>Please enter your email address and we will send you a password reset link.</p>
                            <form>
                                <div className={form.email ? 'input-box active' : 'input-box'}>
                                    <label>Email</label>
                                    <input onChange={onChangeHandler} value={setForm.email} name='email' placeholder='Enter your email' />
                                </div>
                                {error && (<p className='error-message'>{error}</p>)}
                                <button type='button' onClick={changeView}>Reset My Password</button>
                            </form>
                        </div>
                    }
                </section>
            </div>
        </div>
    )
}

export default ForgotPassword