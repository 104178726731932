import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RiDeleteBinLine } from "react-icons/ri";
import { TbArrowsDownUp } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { fetchCases } from "../../../Api/user";
import useLocalStorage from "../../../utils/useLocalStorage";

const Cases = ({ user }) => {
  const navigate = useNavigate();
  const [cases, setCases] = useState([]);
  const [total, setTotal] = useState();
  const [state, setState] = useState({
    limit: 10,
  });
  const [showAll, setShowAll] = useState(true);

  useEffect(() => {
    fetchData();
  }, [state]);

  const fetchData = useCallback(async () => {
    const apiResponse = await fetchCases({
      user_id: user.data.id,
      limit: state.limit,
    });
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeUser();
        navigate("/login");
      } else {
        setCases([...apiResponse.cases]);
        setTotal(apiResponse.total_cases);
      }
    } else {
      setCases([]);
      setTotal([]);
    }
  }, [state]);

  const showAllHandler = useCallback(
    (e) => {
      e.preventDefault();
      setState({
        ...state,
        limit: total,
      });
      setShowAll(false);
    },
    [state]
  );

  const showLessHandler = useCallback(
    (e) => {
      e.preventDefault();
      setState({
        ...state,
        limit: 10,
      });
      setShowAll(true);
    },
    [state]
  );

  //   console.log(cases,"cases")
  return (
    <>
      <div className="table-container">
        <div className="view">
          <h1>Cases</h1>
          <div className="tables">
            <table>
              <thead>
                <tr>
                  <th>Cases</th>
                  <th>Plan Name</th>
                  <th>
                    Date Added <TbArrowsDownUp />
                  </th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {cases.map((item) => (
                  <tr>
                    <td className="text-capitalize">{item.id}</td>
                    <td className="text-capitalize">{item.plan_name}</td>
                    <td>{item.created_at}</td>
                    <td>
                      <span
                        className={item.is_active == 1 ? "active" : "deactive"}
                      >
                        {item.is_active == 1 ? "Active" : "Inactive"}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {(total && total) > 10 && (
            <div className="table-action">
              {showAll ? (
                <a href="" onClick={showAllHandler}>
                  Show All
                </a>
              ) : (
                <a href="" onClick={showLessHandler}>
                  Show Less
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Cases);
