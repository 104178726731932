import React, { useCallback, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { addBuilder } from '../../../Api/builders';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLocalStorage from '../../../utils/useLocalStorage';
import { useNavigate } from 'react-router-dom';

const AddBuilder = ({ hideAddModal, fetchData, logout }) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    display_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: ""
  })
  const [error, setError] = useState(false);

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      first_name: form.first_name.toLowerCase().trim(),
      last_name: form.last_name.toLowerCase().trim(),
      display_name: form.display_name.toLowerCase().trim(),
      email: form.email.trim(),
      phone: form.phone.trim(),
      address: form.address.trim(),
      city: form.city.trim(),
      state: form.state.trim(),
      country: form.country.trim(),
      zipcode: form.zipcode.trim()
    }
    console.log(cleanData, "cleandata")
    if (cleanData.first_name && cleanData.last_name && cleanData.display_name && cleanData.email && cleanData.phone && cleanData.address && cleanData.city && cleanData.state && cleanData.country && cleanData.zipcode) {
      if (cleanData.phone.length !== 10) {
        setError('Please enter a valid mobile number')
      } else if (cleanData.zipcode.length !== 6) {
        setError("Please enter a valid Zipcode ")
      } else {
        const response = await addBuilder(cleanData);
        if (response) {
          if (response === 401) {
            useLocalStorage.removeAdmin();
            logout();
            navigate('/admin/login')
          } else {
            setForm({
              id: "",
              first_name: "",
              last_name: "",
              display_name: "",
              email: "",
              phone: "",
              address: "",
              city: "",
              state: "",
              country: "",
              zipcode: ""
            })
            hideAddModal();
            fetchData();
          }

        } else {
          setForm({
            ...cleanData
          })
        }
      }
    } else {
      setError('Please fill all mandatory fields')
    }
  }, [form, hideAddModal, logout])

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Add Builder</h3>
          <button className='close-btn' onClick={hideAddModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>First Name<span className='mandatory-field'>&#42;</span></label>
                <input name="first_name" value={form.first_name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Last Name<span className='mandatory-field'>&#42;</span></label>
                <input name="last_name" value={form.last_name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Display Name<span className='mandatory-field'>&#42;</span></label>
                <input name="display_name" value={form.display_name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Email<span className='mandatory-field'>&#42;</span></label>
                <input name="email" value={form.email} onChange={changeHandler} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
              </div>
              <div className='modal-input-box'>
                <label>Phone<span className='mandatory-field'>&#42;</span></label>
                <input type='number' name="phone" value={form.phone} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Address<span className='mandatory-field'>&#42;</span></label>
                <input name="address" value={form.address} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>City<span className='mandatory-field'>&#42;</span></label>
                <input name="city" value={form.city} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>State<span className='mandatory-field'>&#42;</span></label>
                <input name="state" value={form.state} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Country<span className='mandatory-field'>&#42;</span></label>
                <input name="country" value={form.country} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>ZipCode<span className='mandatory-field'>&#42;</span></label>
                <input type="number" name="zipcode" value={form.zipcode} onChange={changeHandler} required />
              </div>
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button className='create'>
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(AddBuilder)  