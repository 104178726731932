import React, {useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadUserRequest } from '../../../Actions/user';
import { BiEditAlt } from 'react-icons/bi';
import Document from './Document';
import Cases from './Cases';
import PaymentDetails from './PaymentDetails';
import EditProfile from '../../../Components/Popup/User/EditProfile';
import ChangePassword from '../../../Components/Popup/User/ChangePassword';

const UserProfile = ({user}) => {

  const [editModal, setEditModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const editProfileHandler = (e) => {
  
    setEditModal(!editModal)
    e.preventDefault();
  }

  const passwordHandler = (e) => {
 
    setPasswordModal(!passwordModal)
    e.preventDefault();
  }

    return (
      <>
      {editModal && <EditProfile data={user.data} hideProfileModal={editProfileHandler} />}
      {passwordModal && <ChangePassword hidePasswordModal={passwordHandler} />}
        <div className='container'>
          <div className='user-wrapper'> 
                <h2>My Profile</h2>
                <div className='user-content'>
                    <div className="user-info" >
                      <div>
                          {user.data.image && (
                            <img src={`https://nriconnect.io/realEstate/Uploads/${user.data.image}`} alt="" />
                          )}
                      </div>
                      <h5 className='text-capitalize'>{user.data.first_name} {user.data.last_name}</h5>
                      <p>{user.data.email}</p>
                      <p className="phone">+91 {user.data.phone}</p>
                      <a href="" onClick={editProfileHandler}> Edit Details</a>
                      <a href="" onClick={passwordHandler}> Change Password</a>
                    </div>
                    <div className='user-profile-edit'>
                      <Document />
                      <Cases />
                      <PaymentDetails />
                    </div>
                </div>
          </div>
        </div>
      </>
    )
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => bindActionCreators({
    loadUser: loadUserRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)