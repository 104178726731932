import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchUserDetailAdmin } from "../../../Api/user";
import useLocalStorage from "../../../utils/useLocalStorage";

const UserDetails = () => {
  const cases = [
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 1,
    },
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 0,
    },
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 0,
    },
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 0,
    },
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 1,
    },
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 0,
    },
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 0,
    },
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 0,
    },
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 1,
    },
    {
      case_name: "Case 1",
      created_at: "20-Jun-2022",
      updated_at: "20-Jun-2022",
      is_active: 0,
    },
  ];

  const userData = {
    first_name: "Nishat",
    last_name: "Anjum",
    email: "anjum@gmail.com",
    phone: "1231231231",
    image: "",
  };

  const documents = [
    {
      document_name: "File_uploaded_1.pdf",
    },
    {
      document_name: "File_uploaded_1.pdf",
    },
    {
      document_name: "File_uploaded_1.pdf",
    },
    {
      document_name: "File_uploaded_1.pdf",
    },
  ];
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const BASE_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL
      : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;

  const fetchUserDetails = useCallback(async () => {
    const result = await fetchUserDetailAdmin(id);
    if (result) {
      if (result === 401) {
        useLocalStorage.removeAdmin();
        navigate("/admin/login");
      } else {
        setUserDetails(result.data);
      }
    } else {
      setUserDetails([]);
    }
  }, [id]);

  useEffect(() => {
    fetchUserDetails();
  }, []);
  console.log(userDetails.documents);
  return (
    <>
      <div className="admin-container">
        <div className="breadcrumbs">
          <p>
            Admin{" "}
            <b>
              / <Link to="/admin/users">Users</Link>/ UserDetails
            </b>
          </p>
          <span>User Details</span>
        </div>
        <div className="content-wrapper">
          <div className="profile">
            <section>
              <div className="info" key={userData.id}>
                <div>
                  {userData.image && (
                    <img
                      src={`https://nriconnect.io/realEstate/Uploads/${userData.image}`}
                      alt=""
                    />
                  )}
                </div>
                {userDetails.user_data &&
                  userDetails.user_data.map((item) => (
                    <>
                      <h5>{item.first_name + " " + item.last_name}</h5>
                      <p>{item.email}</p>
                      <p className="phone">
                        +91 {item.phone ? item.phone : ""}
                      </p>
                    </>
                  ))}
              </div>
              <div className="table-view">
                <h3>Documents</h3>
                {userDetails.documents === 0 ? (
                  <p style={{ padding: 22 }}>No documents</p>
                ) : (
                  <div className="table">
                    <table>
                      <thead>
                        <tr>
                          <th>Documents</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userDetails?.documents?.map((item) => (
                          <tr>
                            <td className="text-capitalize">
                              <a
                                href={`${BASE_URL}${item.name}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.name}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </section>
            <section>
              <div className="detail-view">
                <h3>Plans Purchased</h3>
                {userDetails?.cases && userDetails?.cases?.length > 0 && (
                  <div className="table">
                    <table>
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Cases</th>
                          <th>Date Added </th>
                          <th>Date Modified</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userDetails?.cases?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-capitalize">{item.name}</td>
                            <td>{item.created_at}</td>
                            <td>{item.updated_at}</td>
                            <td>
                              <span
                                className={
                                  item.is_active === 1 ? "active" : "deactive"
                                }
                              >
                                {item.is_active === 1 ? "Active" : "InActive"}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
