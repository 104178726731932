import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { showFaqs, showFinancialPlanFaqs } from '../../Api/faqs';
import Accordion from '../../Components/Accordion/Accordion';

const UserFaqs = () => {

    const path = useLocation().pathname;
    const [faqs, setFaqs] = useState([]);

    const { id } = useParams()

    useEffect(() => {
        fetchData()
    }, [id])

    const fetchData = useCallback(async () => {
        if (path.includes('/faqs/plan')) {
            const apiResponse = await showFinancialPlanFaqs(id);
            if (apiResponse) {
                setFaqs([...apiResponse])
            } else {
                setFaqs([])
            }
        } else {
            let serviceId = 0;
            if (id === 'realestate') {
                serviceId = 1;
            } else if (id === 'financial') {
                serviceId = 2;
            } else if (id === 'general') {
                serviceId = 3;
            }

            if (serviceId) {
                const apiResponse = await showFaqs(serviceId);
                if (apiResponse) {
                    setFaqs([...apiResponse])
                } else {
                    setFaqs([])
                }
            }
        }
    }, [])

    return (
        <div className='container static landing-faqs'>
            <div className='wrapper'>
                <div className='content-wrapper'>
                    <h2>{id === 'realestate' ? "Real Estate Services" : (id === 'financial' && "Finacial Services")} FAQs</h2>
                    <div className='faqs-box'>
                        {(faqs && faqs.length > 0) && (
                            <>
                                {faqs.map(faq => <Accordion label={faq.question} content={faq.answer} />)}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserFaqs