import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { loadAdminRequest } from '../../../Actions/admin';
import { activateDeactivateSchedule, fetchSchedules } from '../../../Api/schedules';
import AddSchedule from '../../../Components/Popup/Admins/AddSchedule';
import ViewSlots from '../../../Components/Popup/Admins/ViewSlots';
import useLocalStorage from '../../../utils/useLocalStorage';

const Schedules = () => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        request_type: 0,
        offset: 0
    });
    const [addModal, setAddModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [viewData, setViewData] = useState('');
    const [schedules, setSchedules] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1)

    const addModalHandler = () => {
        setAddModal(!addModal)
    }

    const viewModalHandler = (data) => {
        setViewData(data)
        setViewModal(!viewModal)
    }
    useEffect(() => {
        fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchSchedules(state);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setSchedules([...apiResponse.schedules]);
                if (apiResponse.total_schedules > 0) {
                    if (apiResponse.total_schedules % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_schedules / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_schedules / 10))
                    }
                }
            }
        } else {
            setSchedules([]);
            setTotalPages(0)
        }
    }, [state])

    const activateDeactivateHandler = async (data) => {
        let status = 0;
        if (data.is_active === 0) {
            status = 1;
        }

        const apiResponse = await activateDeactivateSchedule(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                fetchData();
            }
        }
    }

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, [])

    const requestTypeHandler = useCallback((e) => {
        setState({
            ...state,
            request_type: e.target.value
        })
    }, [state])

    return (
        <>
         {addModal && (<AddSchedule fetchData={fetchData} hideAddModal={addModalHandler} />)}
         {viewModal && (<ViewSlots viewData={viewData} hideViewModal={viewModalHandler} />)}
            <div className='admin-container'>
                <div className="breadcrumbs">
                    <p>Admin / Property Services <b>/ Schedules</b></p>
                    <span>Schedules</span>
                </div>
                <div className='content-wrapper'>
                    <div className='table-view'>
                        <div className='header'>
                            <h1>Schedules</h1>
                            <button className='add-btn' onClick={addModalHandler}>Add</button>
                        </div>
                        <div className='table-filters'>
                            <div className='dropdown'>
                                <label>Status:</label>
                                <select value={state.request_type} onChange={requestTypeHandler}>
                                    <option value={0}>All</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Not active</option>
                                </select>
                            </div>
                        </div>

                        {(schedules && schedules.length > 0) && (
                            <>
                                <div className='table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Day</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Date Added</th>
                                                <th>Date Modified</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {schedules.map((item, index) => (
                                                 <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.day}</td>
                                                    <td>{item.start_time}</td>
                                                    <td>{item.end_time}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.updated_at}</td>
                                                    <td>
                                                        <span className={item.is_active === 1 ? 'active' : 'deactive'} >
                                                           {item.is_active === 1 ? 'Active' : 'Not Active'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className='table-actions'>
                                                        <button className={item.is_active === 0 ? 'activate-btn' : 'deactivate-btn'} onClick={() => activateDeactivateHandler(item)}>
                                                            {item.is_active === 0 ? "Activate" : "Deactivate"}
                                                            </button>
                                                            <button className='edit-btn' onClick={() => viewModalHandler({id: item.id})} >
                                                                View Slots
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {(totalPages && totalPages > 1) ? 
                                <div className='admin-pagination'>
                                    <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                                        <AiFillCaretLeft size={16} />
                                    </button>
                                    <span>{`Page ${currentPage} / ${totalPages}`}</span>
                                    <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                                        <AiFillCaretRight size={16} />
                                    </button>
                                </div>
                                :
                                <></>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout: loadAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(Schedules)
