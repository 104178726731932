import React, { useCallback, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { editAdminById } from '../../../Api/admin';
import useLocalStorage from '../../../utils/useLocalStorage';


const EditAdmin = ({ hideEditModal, data, fetchData, logout }) => {

  const navigate = useNavigate();
  const [form, setForm] = useState(data)
  const [error, setError] = useState(false);

  const serviceList = [
    {
      id: '1',
      name: 'Real Estate'
    },
    {
      id: '2',
      name: 'Financial'
    }
  ];

  const changeHandler = useCallback((e) => {
    if (e.target.name === 'service_ids') {
      let serviceIdsArray = [];
      if (form.service_ids) {
        serviceIdsArray = form.service_ids.split(',');
        if (serviceIdsArray[0] === "") {
          serviceIdsArray.splice(0, 1);
        }
        if (serviceIdsArray.length > 0) {
          const index = serviceIdsArray.indexOf(e.target.value);
          if (index === -1) {
            serviceIdsArray.push(e.target.value);
          } else {
            serviceIdsArray.splice(index, 1);
          }
          if (serviceIdsArray.length > 0) {
            if (serviceIdsArray.length === 1) {
              setForm({
                ...form,
                service_ids: ("" + serviceIdsArray[0])
              })
            } else {
              setForm({
                ...form,
                service_ids: serviceIdsArray.map(value => parseInt(value)).sort().join(',')
              })
            }
          } else {
            setForm({
              ...form,
              service_ids: ""
            })
          }
        } else {
          setForm({
            ...form,
            service_ids: ("" + e.target.value)
          })
        }
      } else {
        setForm({
          ...form,
          service_ids: ("" + e.target.value)
        })
      }
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      })
    }
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      first_name: form.first_name.toLowerCase().trim(),
      last_name: form.last_name.toLowerCase().trim(),
      email: form.email.toLowerCase().trim(),
      phone: form.phone.trim(),
      service_ids: form.service_ids
    }
    if (cleanData.first_name && cleanData.last_name && cleanData.email && cleanData.phone && cleanData.service_ids) {
      if (cleanData.phone.length !== 10) {
        setError('Please enter a valid mobile number')
      } else {
        const response = await editAdminById(data.id, cleanData);
        if (response) {
          if (response === 401) {
            useLocalStorage.removeAdmin();
            logout();
            navigate('/admin/login')
          } else {
            setForm({
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              service_ids: ""
            })
          }
          hideEditModal();
          fetchData();
        } else {
          setForm({
            ...cleanData
          })
        }
      }
    } else {
      setError('Please fill all mandatory fields')
    }
  }, [form, hideEditModal, logout])

  console.log(form.service_ids)

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Add Admin</h3>
          <button className='close-btn' onClick={hideEditModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>First Name<span className='mandatory-field'>&#42;</span></label>
                <input name="first_name" value={form.first_name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Last Name<span className='mandatory-field'>&#42;</span></label>
                <input name="last_name" value={form.last_name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Email<span className='mandatory-field'>&#42;</span></label>
                <input name="email" value={form.email} onChange={changeHandler} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
              </div>
              <div className='modal-input-box'>
                <label>Phone<span className='mandatory-field'>&#42;</span></label>
                <input type="number" name="phone" value={form.phone} onChange={changeHandler} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
              </div>
              <div className='modal-input-box'>
                <label>Services<span className='mandatory-field'>&#42;</span></label>
                <ul>
                  {serviceList ?
                    serviceList.map(function (item) {
                      return (
                        <li><label><input type='checkbox' checked={form.service_ids.split(',').indexOf("" + item.id) !== -1} name='service_ids' value={item.id} onChange={changeHandler} /> {item.name}</label></li>
                      )
                    }) : ''}
                </ul>
              </div>
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button className='create'>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(EditAdmin)  