import React, { useState, useEffect, useCallback } from 'react';
import { RiArrowUpDownLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import Properties from './Properties';
import Filters from './Filters';
import { showFilters } from '../../../Api/filter';
import SellProperty from './Sell Property/SellProperty';


const SearchResults = ({ selectedTab }) => {
    const searchParams = useLocation().search.split('?')[1].split('&').map(param => param.split('='));
    const path = useLocation().search;
    const navigate = useNavigate();

    const [urlState, setUrlState] = useState({
        property_types: "",
        is_top: 0,
        filters: "",
        search: "",
        sort: 3
    })

    const [state, setState] = useState({
        property_types: "",
        is_top: 0,
        filters: "",
        search: ""
    })

    const [showApply, setShowApply] = useState(false)

    const [propertyTypes, setPropertyTypes] = useState([]);
    const [filters, setFilters] = useState([]);
    const [locations, setLocations] = useState([])

    const propertyTypesHandler = useCallback((id) => {
        let data = [];
        if (state.property_types.split(',').length > 1 || ("" + state.property_types.split(',')[0]).trim() !== "") {
            data = state.property_types.split(',');
        }
        const index = data.indexOf('' + id);
        if (index !== -1) {
            data.splice(index, 1);
        } else {
            data.push(id);
        }
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                for (let j = i + 1; j < data.length; j++) {
                    if (parseInt(data[i]) > parseInt(data[j])) {
                        const temp = data[i];
                        data[i] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }
        if (data && data.length > 0 && ("" + data[0]).trim() === '') {
            data.splice(0, 1)
        }

        data.sort()

        setState({
            ...state,
            property_types: data.join()
        })
    }, [state])

    const filtersHandler = useCallback((id) => {
        let data = [];
        if (state.filters.split(',').length > 1 || ("" + state.filters.split(',')[0]).trim() !== "") {
            data = state.filters.split(',');
        }
        const index = data.indexOf('' + id);
        if (index !== -1) {
            data.splice(index, 1);
        } else {
            data.push(id);
        }
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                for (let j = i + 1; j < data.length; j++) {
                    if (parseInt(data[i]) > parseInt(data[j])) {
                        const temp = data[i];
                        data[i] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }
        if (data && data.length > 0 && ("" + data[0]).trim() === '') {
            data.splice(0, 1)
        }

        setState({
            ...state,
            filters: data.join()
        })
    }, [state])

    const propertyTypesClearHandler = useCallback(() => {
        setState({
            ...state,
            property_types: ""
        })
    }, [state])

    const locationsHandler = useCallback((value) => {
        let data = [];
        if (state.search.split(',').length > 1 || ("" + state.search.split(',')[0]).trim() !== "") {
            data = state.search.split(',');
        }
        const index = data.indexOf('' + value);
        if (index !== -1) {
            data.splice(index, 1);
        } else {
            data.push(value);
        }
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                for (let j = i + 1; j < data.length; j++) {
                    if (parseInt(data[i]) > parseInt(data[j])) {
                        const temp = data[i];
                        data[i] = data[j];
                        data[j] = temp;
                    }
                }
            }
        }
        if (data && data.length > 0 && ("" + data[0]).trim() === '') {
            data.splice(0, 1)
        }
        data.sort()

        setState({
            ...state,
            search: data.join()
        })
    }, [state])

    const locationsClearHandler = useCallback(() => {
        setState({
            ...state,
            search: ""
        })
    }, [state])

    const filtersClearHandler = useCallback((values) => {
        let filtersData = [];
        if (state.filters.split(',').length > 1 || ("" + state.filters.split(',')[0]).trim() !== "") {
            filtersData = state.filters.split(',');
        }
        if (filtersData && filtersData.length > 0) {
            values.forEach(value => {
                if (filtersData.indexOf("" + value) !== -1) {
                    filtersData.splice(filtersData.indexOf("" + value), 1)
                }
            })
        }
        setState({
            ...state,
            filters: (filtersData && filtersData.length) > 0 ? filtersData.join() : ""
        })
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await showFilters();
        if (apiResponse) {
            if (apiResponse.filters && apiResponse.filters.length > 0) {
                setFilters([...apiResponse.filters])
            } else {
                setFilters([])
            }

            if (apiResponse.property_types && apiResponse.property_types.length > 0) {
                setPropertyTypes([...apiResponse.property_types])
            } else {
                setPropertyTypes([])
            }

            if (apiResponse.locations && apiResponse.locations.length > 0) {
                setLocations([...apiResponse.locations])
            } else {
                setLocations([])
            }
        } else {
            setPropertyTypes([]);
            setFilters([]);
            setLocations([])
        }
    }, [propertyTypes, filters, locations])

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (searchParams.length > 0) {
            const data = {};
            searchParams.forEach(param => {
                data[param[0]] = param[1].trim();
            })
            data.search = data.search.split('%20').join(' ').trim();
            setUrlState(data);
            setState({
                property_types: data.property_types,
                is_top: data.is_top,
                filters: data.filters,
                search: data.search,
                sort: parseInt(data.sort) || 3
            })
        }
    }, [path])

    useEffect(() => {
        if (urlState.property_types !== state.property_types || urlState.filters !== state.filters || state.is_top !== urlState.is_top || urlState.search !== state.search) {
            setShowApply(true)
        } else {
            setShowApply(false)
        }
    }, [state])

    const topPropertyHandler = useCallback(() => {
        if (state.is_top == '1') {
            setState({
                ...state,
                is_top: ''
            })
        } else {
            setState({
                ...state,
                is_top: '1'
            })
        }
    }, [state])
    const sortHandler = useCallback((e) => {
        if (e.target.value > 0 && e.target.value <= 6) {
            setState({
                ...state,
                sort: e.target.value
            })
            navigate(`/services/realestate/properties?search=${state.search}&property_types=${state.property_types}&filters=${state.filters}&is_top=${state.is_top}&sort=${e.target.value}&page=1`)
        }
    }, [state])

    const applyFiltersHandler = useCallback(() => {
        navigate(`/services/realestate/properties?search=${state.search}&property_types=${state.property_types}&filters=${state.filters}&is_top=${state.is_top}&sort=3&page=1`)
    }, [state])
    if (selectedTab === 'sell') {
        return (
            <div className='realestate-data-container'>
                <SellProperty />
            </div>
        )
    }
    return (
        <div className='realestate-data-container'>
            <div className='wrapper realestate-results'>
                <div className='content-wrapper'>
                    <div className='filters-section'>
                        <h2>Filter By</h2>
                        <div className='filters'>
                            {(propertyTypes && propertyTypes.length > 0) && <Filters onChange={propertyTypesHandler} selectedFilters={state.property_types} clear={propertyTypesClearHandler} header={'Property Types'} data={propertyTypes} />}
                            {(filters && filters.length > 0) && filters.map(filter => {
                                if (filter.filters && filter.filters.length > 0) {
                                    return (
                                        <Filters clear={filtersClearHandler} onChange={filtersHandler} selectedFilters={state.filters} header={filter.name} data={filter.filters} />
                                    )
                                }
                            })}
                            {(locations && locations.length > 0) && (
                                <Filters clear={locationsClearHandler} onChange={locationsHandler} selectedFilters={state.search} header='Locations' data={locations} isLocation={true} />
                            )}
                            <div className='filter active'>
                                <ul>
                                    <li>
                                        <label>
                                            <input onChange={topPropertyHandler} checked={state.is_top == '1'} type='checkbox' />
                                            Top Properties
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {showApply && (
                            <div className='filters-action'>
                                <button onClick={applyFiltersHandler}>Apply Filters</button>
                            </div>
                        )}
                    </div>
                    <div className='all-properties-section'>
                        <div className='sort'>
                            <RiArrowUpDownLine />
                            <span>Sort By:</span>
                            <select value={state.sort} onChange={sortHandler}>
                                <option value={1}>Most Popular</option>
                                <option value={2}>Most Viewed</option>
                                <option value={3}>Latest</option>
                                <option value={4}>Oldest</option>
                                <option value={5}>Price: High</option>
                                <option value={6}>Price: Low</option>
                            </select>
                        </div>
                        <Properties />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchResults