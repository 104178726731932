import api from "../utils/api";
import useLocalStorage from "../utils/useLocalStorage";
import { toast } from "react-toastify";

export const login = async (data) => {
  const apiResponse = await api.applicationApi(
    "admins/login",
    "POST",
    data,
    null
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      useLocalStorage.setAdmin(apiResponse.data.token);
      toast.success(apiResponse.data.message);
      return apiResponse.data.data;
    } else {
      console.log(apiResponse.data.message);
      toast.error(apiResponse.data.message);
      // if (apiResponse.status === 401) {
      //     return 401;
      // }
      return false;
    }
  } else {
    return false;
  }
};

export const adminLogout = async () => {
  const apiResponse = await api.applicationApi(
    "admins/logout",
    "POST",
    null,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const dashboard = async () => {
  const apiResponse = await api.applicationApi(
    "admins/dashboard",
    "POST",
    null,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const fetchAdminDetails = async () => {
  const apiResponse = await api.applicationApi(
    "admins/fetchAdminDetails",
    "POST",
    null,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const fetchAdmins = async (data) => {
  const apiResponse = await api.applicationApi(
    "admins/fetchAdmins",
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const fetchSuperAdmins = async (data) => {
  const apiResponse = await api.applicationApi(
    "admins/fetchSuperAdmins",
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const addAdmin = async (data) => {
  const apiResponse = await api.applicationApi(
    "admins/addAdmin",
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const addSuperAdmin = async (data) => {
  const apiResponse = await api.applicationApi(
    "admins/addSuperAdmin",
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const editAdminById = async (id, data) => {
  const apiResponse = await api.applicationApi(
    `admins/editAdmin/${id}`,
    "PUT",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.data.message);
      return false;
    }
  } else {
    return false;
  }
};

export const editAdmin = async (data) => {
  const apiResponse = await api.multipartApi(
    "admins/editAdmin",
    "PUT",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.message);
      return true;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      toast.error(apiResponse.message);
      return false;
    }
  } else {
    return false;
  }
};

export const activateDeactivateAdmin = async (id, data) => {
  const apiResponse = await api.applicationApi(
    `admins/activateDeactivateAdmin/${id}`,
    "PATCH",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const adminChangePassword = async (data) => {
  const apiResponse = await api.applicationApi(
    `admins/changePassword`,
    "PATCH",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      toast.error(apiResponse.data.message);
      if (apiResponse.status === 401) {
        return 401;
      }
      return false;
    }
  } else {
    return false;
  }
};

export const forgotPassword = async (data) => {
  const apiResponse = await api.applicationApi(
    `admins/forgotPassword`,
    "POST",
    data,
    0
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const resetPassword = async (data) => {
  const apiResponse = await api.applicationApi(
    `admins/resetPassword`,
    "PATCH",
    data,
    0
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      toast.success(apiResponse.data.message);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

//Cases api
export const fetchCases = async (data) => {
  const apiResponse = await api.applicationApi(
    "cases/fetchCases",
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

//transactions api
export const fetchTransactions = async (data) => {
  const apiResponse = await api.applicationApi(
    "transaction/fetchTransactions",
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else if (apiResponse.status === 401) {
      return 401;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
// Price Consultation API
export const fetchPriceConsultation = async (data) => {
  const apiResponse = await api.applicationApi(
    `priceConsultation/fetchPriceConsultation`,
    "POST",
    data,
    1
  );
  if (apiResponse) {
    if (apiResponse.status === 200) {
      return apiResponse.data.data;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
