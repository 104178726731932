import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./readypage.css";
import { AiOutlineArrowRight } from "react-icons/ai";
import "react-phone-input-2/lib/style.css";
import FillDetails from "./FillDetails";
import { bindActionCreators } from "redux";
import { loadUserRequest } from "../../../../Actions/user";
import { connect } from "react-redux";
import SellPropertyDetail from "./SellPropertyDetail";

function ReadyPage({ user }) {
  const [userDetails, setUserDetails] = useState(true);
  const [userData, setUserData] = useState(null);
  const [checked, setChecked] = useState(true);
  const [nextPage, setNextPage] = useState(false);
  const navigate = useNavigate();

  const onDataEntered = (formData) => {
    setUserData(formData);
    setNextPage(true);
  };
  const location = useLocation();
  const formDatas = location.state ? location.state.formDatas : null;
  const onBackClick = () => {
    setNextPage(false); // Set nextPage to false to go back to the previous component
  };
  const handleContinue = () => {
    if (userDetails) {
      // If the checkbox is checked, proceed as usual
      setNextPage(true);
    } else {
      // If the checkbox is unchecked, load different content
      setChecked(false);
    }
  };

  return (
    <div className="container">
      <div className="wrapper sell-property">
        {nextPage ? (
          <SellPropertyDetail
            user={userDetails ? user.data : userData}
            onBackClick={onBackClick}
          />
        ) : (
          <div className="detail-box">
            <div className="header">
              <p>
                Ready ! <span>To sell</span> your property
              </p>
              <p>We ensure 100% trust and zero hassle selling experience</p>
            </div>
            {!checked ? (
              <FillDetails onDataEntered={onDataEntered} />
            ) : (
              <div className="details">
                <p>Personal Details</p>
                <div className="personal-details">
                  {formDatas !== null ? (
                    <>
                      <p>Name: {formDatas?.contact_name}</p>
                      <p>Email: {formDatas?.contact_email}</p>
                      <p>Contact Detail: {formDatas?.contact_number}</p>
                    </>
                  ) : userData !== null ? (
                    <>
                      <p>Name : {userData?.name}</p>
                      <p>Email : {userData?.email}</p>
                      <p>Contact Detail :{userData?.phone}</p>
                    </>
                  ) : (
                    <>
                      <p>
                        Name: {user.data.first_name} {user.data.last_name}
                      </p>
                      <p>Email : {user.data.email}</p>
                      <p>Contact Detail : {user.data.phone}</p>
                    </>
                  )}
                </div>
                <div className="input-div">
                  <input
                    type="checkbox"
                    name="details"
                    id="details"
                    value="1"
                    checked={userDetails}
                    onChange={() => setUserDetails(!userDetails)}
                  />
                  <label htmlFor="details">
                    Use these details to contact for sales and other
                    communication. Uncheck to add another contact details.
                  </label>
                </div>

                <button type="button" onClick={handleContinue}>
                  <p>Continue</p> <AiOutlineArrowRight />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        {/* <Slider />
        <Caurasal /> */}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadUser: loadUserRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReadyPage);
