import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { showTopProperties } from '../../../Api/properties';

const TopProperties = () => {
    const [properties, setProperties] = useState([]);

    const fetchData = async () => {
        const apiResponse = await showTopProperties();
        if (apiResponse) {
            setProperties(apiResponse)
        } else {
            setProperties([]);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='wrapper realestate-top'>
            <div className='content-wrapper'>
                <div className='header'>
                    <h3>Our Top Properties</h3>
                    <Link to="/services/realestate/properties?search=&property_types=&filters=&is_top=&sort=3&page=1">View All <AiOutlineArrowRight size={12} /></Link>
                </div>
                {(properties && properties.length > 0) && (
                    <ul>
                        {properties.map(property => (
                            <li>
                                <div>
                                    <Link to={`/services/realestate/propertyDetails/${property.id}`}><img src={`https://nriconnect.io/realEstate/Uploads/${property.thumbnail}`} /></Link>
                                </div>
                                <h4>{property.name}</h4>
                                <p>{`${property.city}, ${property.state}`}</p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default TopProperties