import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Hero from "./Hero";
import Details from "./Details";
import SearchResults from "./SearchResults";
import Property from "./Property/Property";
import Faqs from "./Faqs";
import "./realestate.css";

const RealEstate = () => {
  const path = useLocation().pathname;
  const [selectedTab, setSelectedTab] = useState("buy"); // Default to "buy"
  return (
    <div className="container">
      <Hero selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {path.includes("/realestate/properties") ? (
        <SearchResults selectedTab={selectedTab} />
      ) : path.includes("/realestate/propertyDetails") ? (
        <Property />
      ) : (
        <Details selectedTab={selectedTab} />
      )}
      <Faqs />
    </div>
  );
};

export default RealEstate;
