import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { loadAdminRequest } from '../../../Actions/admin';
import { GoSearch } from 'react-icons/go';
import useLocalStorage from '../../../utils/useLocalStorage';
import { fetchCases } from '../../../Api/admin';


const Cases = () => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        request_type: 0,
        offset: 0,
        search: ''
    });
    const [cases, setCases] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1);
    // const [search, setSearch] = useState("");

    useEffect(() => {
        fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchCases(state);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setCases([...apiResponse.cases]);
                if (apiResponse.total_cases > 0) {
                    if (apiResponse.total_cases % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_cases / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_cases / 10))
                    }
                }
            }
        } else {
            setCases([]);
            setTotalPages(0)
        }
    }, [state])

    const requestTypeHandler = useCallback((e) => {
        setState({
            ...state,
            request_type: e.target.value
        })
    }, [state])

    // const searchHandler = useCallback((e) => {
    //     setSearch(e.target.value)
    // }, [search])

    // const submitSearchHandler = useCallback(() => {
    //     setState({
    //         ...state,
    //         offset: 0,
    //         search: search
    //     })
    // }, [search, state])

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, [])

    return (
        <>
            <div className='admin-container'>
                <div className="breadcrumbs">
                    <p>Admin <b>/ Cases</b></p>
                    <span>Cases</span>
                </div>
                <div className='content-wrapper'>
                    <div className='table-view'>
                        <div className='header'>
                            <h1>Cases</h1>
                        </div>
                        <div className='table-filters'>
                            {/* <div className='search'>
                                <input placeholder='Search...' type='text' value={search} onChange={searchHandler} />
                                <button onClick={submitSearchHandler}><GoSearch size={16} /></button>
                            </div> */}
                            <div className='dropdown'>
                                <label>Status:</label>
                                <select value={state.request_type} onChange={requestTypeHandler}>
                                    <option value={0}>All</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Not active</option>
                                </select>
                            </div>
                        </div>

                        {(cases && cases.length > 0) && (
                            <>
                                <div className='table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Case Id</th>
                                                <th>Plan</th>
                                                <th>User</th>
                                                <th>Date Added</th>
                                                <th>Date Modified</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cases.map((item, index) => (
                                                 <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.id}</td>
                                                    <td className='text-capitalize'>{item.plan_name}</td>
                                                    <td className='text-capitalize'>{item.user_name}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.updated_at}</td>
                                                    <td>
                                                        <span className={item.is_active === 1 ? 'active' : 'deactive'} >
                                                           {item.is_active === 1 ? 'Active' : 'Not Active'}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                               {(totalPages && totalPages > 1) ?  
                                <div className='admin-pagination'>
                                    <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                                        <AiFillCaretLeft size={16} />
                                    </button>
                                    <span>{`Page ${currentPage} / ${totalPages}`}</span>
                                    <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                                        <AiFillCaretRight size={16} />
                                    </button>
                                </div>
                                :
                                <></>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    loadAdmin: loadAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(Cases)
