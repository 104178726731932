import api from "../utils/api";
import { toast } from "react-toastify";

export const fetchSchedules = async (data) => {
    const apiResponse = await api.applicationApi('schedules/fetchSchedules', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const addSchedule = async (data) => {
    const apiResponse = await api.applicationApi('schedules/addSchedule', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.message)
            return true;
        } else {
            toast.error(apiResponse.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const fetchSlots = async (id, data) => {
    const apiResponse = await api.applicationApi(`schedules/fetchSlots/${id}`, 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateSchedule = async (id, data) => {
    const apiResponse = await api.applicationApi(`schedules/activateDeactivateSchedule/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.message)
            return true;
        } else {
            toast.error(apiResponse.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateSlot = async (id, data) => {
    const apiResponse = await api.applicationApi(`schedules/activateDeactivateSlot/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.message)
            return true;
        } else {
            toast.error(apiResponse.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const showSlots = async (data) => {
    const apiResponse = await api.applicationApi(`schedules/showSlots`, 'POST', data, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}