import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import useLocalStorage from '../../../utils/useLocalStorage';
import { loadServiceProviderRequest } from '../../../Actions/serviceProvider';
import { serviceProviderLogin } from '../../../Api/serviceProvider';

const ServiceProviderLogin = ({ loadServiceProvider, isServiceProviderLoggedIn }) => {

    const navigate = useNavigate();

    const [form, setForm] = useState({
        email: '',
        password: ''
    });
    const [showPassword, setShowPassword] = useState(false);

    const [error, setError] = useState(false);

    const showPasswordHandler = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])

    const changeHandler = useCallback((e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }, [form]);

    const submitHandler = async (e) => {
        e.preventDefault();
        setError(false)
        const cleanData = {
            email: form.email.trim(),
            password: form.password.trim()
        }

        if (cleanData.email && cleanData.password) {
            const response = await serviceProviderLogin(cleanData);
            if (response) {
                loadServiceProvider(response);
                setForm({
                    email: "",
                    password: ''
                })
                navigate('/serviceProvider/properties');
            }
        } else {
            setForm(cleanData);
            setError('please fill all the mandatory fields');
        }
    }

    return (
        <>
            {(!useLocalStorage.getServiceProvider() || !isServiceProviderLoggedIn) && (
                <div className='admin-auth-container'>
                    <div className='auth-box'>
                        <p>Login to Service Provider Panel</p>
                        <form onSubmit={submitHandler}>
                            <div className={form.email ? 'input-box active' : 'input-box'}>
                                <label>Email</label>
                                <input onChange={changeHandler} placeholder="Email" name="email" type='email' value={form.email} />
                            </div>
                            <div className={form.password ? 'input-box active' : 'input-box'}>
                                <label>Password</label>
                                <input onChange={changeHandler} placeholder="Password" name='password' value={form.password} type={showPassword ? 'text' : 'password'} />
                                <button type='button' onClick={showPasswordHandler}>{showPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
                            </div>
                            <Link to="/serviceProvider/forgotPassword">Forgot Password?</Link>
                            {error && (<p className='error-message'>{error}</p>)}
                            <button type='submit'>Login</button>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

const mapStateToProps = state => ({
    isServiceProviderLoggedIn: state.serviceProvider.isAuthenticated
})

const mapDispatchToProps = dispatch => bindActionCreators({
    loadServiceProvider: loadServiceProviderRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderLogin)