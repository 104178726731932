import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { loadAdminRequest } from "../../../Actions/admin";
import { GoSearch } from "react-icons/go";
import useLocalStorage from "../../../utils/useLocalStorage";
import { fetchRealEstateEnquiries } from "../../../Api/realestateEnquiries";

const RealEstateEnquiries = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    offset: 0,
    search: "",
  });
  const [enquiries, setEnquiry] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentpage] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchData();
  }, [state]);

  const fetchData = useCallback(async () => {
    const apiResponse = await fetchRealEstateEnquiries(state);
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeAdmin();
        navigate("/admin/login");
      } else {
        setEnquiry([...apiResponse.realestate_enquiries]);
        if (apiResponse.total_realestate_enquiries > 0) {
          if (apiResponse.total_realestate_enquiries % 10 > 0) {
            setTotalPages(
              parseInt(apiResponse.total_realestate_enquiries / 10) + 1
            );
          } else {
            setTotalPages(
              parseInt(apiResponse.total_realestate_enquiries / 10)
            );
          }
        }
      }
    } else {
      setEnquiry([]);
      setTotalPages(0);
    }
  }, [state]);

  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search]
  );

  const submitSearchHandler = useCallback(() => {
    setState({
      ...state,
      offset: 0,
      search: search,
    });
  }, [search, state]);

  const currentPageHandler = useCallback((pageNumber) => {
    setCurrentpage(pageNumber);
    setState({
      ...state,
      offset: (pageNumber - 1) * 10,
    });
  }, []);

  return (
    <>
      <div className="admin-container">
        <div className="breadcrumbs">
          <p>
            Admin / Properties <b>/ Enquiries</b>
          </p>
          <span>Enquiries</span>
        </div>
        <div className="content-wrapper">
          <div className="table-view">
            <div className="header">
              <h1>Enquiries</h1>
            </div>
            <div className="table-filters">
              <div className="search">
                <input
                  placeholder="Search..."
                  type="text"
                  value={search}
                  onChange={searchHandler}
                />
                <button onClick={submitSearchHandler}>
                  <GoSearch size={16} />
                </button>
              </div>
            </div>

            {enquiries && enquiries.length > 0 && (
              <>
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Enquiry Number</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Country</th>
                        <th>Date Added</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enquiries.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.enquiry_id}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                          <td>{item.country}</td>
                          <td>{item.created_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {totalPages && totalPages > 1 ? (
                  <div className="admin-pagination">
                    <button
                      type="button"
                      className={currentPage === 1 ? "disabled" : ""}
                      onClick={
                        currentPage === 1
                          ? null
                          : () => currentPageHandler(currentPage - 1)
                      }
                    >
                      <AiFillCaretLeft size={16} />
                    </button>
                    <span>{`Page ${currentPage} / ${totalPages}`}</span>
                    <button
                      type="button"
                      className={currentPage === totalPages ? "disabled" : ""}
                      onClick={
                        currentPage === totalPages
                          ? null
                          : () => currentPageHandler(currentPage + 1)
                      }
                    >
                      <AiFillCaretRight size={16} />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadAdmin: loadAdminRequest,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(RealEstateEnquiries);
