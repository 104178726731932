import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { BiChevronDown } from "react-icons/bi";
import { fetchPropertiesSearchData } from "../../../Api/properties";

const Search = () => {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const searchParams = useLocation.search;

  const [state, setState] = useState(
    searchParams
      ? searchParams
          .split("?")[1]
          .split("&")
          .map((param) => param.split("="))[0][1]
      : ""
  );
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const apiResponse = await fetchPropertiesSearchData();
    if (apiResponse) {
      setData([...apiResponse]);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  const changeHandler = useCallback(
    (e) => {
      setState(e.target.value);
    },
    [state]
  );

  const submitHandler = useCallback(() => {
    if (state) {
      navigate(
        `/services/realestate/properties?search=${state}&property_types=&filters=&is_top=&sort=3&page=1`
      );
    }
  }, [state]);

  return (
    <div className="realestate-search">
      {/* <h2>Find Property</h2> */}
      <div className="search-bar">
        <div className="search-bar-inputs">
          {/* <div className="search-select">
            <select>
              <option>Buy</option>
            </select>
            <BiChevronDown className="down-icon" size={16} />
          </div> */}
          <div className="location-select search-select">
            <IoLocationOutline className="location-icon" size={16} />
            <select value={state} onChange={changeHandler}>
              <option value="" disabled style={{ display: "none" }}>
                Enter Landmark, Location
              </option>
              {data && data.length > 0 && (
                <>
                  {data.map((item) => (
                    <option key={`search-${item}`} value={item}>
                      {item}
                    </option>
                  ))}
                </>
              )}
            </select>
            <BiChevronDown className="down-icon" size={16} />
          </div>
        </div>
        <button type="button" onClick={submitHandler}>
          {path.includes("/realestate/properties") ||
          path.includes("/realestate/propertyDetails")
            ? "Modify Search"
            : "Search Now"}
        </button>
      </div>
    </div>
  );
};

export default Search;
