import React, { useCallback, useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLocalStorage from '../../../utils/useLocalStorage';
import { editFilter } from '../../../Api/filter';
import { useNavigate } from 'react-router-dom';


const EditFilter = ({ hideEditModal, fetchData, data, logout }) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({ ...data })
  const [error, setError] = useState(false);

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      name: form.name.toLowerCase().trim(),
      value: form.value,
      min_value: form.min_value,
      max_value: form.max_value,
      placement_order: form.placement_order
    }
    if (cleanData.name) {
      const response = await editFilter(data.id, cleanData);
      if (response) {
        if (response === 401) {
          useLocalStorage.removeAdmin();
          logout();
        } else {
          setForm({
            name: "",
            value: 0,
            min_value: 0,
            max_value: 0,
            placement_order: 0
          })
        }
        hideEditModal();
        fetchData();
      } else {
        setForm({
          ...cleanData
        })
      }

    } else {
      setError('Please fill all mandatory fields')
    }
  }, [form, hideEditModal, logout])


  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Edit Filter</h3>
          <button className='close-btn' onClick={hideEditModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              {form.category_id != 0 && (
                <>
                  <div className='modal-input-box'>
                    <label>Name<span className='mandatory-field'>&#42;</span></label>
                    <input name="name" value={form.name} onChange={changeHandler} required />
                  </div>
                  {form.type == 1 && (
                    <>
                      <div className='modal-input-box'>
                        <label>Minimum Value</label>
                        <input type="number" name="min_value" value={form.min_value} onChange={changeHandler} />
                      </div>
                      <div className='modal-input-box'>
                        <label>Maximum Value</label>
                        <input type="number" name="max_value" value={form.max_value} onChange={changeHandler} />
                      </div>
                    </>
                  )}
                  {form.type == 2 && (
                    <div className='modal-input-box'>
                      <label>Value</label>
                      <input name="value" value={form.value} onChange={changeHandler} required />
                    </div>
                  )}
                  {form.type == 3 && (
                    <div className='modal-input-box'>
                      <label>Maximum Value</label>
                      <input type="number" name="max_value" value={form.max_value} onChange={changeHandler} />
                    </div>
                  )}
                  {form.type == 4 && (
                    <div className='modal-input-box'>
                      <label>Minimum Value</label>
                      <input type="number" name="min_value" value={form.min_value} onChange={changeHandler} />
                    </div>
                  )}
                  <div className='modal-input-box'>
                    <label>Placement Order</label>
                    <input type="number" name="placement_order" value={form.placement_order} onChange={changeHandler} />
                  </div>
                </>
              )}
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button className='create'>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(EditFilter)