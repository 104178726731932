import { toast } from 'react-toastify';
import api from '../utils/api';

export const addPropertyService = async (data) => {
    const apiResponse = await api.multipartApi('propertyServices/addPropertyService', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editPropertyService = async (id, data) => {
    const apiResponse = await api.multipartApi(`propertyServices/editPropertyService/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivatePropertyService = async (id, data) => {
    const apiResponse = await api.applicationApi(`propertyServices/activateDeactivatePropertyService/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const fetchPropertyServices = async (data) => {
    const apiResponse = await api.applicationApi('propertyServices/fetchPropertyServices', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            if (apiResponse.status === 401) {
                return 401;
            }
            if (apiResponse.status === 403) {
                toast.error(apiResponse.data.message);
            }
            return false;
        }
    } else {
        return false;
    }
}

