import api from "../utils/api";
import { toast } from "react-toastify";


export const addTestimonial = async (data) => {
    const apiResponse = await api.multipartApi('testimonials/addTestimonial', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editTestimonial = async (id, data) => {
    const apiResponse = await api.multipartApi(`testimonials/editTestimonial/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}

export const fetchTestimonials = async (data) => {
    const apiResponse = await api.applicationApi('testimonials/fetchTestimonials', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateTestimonial = async (id, data) => {
    const apiResponse = await api.applicationApi(`testimonials/activateDeactivateTestimonial/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.message)
            return true;
        } else {
            toast.error(apiResponse.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const showTestimonials = async () => {
    const apiResponse = await api.applicationApi('users/showTestimonials', 'POST', null, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

