import { LOAD_ADMIN, LOGOUT_ADMIN  } from "../utils/actionTypes"


export const loadAdminRequest = (data) => ({
    type: LOAD_ADMIN,
    payload: data
});

export const logoutAdminRequest = () => ({
    type: LOGOUT_ADMIN
})
