import React, { useState, useCallback, useEffect } from 'react';
import { activateDeactivateProperty, fetchProperties, propertyApproval, setTopProperty } from '../../../Api/properties';
import AddProperty from '../../../Components/Popup/Admins/AddProperty';
import EditProperty from '../../../Components/Popup/Admins/EditProperty';
import useLocalStorage from '../../../utils/useLocalStorage';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { fetchActivePropertyTypes } from '../../../Api/propertyTypes';
import { GoSearch } from 'react-icons/go';
import { CgProfile } from 'react-icons/cg';
import Property_img from '../../../Assets/Images/property_img.png';

const Properties = () => {
    const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL
    const navigate = useNavigate();
    const [state, setState] = useState({
        request_type: 0,
        offset: 0,
        property_type: 0,
        search: ''
    });
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState('');
    const [properties, setProperties] = useState([]);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1);
    const [search, setSearch] = useState("");


    const addModalHandler = () => {
        setAddModal(!addModal)
    }

    const editModalHandler = (data) => {
        setEditModal(!editModal);
        setEditData(data)
    }

    useEffect(() => {
        fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchProperties(state);
        const response = await fetchActivePropertyTypes();
        if (apiResponse || response) {
            if (apiResponse === 401 || response === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setProperties([...apiResponse.properties]);
                setPropertyTypes([...response])
                if (apiResponse.total_properties > 0) {
                    if (apiResponse.total_properties % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_properties / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_properties / 10))
                    }
                }
            }
        } else {
            setProperties([]);
            setTotalPages(0)
        }
    }, [state])

    const requestTypeHandler = useCallback((e) => {
        setState({
            ...state,
            request_type: e.target.value
        })
    }, [state])

    const propertyTypeHandler = useCallback((e) => {
        setState({
            ...state,
            property_type: e.target.value
        })
    }, [state])

    const searchHandler = useCallback((e) => {
        setSearch(e.target.value)
    }, [search])

    const submitSearchHandler = useCallback(() => {
        setState({
            ...state,
            offset: 0,
            search: search
        })
    }, [search, state])

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, [])

    const activateDeactivateHandler = async (data) => {
        let status = 0;
        if (data.is_active === 0) {
            status = 1;
        }
        const apiResponse = await activateDeactivateProperty(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login');
            } else {
                fetchData();
            }
        }
    }

    const setTopPropertyHandler = async (data) => {
        let status = 0;
        if (data.is_top === 0) {
            status = 1;
        }
        const apiResponse = await setTopProperty(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login');
            } else {
                fetchData();
            }
        }
    }

    const approvedPropertyHandler = async (data) => {
        let status = 1;
        if (data.is_approved === 1) {
            status = 0;
        }
        const apiResponse = await propertyApproval(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                fetchData();
            }
        }
    }

    const viewPropertyHandler = useCallback((id) => {
        navigate(`/admin/properties/propertyDetails/${id}`)
    }, [])

    return (
        <>
            {addModal && <AddProperty fetchData={fetchData} hideAddModal={addModalHandler} />}
            {editModal && <EditProperty fetchData={fetchData} data={editData} hideEditModal={editModalHandler} />}
            <div className='admin-container'>
                <div className="breadcrumbs">
                    <p>Admin / Property Services <b>/ Properties</b></p>
                    <span>Properties</span>
                </div>
                <div className='content-wrapper'>
                    <div className='table-view'>
                        <div className='header'>
                            <h1>Properties</h1>
                            <button className='add-btn' onClick={addModalHandler}>Add</button>
                        </div>
                        <div className='table-filters'>
                            <div className='search'>
                                <input placeholder='Search...' type='text' value={search} onChange={searchHandler} />
                                <button onClick={submitSearchHandler}><GoSearch size={16} /></button>
                            </div>
                            <div className='dropdown'>
                                <label>Property Type:</label>
                                <select value={state.property_type} onChange={propertyTypeHandler}>
                                    <option value={0}>All</option>
                                    {(propertyTypes && propertyTypes.length > 0) &&
                                        propertyTypes.map(item => (
                                            <option value={item.id}>{item.name}</option>
                                        ))}
                                </select>
                            </div>
                            <div className='dropdown'>
                                <label>Status:</label>
                                <select value={state.request_type} onChange={requestTypeHandler}>
                                    <option value={0}>All</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Not active</option>
                                    <option value={3}>Approved</option>
                                    <option value={4}>Not Approved</option>
                                    <option value={5}>Top Properties</option>
                                    <option value={6}>Not assign to any admin</option>
                                </select>
                            </div>
                        </div>

                        {(properties && properties.length > 0) && (
                            <>
                                <div className='table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Description</th>
                                                <th>Location Url</th>
                                                <th>Property Type</th>
                                                <th>Services</th>
                                                <th>Contact Email</th>
                                                <th>Contact Number</th>
                                                <th>Created By</th>
                                                <th>Updated By</th>
                                                <th>Date Added</th>
                                                <th>Date Modified</th>
                                                <th>Approval Status</th>
                                                <th>Active Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {properties.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.thumbnail.length === 0 ? <img src={Property_img} alt='img'/> : <img src={`${BASE_URL}${item.thumbnail}`} alt="" />}</td>
                                                    <td className='head' onClick={() => viewPropertyHandler(item.id)}>{item.name}</td>
                                                    <td>₹{item.price}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.location_url}</td>
                                                    <td>{item.property_type_name}</td>
                                                    <td>{item.services}</td>
                                                    <td>{item.contact_email}</td>
                                                    <td>{item.contact_number}</td>
                                                    <td>{item.updated_by_role}</td>
                                                    <td>{item.updated_by}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.updated_at}</td>
                                                    <td>
                                                        <span className={item.is_approved === 1 ? 'active' : 'deactive'} >
                                                            {item.is_approved === 0 ? 'Not Approved' : 'Approved'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className={item.is_active === 1 ? 'active' : 'deactive'} >
                                                            {item.is_active === 1 ? 'Active' : 'Not Active'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className='table-actions'>
                                                            <button className={item.is_approved === 0 ? 'activate-btn' : 'deactivate-btn'} onClick={() => approvedPropertyHandler(item)}>
                                                                {item.is_approved === 1 ? "Disapprove" : "Approve"}
                                                            </button>
                                                            {item.created_by_role !== 4 ? <button className={item.is_active === 0 ? 'activate-btn' : 'deactivate-btn'} onClick={() => activateDeactivateHandler(item)}>
                                                                {item.is_active === 0 ? "Activate" : "Deactivate"}
                                                            </button>
                                                                : <></>}
                                                            {item.created_by_role !== 4 ? (item.is_active === 1 ? <button className='edit-btn' onClick={() => editModalHandler(item)}>
                                                                Edit
                                                            </button>
                                                                : <></>)
                                                                : <></>}
                                                            <button className='edit-btn' onClick={() => setTopPropertyHandler(item)}>
                                                                {item.is_top === 0 ? "Make Top Property" : "Remove Top Property"}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {(totalPages && totalPages > 1) ?
                                    <div className='admin-pagination'>
                                        <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                                            <AiFillCaretLeft size={16} />
                                        </button>
                                        <span>{`Page ${currentPage} / ${totalPages}`}</span>
                                        <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                                            <AiFillCaretRight size={16} />
                                        </button>
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Properties