import React, { useState, useCallback, useEffect } from 'react';
import { activateDeactivateServiceProvider, fetchServiceProviders, serviceProviderApproval } from '../../../Api/serviceProvider';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import AddServiceProvider from '../../../Components/Popup/Admins/AddServiceProvider';
import EditServiceProvider from '../../../Components/Popup/Admins/EditServiceProvider';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { loadAdminRequest } from '../../../Actions/admin';
import { GoSearch } from 'react-icons/go';
import useLocalStorage from '../../../utils/useLocalStorage';
import Avatar from '../../../Components/Avatar/Avatar';

const ServiceProviders = () => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        request_type: 0,
        offset: 0,
        service_id: 0,
        search: ''
    });
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState('');
    const [service_providers, setServiceProviders] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1);
    const [search, setSearch] = useState("");

    const addModalHandler = () => {
        setAddModal(!addModal)
    }

    const editModalHandler = (data) => {
        setEditData(data)
        setEditModal(!editModal);
    }

    useEffect(() => {
        fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchServiceProviders(state);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setServiceProviders([...apiResponse.service_providers]);
                if (apiResponse.total_service_providers > 0) {
                    if (apiResponse.total_service_providers % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_service_providers / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_service_providers / 10))
                    }
                }
            }
        } else {
            setServiceProviders([]);
            setTotalPages(0)
        }
    }, [state])

    const requestTypeHandler = useCallback((e) => {
        setState({
            ...state,
            request_type: e.target.value
        })
    }, [state])

    const searchHandler = useCallback((e) => {
        setSearch(e.target.value)
    }, [search])

    const submitSearchHandler = useCallback(() => {
        setState({
            ...state,
            offset: 0,
            search: search
        })
    }, [search, state])

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, [])

    const activateDeactivateHandler = async (data) => {
        let status = 0;
        if (data.is_active === 0) {
            status = 1;
        }

        const apiResponse = await activateDeactivateServiceProvider(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                fetchData();
            }
        }
    }
    return (
        <>
            {addModal && <AddServiceProvider fetchData={fetchData} hideAddModal={addModalHandler} />}
            {editModal && <EditServiceProvider fetchData={fetchData} data={editData} hideEditModal={editModalHandler} />}
            <div className='admin-container'>
                <div className="breadcrumbs">
                    <p>Admin / Users <b>/ Service Providers</b></p>
                    <span>Service Providers</span>
                </div>
                <div className='content-wrapper'>
                    <div className='table-view'>
                        <div className='header'>
                            <h1>Service Providers</h1>
                            <button className='add-btn' onClick={addModalHandler}>Add</button>
                        </div>
                        <div className='table-filters'>
                            <div className='search'>
                                <input placeholder='Search...' type='text' value={search} onChange={searchHandler} />
                                <button onClick={submitSearchHandler}><GoSearch size={16} /></button>
                            </div>
                            <div className='dropdown'>
                                <label>Status:</label>
                                <select value={state.request_type} onChange={requestTypeHandler}>
                                    <option value={0}>All</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Not active</option>
                                    <option value={3}>Verified</option>
                                    <option value={4}>Not Verified</option>
                                </select>
                            </div>
                        </div>
                        {(service_providers && service_providers.length > 0) && (
                            <>
                                <div className='table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Date Added</th>
                                                <th>Date Modified</th>
                                                <th>Verification Status</th>
                                                <th>Active Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {service_providers.map((item, index) => (
                                                 <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{<Avatar name={item.first_name} image={item.image} />}</td>
                                                    <td className='text-capitalize'>{item.first_name} {item.last_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.updated_at}</td> 
                                                    <td>
                                                        <span className={item.is_verified === 1 ? 'active' : 'deactive'} >
                                                           {item.is_verified === 1 ? 'Verified' : 'Not verified'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className={item.is_active === 1 ? 'active' : 'deactive'} >
                                                           {item.is_active === 1 ? 'Active' : 'Not Active'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className='table-actions'>
                                                            <button className={item.is_active === 0 ? 'activate-btn' : 'deactivate-btn'} onClick={() => activateDeactivateHandler(item)}>
                                                                {item.is_active === 0 ? "Activate" : "Deactivate"}
                                                            </button>
                                                            <button className='edit-btn' onClick={() => editModalHandler({ id: item.id, first_name: item.first_name, last_name: item.last_name, email: item.email, admin_id: item.admin_id, phone: item.phone })}>
                                                                Edit
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {(totalPages && totalPages > 1) ? 
                                <div className='admin-pagination'>
                                    <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                                        <AiFillCaretLeft size={16} />
                                    </button>
                                    <span>{`Page ${currentPage} / ${totalPages}`}</span>
                                    <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                                        <AiFillCaretRight size={16} />
                                    </button>
                                </div>
                                :
                                <></>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout: loadAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(ServiceProviders)