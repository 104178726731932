import api from "../utils/api";
import { toast } from "react-toastify";


export const addFilter = async (data) => {
    const apiResponse = await api.applicationApi('filters/addFilter', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editFilter = async (id, data) => {
    const apiResponse = await api.applicationApi(`filters/editFilter/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}

export const fetchFilters = async (data) => {
    const apiResponse = await api.applicationApi('filters/fetchFilters', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateFilter = async (id, data) => {
    const apiResponse = await api.applicationApi(`filters/activateDeactivateFilter/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const fetchFiltersAddEditData = async (data) => {
    const apiResponse = await api.applicationApi('filters/fetchFiltersAddEditData', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const showFilters = async () => {
    const apiResponse = await api.applicationApi('users/showFilters', 'POST', null, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}