import React, { useCallback, useEffect } from "react";
import styles from "./Propertydetail.module.css";
import Illustration5 from "../../../../Assets/Images/Illustration5.jpeg";
import User from "../../../../Assets/Images/user.jpg";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Accordion from "../../../../Components/Accordion/Accordion";
import View from "../../../../Assets/Images/vew_icon.png";
import Accept from "../../../../Assets/Images/accept.png";
import { AiOutlineHeart } from "react-icons/ai";
import { } from "react-icons/ai";
import { FaRegImage } from "react-icons/fa";
import GoogleMapReact from "google-map-react";
import { RiEditCircleLine } from "react-icons/ri";
import Editicon from "../../../../Assets/Images/editicon.png";
import Previewpopup from "../properlist/previewimagepopup/Previewpopup";
import {
  acceptRejectBid,
  fetchBidlist,
  fetchPropertyDetail,
  handleSoldout,
} from "../../../../Api/user";
import useLocalStorage from "../../../../utils/useLocalStorage";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import addimage from "../../../../Assets/Images/addimage.png";

const Propertydetail = () => {
  const [showbid, setShowbid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showmoreImage, setShowmoreImage] = useState(false);
  const [data, setData] = useState([]);
  const [bidData, setBidData] = useState(null);

  const BASE_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL
      : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;

  const { id } = useParams();
  const navigate = useNavigate();

  const fetchDetails = useCallback(async () => {
    setLoading(true);
    const apiResponse = await fetchPropertyDetail(id);
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeUser();
        navigate("/login");
      } else {
        setData(apiResponse);
        fetchBids(apiResponse.id);
        setLoading(false);
      }
    } else {
      setData([]);
      // setTotalPages(0);
    }
  }, [id]);

  useEffect(() => {
    fetchDetails();
  }, [id]);

  const fetchBids = useCallback(
    async (id) => {
      setLoading(true);
      const apiResponse = await fetchBidlist(id);
      if (apiResponse) {
        if (apiResponse === 401) {
          useLocalStorage.removeUser();
          navigate("/login");
        } else {
          console.log(apiResponse);
          setBidData(apiResponse);
          setLoading(false);
        }
      } else {
        setData([]);
        // setTotalPages(0);
      }
    },
    [data?.id]
  );
  var heighest_bid = 0;
  bidData &&
    bidData?.bidders.filter((val) => {
      if (heighest_bid < val.price) {
        heighest_bid = val.price;
      }
    });

  const handleAcceptRejectBid = useCallback(async (id, value) => {
    const apiResponse = await acceptRejectBid(id, { is_accepted: value });
    apiResponse && fetchBids(data?.id);
  }, []);

  const hadleShowmoreImage = (val) => {
    setShowmoreImage(true);
  };
  const handleSold = useCallback((dt) => {
    handleSoldout(id, { sold_out: dt === 1 ? 0 : 1 }, fetchDetails);
  }, []);
  return (
    <div className={styles.container}>
      {showmoreImage && (
        <Previewpopup
          thumbnail={data && data?.thumbnail}
          images={data && data?.images}
          setShowmoreImage={setShowmoreImage}
        />
      )}
      <label className={styles.navigator_link}>
        Account &gt; <Link to="/propertylists">My Properties</Link> &gt;{" "}
        {data?.name}
      </label>
      <section>
        <h2>{data?.name}</h2>
        <label className={styles.location}>
          {data?.address}, {data?.city}, {data?.state}{" "}
        </label>
      </section>
      <section className={styles.prp_detail_section}>
        <div className={styles.detail_div}>
          <div className={styles.prp_img_div}>
            <img
              src={data.length === 0 ? addimage :
                data?.thumbnail?.length === 0
                  ? addimage
                  : BASE_URL + data.thumbnail
              }
              alt="img"
              height="100%"
              width="100%"
            />
            <div className={styles.prop_img_section}>
              <div className={styles.dt_vi_li}>
                {/* <ul className={styles.prp_view}>
                  <img alt="img" src={View} height="100%" width="100%" />
                  <li>2355</li>
                </ul>
                <ul className={styles.prp_view}>
                  <AiOutlineHeart color="white" />
                  <li>255</li>
                </ul> */}
              </div>
              <div className={styles.more_img_btn}>
                <ul
                  className={styles.more_img}
                  onClick={data.length === 0 ? null :
                    data?.images?.length !== 0 ? hadleShowmoreImage : null
                  }
                >
                  <li>
                    {data?.images?.length === 0
                      ? 0
                      : data?.images?.split(",").length}
                  </li>
                  <FaRegImage size={25} />
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.img_price_detail}>
            <ul>
              <li className={styles.label}>Expected Price</li>
              <li className={styles.bold_price}>INR {data?.price}</li>
            </ul>
            <ul>
              <li className={styles.label}>Booking Amount</li>
              <li>{data?.token_amount}</li>
            </ul>
            <ul>
              <Link to={`/editproperties/${id}`}>
                <span>
                  <img src={Editicon} alt="img" />
                  {/* <RiEditCircleLine size={30} /> */}
                </span>
              </Link>
            </ul>
          </div>
          <div className={styles.sold_toggle_div}>
            <p>Property Sold Out</p>
            <label className={styles.switch} htmlFor="sold_out">
              <input
                type="checkbox"
                id="sold_out"
                checked={data?.sold_out}
                onChange={() => handleSold(data?.sold_out)}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          </div>

          <h6 className={styles.des_text}>Description</h6>
          <p className={styles.des_content} styles={{ marginBottom: "0.5rem" }}>
            Travel Sustainable Property
          </p>
          <p className={styles.des_content}>
            {data?.description} Hotel Grand Suites - Near Delhi Airport features
            air-conditioned rooms with satellite flat-screen TV in the
            Mahipalpur district of New Delhi.
          </p>

          <div style={{ height: "100vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_LOCATION_API_KEY,
              }}
              defaultCenter={{
                lat: "28.5343744",
                lng: "77.3685248",
              }}
              defaultZoom={11}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: "#000",
                }}
                // lat={parseFloat(propertyData.lattitude)}
                // lng={parseFloat(propertyData.longitude)}
                lat="28.5343744"
                lng="77.3685248"
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </div>
        <div className={styles.bid_detail_div}>
          <div className={styles.high_bid_card}>
            <div>
              <h6 className={styles.high_bid_txt}>Highest Bid</h6>
              <h5 className={styles.bold_price}>INR {heighest_bid}</h5>
            </div>
            <div>
              <label className={styles.label}>Total Bids</label>
              <p>{bidData && bidData?.bidders.length}</p>
            </div>
            <section className={styles.all_bid_btn}>
              {bidData && bidData?.bidders?.length > 4 && (
                <p type="button" calssName={styles.all_view_bid_btn}>
                  View All Bids
                </p>
              )}
            </section>
          </div>
          <h4 className={styles.bidder_heading}>Top Bidders</h4>
          {loading ? (
            <div className="loading_page">
              {" "}
              <LoadingBar />
            </div>
          ) : (
            bidData &&
            bidData?.bidders
              .filter((v, i) => showbid || i < 4)
              .map((val) => (
                <div key={val.id} className={styles.subcard_div}>
                  <h4 className={styles.bidder_name}>{val.bidder_name}</h4>
                  <div className={styles.bid_amount}>
                    <label className={styles.label}>Bid Amount</label>
                    <p>INR {val.price}</p>
                  </div>
                  <hr className={styles.hr_line} />
                  {val.is_accepted === "Pending" ? (
                    <div className={styles.action_btn_div}>
                      <span
                        onClick={() =>
                          handleAcceptRejectBid(val.id, "Rejected")
                        }
                        type="button"
                        className={styles.action_btn_div_inactive}
                      >
                        Reject
                      </span>
                      <div className={styles.ver_line} />
                      <span
                        type="button"
                        onClick={() =>
                          handleAcceptRejectBid(val.id, "Approved")
                        }
                        className={styles.accept_btn}
                      >
                        <img src={Accept} alt="img" /> Accept
                      </span>
                    </div>
                  ) : (
                    <p className={styles.action_btn_div_inactive}>
                      {val.is_accepted}
                    </p>
                  )}
                </div>
              ))
          )}
          {bidData &&
            bidData?.bidders?.length !== 0 &&
            (showbid ? (
              <p
                className={styles.view_more_btn}
                onClick={() => setShowbid(false)}
              >
                View Less Bids
              </p>
            ) : (
              bidData?.bidders?.length > 4 && (
                <p
                  className={styles.view_more_btn}
                  onClick={() => setShowbid(true)}
                >
                  View All Bids
                </p>
              )
            ))}
        </div>
      </section>
      <div className="wrapper wrapper-border-top landing-faqs">
        {/* <div className='wrapper-header'>FAQs</div> */}
        <div className="content-wrapper">
          <h2>
            Frequently asked <span>questions</span>
          </h2>
          <div className="faqs-box">
            {/* {faqs.map(faq =>  */}
            <Accordion
              className={styles.acordian}
              label="How does special pricing work?"
              content="First, you need to know how much you can borrow Knowing how much home you can afford narrows down online home searching to suitable properties, thus no times wasted consideringhomes that are not within your budget. (Pre-approvals also help prevent disappointment cause by falling for unaffordable homes."
            />{" "}
            <Accordion
              label="How does special pricing work?"
              content="First, you need to know how much you can borrow Knowing how much home you can afford narrows down online home searching to suitable properties, thus no times wasted consideringhomes that are not within your budget. (Pre-approvals also help prevent disappointment cause by falling for unaffordable homes."
            />{" "}
            <Accordion
              label="How does special pricing work?"
              content="First, you need to know how much you can borrow Knowing how much home you can afford narrows down online home searching to suitable properties, thus no times wasted consideringhomes that are not within your budget. (Pre-approvals also help prevent disappointment cause by falling for unaffordable homes."
            />
            {/* )} */}
          </div>
          <Link to="/faqs/general">
            <button className="more-faqs-btn" type="button">
              More FAQs
            </button>
          </Link>
          <div className="faqs-enquire">
            <div className="content">
              <p>Do you have any questions?</p>
              <p>Put your email address and we will get back to you</p>
            </div>
            <div className="input">
              <input placeholder="Enter your email" />
              <button type="button">Enquire Now</button>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default Propertydetail;
