import { LOAD_SERVICE_PROVIDER, LOGOUT_SERVICE_PROVIDER  } from "../utils/actionTypes"


export const loadServiceProviderRequest = (data) => ({
    type: LOAD_SERVICE_PROVIDER,
    payload: data
});

export const logoutServiceProviderRequest = () => ({
    type: LOGOUT_SERVICE_PROVIDER
})
