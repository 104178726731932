
// sidebar
export const ACTIVATE_SIDEBAR = 'ACTIVATE_SIDEBAR';
export const DEACTIVATE_SIDEBAR = 'DEACTIVATE_SIDEBAR';



// users
export const LOAD_USER = 'LOAD_USER';
export const LOGOUT_USER = 'LOGOUT_USER';



// admins
export const LOAD_ADMIN = 'LOAD_ADMIN';
export const LOGOUT_ADMIN = 'LOGOUT_ADMIN';

// service providers
export const LOAD_SERVICE_PROVIDER = 'LOAD_SERVICE_PROVIDER';
export const LOGOUT_SERVICE_PROVIDER = 'LOGOUT_SERVICE_PROVIDER';