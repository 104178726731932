import React, { useState, useCallback, useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineFolder } from "react-icons/md";
import { TbArrowsDownUp } from "react-icons/tb";
import { addUserDocs, deleteUserDoc, fetchUserDocs } from "../../../Api/user";
import useLocalStorage from "../../../utils/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import DocViewer from "../../../Components/Document Viewer/DocViewer";
import "./../../../Components/Document Viewer/DocViewer.css";

const Document = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [doc, setDoc] = useState(null);
  const [error, setError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [viewerVisible, setViewerVisible] = useState(false); // State for the viewer
  const [viewerUrl, setViewerUrl] = useState(""); // URL of the document to show in the viewer

  const BASE_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL
      : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    const apiResponse = await fetchUserDocs();
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeUser();
        navigate("/login");
      } else {
        setDocuments(apiResponse);
      }
    } else {
      setDocuments([]);
    }
  }, []);

  const changeHandler = useCallback(
    (e) => {
      console.log(e.target.files);
      if (e.target.files && e.target.files[0]) {
        setDoc(e.target.files[0]);
      }
    },
    [doc]
  );
  const openViewer = (url) => {
    setViewerUrl(url);
    setViewerVisible(true);
  };
  const removeFile = useCallback(() => {
    setDoc(null);
  }, [doc]);

  const uploadFile = useCallback(async () => {
    const cleanData = {
      doc: doc,
    };
    if (cleanData.doc) {
      setUploading(true);
      const formData = new FormData();
      formData.append("doc", cleanData.doc);

      const response = await addUserDocs(formData);
      if (response) {
        if (response === 401) {
          useLocalStorage.removeUser();
          navigate("/login");
        } else {
          setDoc(null);
        }
        fetchData();
      }
      setUploading(false);
    } else {
      setError("Please select a file");
      setUploading(false);
    }
  }, [doc]);

  const deleteFile = useCallback(async (id) => {
    const response = await deleteUserDoc(id);
    if (response) {
      if (response === 401) {
        useLocalStorage.removeUser();
        navigate("/login");
      } else {
        fetchData();
      }
    }
  }, []);

  return (
    <>
      {viewerVisible && (
        <div className="viewer-overlay">
          <div className="viewer-popup">
            {/* Your DocumentViewer component that takes viewerUrl as prop */}
            <DocViewer
              url={viewerUrl}
              onClose={() => setViewerVisible(false)}
            />
          </div>
        </div>
      )}
      <div className="table-container">
        <div className="view">
          <h1>Documents</h1>
          <div className="file">
            <div className="file-box-actions">
              <label htmlFor="doc">
                Drag and drop or <span>choose your files</span>
              </label>
              <input onChange={changeHandler} name="doc" id="doc" type="file" />
            </div>
            {doc && (
              <div className="file-box">
                <div>
                  <label>{doc.name}</label>
                  <FaTimes
                    size={16}
                    color="#626573"
                    onClick={() => removeFile()}
                  />
                </div>
                <button onClick={uploadFile} disabled={uploading}>
                  {uploading ? "Uploading..." : "Upload"}
                </button>
              </div>
            )}
          </div>
          <span>
            File must be in one of the following formats: pdf, xls, xlsx, csv,
            doc, docx, txt, ppt, pptx, png, jpg, jpeg, gif
          </span>
          {documents && documents.length > 0 && (
            <>
              <div className="tables">
                <table>
                  <thead>
                    <tr>
                      <th>Document</th>
                      <th>
                        Date Added <TbArrowsDownUp />
                      </th>
                      <th>
                        Date Modified <TbArrowsDownUp />
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((item) => (
                      <tr>
                        <td className="text-capitalize">
                          <MdOutlineFolder size={22} /> {item.name}
                        </td>
                        <td>{item.created_at}</td>
                        <td>{item.updated_at}</td>
                        <td>
                          <div className="table-actions">
                            <FiEye
                              size={20}
                              color="#626573"
                              style={{ marginRight: 10 }}
                              onClick={() =>
                                openViewer(`${BASE_URL}${item.name}`)
                              }
                            />
                            <RiDeleteBinLine
                              size={20}
                              color="#626573"
                              onClick={() => deleteFile(item.id)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="table-action">
                <a>Show All</a>
              </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Document;
