import api from "../utils/api";
import { toast } from "react-toastify";

export const realEstateEnquireNow = async (data) => {
    const apiResponse = await api.applicationApi(`users/realEstateEnquireNow`, 'POST', data, 3);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const fetchRealEstateEnquiries = async (data) => {
    const apiResponse = await api.applicationApi(`realEstateEnquiries/fetchRealEstateEnquiries`, 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}