import api from "../utils/api";
import { toast } from "react-toastify";

export const generalEnquireNow = async (data) => {
    const apiResponse = await api.applicationApi(`users/generalEnquireNow`, 'POST', data, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const fetchGeneralEnquiries = async (data) => {
    const apiResponse = await api.applicationApi(`generalEnquiries/fetchGeneralEnquiries`, 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}