import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiArrowUpDownLine } from "react-icons/ri";

const Search = () => {
  const navigate = useNavigate();
  const searchParams = useLocation().search;

  const [state, setState] = useState({
    search: "",
    sort: 0,
  });

  useEffect(() => {
    if (searchParams) {
      const data = searchParams
        .split("?")[1]
        .split("&")
        .map((param) => param.split("="));
      setState({
        search: data[0][1],
        sort: data[1][1],
      });
    }
  }, [searchParams]);

  const changeHandler = useCallback(
    (e) => {
      setState({
        ...state,
        search: e.target.value,
      });
    },
    [state]
  );

  const submitHandler = useCallback(async () => {
    navigate(`/services/financial?search=${state.search}&sort=0`);
  }, [state]);
  const clearSearch = useCallback(() => {
    setState({
      search: "",
      sort: 0,
    });
    navigate(`/services/financial?search=&sort=0`);
  }, []);

  const sortHandler = useCallback(
    (e) => {
      navigate(
        `/services/financial?search=${state.search}&sort=${e.target.value}`
      );
    },
    [state]
  );

  return (
    <div className="financial-search">
      <h2>Find Plans</h2>
      <div className="search-bar">
        <div className="plan-input">
          <input value={state.search} onChange={changeHandler} />
          {state.search && <p onClick={clearSearch}>X</p>}
        </div>
        <button onClick={submitHandler}>Search Now</button>
      </div>
      <div className="sort">
        <RiArrowUpDownLine />
        <span>Sort By:</span>
        <select value={state.sort} onChange={sortHandler}>
          <option value={"0"}>Price Low</option>
          <option value={"1"}>Price High</option>
        </select>
      </div>
    </div>
  );
};

export default Search;
