import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import AddPropertyType from '../../../Components/Popup/Admins/AddPropertyType'
import EditPropertyType from '../../../Components/Popup/Admins/EditPropertyType';
import { fetchPropertyTypes, activateDeactivatePropertyType } from '../../../Api/propertyTypes';
import { logoutAdminRequest } from '../../../Actions/admin';
import { GoSearch } from 'react-icons/go';
import useLocalStorage from '../../../utils/useLocalStorage';

const PropertyType = () => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        request_type: 0,
        offset: 0,
        search: ''
    });
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1);
    const [search, setSearch] = useState("");


    const fetchData = useCallback(async () => {
        const apiResponse = await fetchPropertyTypes(state);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setPropertyTypes([...apiResponse.property_types]);
                if (apiResponse.total_property_types > 0) {
                    if (apiResponse.total_property_types % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_property_types / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_property_types / 10))
                    }
                }
            }
        } else {
            setPropertyTypes([]);
            setTotalPages(0)
        }
    }, [state])

    useEffect(() => {
        fetchData();
    }, [state])


    const addModalHandler = () => {
        setAddModal(!addModal)
    }

    const editModalHandler = (data) => {
        setEditModal(!editModal)
        setEditData(data);
    }
    const activateDeactivateHandler = async (data) => {
        let status = 0;
        if (data.is_active === 0) {
            status = 1;
        }

        const apiResponse = await activateDeactivatePropertyType(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                fetchData();
            }
        }
    }
    const requestTypeHandler = useCallback((e) => {
        setState({
            ...state,
            request_type: e.target.value
        })
    }, [state])

    const searchHandler = useCallback((e) => {
        setSearch(e.target.value)
    }, [search])

    const submitSearchHandler = useCallback(() => {
        setState({
            ...state,
            offset: 0,
            search: search
        })
    }, [search, state])

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, [])

    return (
        <>
            {addModal && (<AddPropertyType fetch={fetchData} hideAddModal={addModalHandler} />)}
            {editModal && (<EditPropertyType fetch={fetchData} data={editData} hideEditModal={editModalHandler} />)}
            <div className='admin-container'>
                <div className="breadcrumbs">
                    <p>Admin / Property Services <b>/ Property Types</b></p>
                    <span>Property Types</span>
                </div>
                <div className='content-wrapper'>
                    <div className='table-view'>
                        <div className='header'>
                            <h1>Property Types</h1>
                            <button className='add-btn' onClick={addModalHandler}>Add</button>
                        </div>
                        <div className='table-filters'>
                            <div className='search'>
                                <input placeholder='Search...' type='text' value={search} onChange={searchHandler} />
                                <button onClick={submitSearchHandler}><GoSearch size={16} /></button>
                            </div>
                            <div className='dropdown'>
                                <label>Status:</label>
                                <select value={state.request_type} onChange={requestTypeHandler}>
                                    <option value={0}>All</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Not active</option>
                                </select>
                            </div>
                        </div>
                        {(propertyTypes && propertyTypes.length > 0) && (
                            <>
                                <div className='table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Name</th>
                                                <th>Date Added</th>
                                                <th>Date Modified</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {propertyTypes.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td className='text-capitalize'>{item.name}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.updated_at}</td>
                                                    <td>
                                                        <span className={item.is_active === 1 ? 'active' : 'deactive'} >
                                                            {item.is_active === 1 ? 'Active' : 'Not Active'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className='table-actions'>
                                                            <button className={item.is_active === 0 ? 'activate-btn' : 'deactivate-btn'} onClick={() => activateDeactivateHandler(item)}>
                                                                {item.is_active === 0 ? "Activate" : "Deactivate"}
                                                            </button>
                                                            {item.is_active === 1 ? <button className='edit-btn' onClick={() => editModalHandler({ id: item.id, name: item.name, icon: item.icon })}>
                                                                Edit
                                                            </button>
                                                                : <></>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {(totalPages && totalPages > 1) ?
                                    <div className='admin-pagination'>
                                        <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                                            <AiFillCaretLeft size={16} />
                                        </button>
                                        <span>{`Page ${currentPage} / ${totalPages}`}</span>
                                        <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                                            <AiFillCaretRight size={16} />
                                        </button>
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(PropertyType)