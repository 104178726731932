import React, { useCallback, useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { addFinancialPlanPricing, fetchFinancialPlansAddEditData, fetchFinancialPlansAddonsAddEditData } from '../../../Api/financialPlans';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../../../utils/useLocalStorage';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const AddFinancialPlanPricing = ({ hideAddModal, fetchData, logout }) => {
    const [form, setForm] = useState({
        name: "",
        price: "",
        plan_id: 0
    })
    const [error, setError] = useState(false)
    const [financial_plans_categories, setCategory] = useState([]);
    const [financial_plans, setPlans] = useState([]);
    const navigate = useNavigate();

    const changeHandler = useCallback(async (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }, [form])

    const submitHandler = useCallback(async (e) => {
        e.preventDefault();
        const cleanData = {
            name: form.name.trim(),
            price: form.price.trim(),
            plan_id: form.plan_id
        }
        if (cleanData.name && cleanData.price && cleanData.plan_id) {

            const response = await addFinancialPlanPricing(cleanData);
            if (response) {
                if (response === 401) {
                    useLocalStorage.removeAdmin();
                    logout();
                    navigate('/admin/login')
                } else {
                    setForm({
                        name: "",
                        price: "",
                        category_id: 0,
                        plan_id: 0,
                    })
                }
                hideAddModal();
                fetchData();
            } else {
                setForm({
                    ...cleanData
                })
            }
        } else {
            setError('Please fill all mandatory fields')
        }
    }, [form, hideAddModal, logout])

    useEffect(() => {
        fetch()
    }, [])

    const fetchPlansData = useCallback(async () => {
        if (form.category_id !== 0) {
            const response = await fetchFinancialPlansAddonsAddEditData({ category_id: form.category_id });
            if (response) {
                if (response === 401) {
                    useLocalStorage.removeAdmin();
                    navigate('/admin/login')
                } else {
                    setPlans([...response.financial_plans])
                }
            } else {
                setPlans([]);
            }
        } else {
            setPlans([]);
        }
    }, [form.category_id])

    useEffect(() => {
        fetchPlansData()
    }, [form.category_id])

    const fetch = useCallback(async () => {
        const apiResponse = await fetchFinancialPlansAddEditData();
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setCategory([...apiResponse.financial_plans_categories])
                if (apiResponse.financial_plans_categories.length > 0) {
                    setForm({
                        ...form,
                        category_id: apiResponse.financial_plans_categories[0].id
                    })
                }
            }
        } else {
            setCategory([]);
        }
    }, [form])


    return (
        <div className='modal-container'>
            <div className='modal'>
                <div className='header'>
                    <h3>Add Plan Add On</h3>
                    <button className='close-btn' onClick={hideAddModal} >
                        <FaTimes size={16} />
                    </button>
                </div>
                <div className='content'>
                    <form onSubmit={submitHandler}>
                        <div className='modal-input-grid'>
                            <div className='modal-input-box'>
                                <label>Category:<span className='mandatory-field'>&#42;</span></label>
                                <select name="category_id" value={form.category_id} onChange={changeHandler}>
                                    <option value={0} disabled selected style={{ display: 'none' }}>None</option>
                                    {(financial_plans_categories && financial_plans_categories.length > 0) &&
                                        financial_plans_categories.map(item => (
                                            <option value={item.id}>{item.name}</option>
                                        ))}
                                </select>
                            </div>
                            <div className='modal-input-box'>
                                <label>Plan:<span className='mandatory-field'>&#42;</span></label>
                                <select name="plan_id" value={form.plan_id} onChange={changeHandler}>
                                    <option value={0} disabled selected style={{ display: 'none' }}>None</option>
                                    {(financial_plans && financial_plans.length > 0) &&
                                        financial_plans.map(item => (
                                            <option value={item.id}>{item.name}</option>
                                        ))}
                                </select>
                            </div>
                            <div className='modal-input-box'>
                                <label>Name<span className='mandatory-field'>&#42;</span></label>
                                <input type="text" name="name" value={form.name} onChange={changeHandler} required />
                            </div>
                            <div className='modal-input-box'>
                                <label>Price<span className='mandatory-field'>&#42;</span></label>
                                <input type="number" name="price" value={form.price} onChange={changeHandler} required />
                            </div>
                        </div>
                        <div className='action-box'>
                            {error && (<p className='error-message'>{error}</p>)}
                            <button className='create'>
                                Create
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(AddFinancialPlanPricing)