import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";

const Works = () => {
  return (
    <div className="wrapper wrapper-border-top realestate-works">
      <div className="wrapper-header">How it Works</div>
      {/* <div className='content-wrapper'>
                <ul className='details'>
                    <li className='details-section'>
                        <div className='content'>
                            <h2>Online booking <span>process</span></h2>
                            <ul>
                                <li>
                                    <BsCheck size={12} />
                                    <p>Lorem ipsum dolor sit amet.</p>
                                </li>
                                <li>
                                    <BsCheck size={12} />
                                    <p>Sed ut perspiciatis unde omnis iste.</p>
                                </li>
                                <li>
                                    <BsCheck size={12} />
                                    <p>Nemo enim ipsam voluptatem quia.</p>
                                </li>
                                <li>
                                    <BsCheck size={12} />
                                    <p>Neque porro quisquam est, qui dolorem ipsum.</p>
                                </li>
                            </ul>
                        </div>
                        <div className='section-image' />
                    </li>
                    <li className='details-section'>
                        <div className='section-image' />
                        <div className='content'>
                            <h2>Let us take <span>care</span> of <span>you</span></h2>
                            <ul>
                                <li>
                                    <BsCheck size={12} />
                                    <p>Lorem ipsum dolor sit amet.</p>
                                </li>
                                <li>
                                    <BsCheck size={12} />
                                    <p>Sed ut perspiciatis unde omnis iste.</p>
                                </li>
                                <li>
                                    <BsCheck size={12} />
                                    <p>Nemo enim ipsam voluptatem quia.</p>
                                </li>
                                <li>
                                    <BsCheck size={12} />
                                    <p>Neque porro quisquam est, qui dolorem ipsum.</p>
                                </li>
                            </ul>
                            <button>Enquire Now <AiOutlineArrowRight /></button>
                        </div>
                    </li>
                </ul>
            </div> */}
      <div className="content-wrapper">
        <section>
          <div />
          <div />
          <div />
        </section>
        <section>
          <ul>
            <li>
              <BsCheck />
              <div>
                <span>01</span>
                <progress value={33.33} max={100} />
                <h5>Book a Free Consulation.</h5>
                <p>
                  Book a <b>FREE</b> 15 min consultation with our expert on call
                  or mail us your query.The expert will understand your queries
                  and will guide you next steps.
                </p>
              </div>
            </li>
            <li>
              <BsCheck />
              <div>
                <span>02</span>
                <progress value={66.66} max={100} />
                <h5>Provide us your details.</h5>
                <p>
                  Fill in the <b>application form</b> provided by our expert. It
                  should not take you more than 5-10 min to complete it.
                </p>
              </div>
            </li>
            <li>
              <BsCheck />
              <div>
                <span>03</span>
                <progress value={100} max={100} />
                <h5>The Finish Line awaits</h5>
                <p>Experience what our experience can do for you..</p>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Works;
