import React from "react";
import styles from './Previewpopup.module.css'
import primary from '../../../../../Assets/Images/user.jpg'
import cancel from '../../../../../Assets/Images/cancelicon.png'
import Parking from '../../../../../Assets/Images/parking.jpg'
import { Link } from "react-router-dom";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";
import { useEffect } from "react";
import { useState } from "react";

const Previewpopup = ({ setShowmoreImage, images, thumbnail }) => {

    const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;

    var imagesLength = images?.split(",").length

    const handlePopup = () => {
        setShowmoreImage(false)
    }
    const [mainPreview, setMainPreview] = useState(thumbnail && thumbnail || "")

    const [startIndex, setStartIndex] = useState(0);
    const [tabCount, setTabCount] = useState(1);

    useEffect(() => {
        const width = window.innerWidth;
        if (width < 768) {
            setTabCount(2)
        } else if (width >= 768 && width < 1024) {
            setTabCount(3)
        } else {
            setTabCount(5)
        }
    }, [window.innerWidth])

    const indexHandler = (type) => {
        if (type === '-') {
            if (startIndex > 0) {
                if (startIndex - tabCount >= 0) {
                    setStartIndex(startIndex - 1)
                } else {
                    setStartIndex(0)
                }
            }
        }
        if (type === "+") {
            if ((startIndex + tabCount) < imagesLength) {
                if (startIndex + tabCount <= imagesLength) {
                    setStartIndex(startIndex + 1)
                } else {
                    setStartIndex(imagesLength - 1)
                }
            }
        }
    }
    const handlePreviewImage = (e) => {
        setMainPreview(e.target.id)
    }

    return (
        <div className={styles.modal_container}>
            <div className={styles.modal_popup}>
                <>
                    <div className={styles.primary_img_div}>
                        <img alt='img' src={`${BASE_URL}${mainPreview}`} height='100%' width="100%" />
                        <img className={styles.cancel_icon} onClick={handlePopup} src={cancel} height="2rem" width="2rem" alt='img' />
                    </div>
                    <section className={styles.carousel_section}>
                        <div className={styles.plans_actions}>
                            <button type='button' onClick={((startIndex - 1) >= 0) ? () => indexHandler('-') : null} className={((startIndex - 1) >= 0) ? styles.active : ''}>
                                <AiOutlineLeft size={20} />
                            </button>
                        </div>
                        <ul className={styles.seconday_img_box}>
                            {images && images?.split(",").map((plan, index) => {
                                if (index < (startIndex + tabCount) && index >= startIndex) {
                                    return (
                                        <li className={styles.seconday_img_div}>
                                            <img alt='img' id={plan} onClick={handlePreviewImage} src={`${BASE_URL}${plan}`} height='100%' width="100%" />
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                        <div className={styles.plans_actions}>
                            <button type='button' onClick={((startIndex + tabCount) < imagesLength) ? () => indexHandler('+') : null} className={((startIndex + tabCount) < imagesLength) ? styles.active : ''}>
                                <AiOutlineRight size={20} />
                            </button>
                        </div>
                    </section>
                </>
            </div>
        </div>
    );
};

export default Previewpopup;
