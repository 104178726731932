import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import Select from 'react-select'
import useLocalStorage from '../../../utils/useLocalStorage';
import { editProperty, fetchPropertyAddEditData } from '../../../Api/serviceProvider';
import { logoutServiceProviderRequest } from '../../../Actions/serviceProvider';

const EditProperty = ({ logout, hideEditModal, fetchData, data }) => {
  const navigate = useNavigate();

  const [admins, setAdmins] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [propertyServices, setPropertyServices] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [form, setForm] = useState({
    ...data,
    thumbnailSrc: `https://nriconnect.io/realEstate/Uploads/${data.thumbnail}`,
    new_images: [],
    new_images_src: []
  })

  const [error, setError] = useState(false);

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }, [form])

  const servicesAvailablehandler = useCallback((value) => {
    setForm({
      ...form,
      services_available: value
    })
  }, [form])

  const uploadThumbnail = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      setForm({
        ...form,
        thumbnail: e.target.files[0],
        thumbnailSrc: URL.createObjectURL(e.target.files[0])
      })
    }
  }, [form])

  const uploadNewImages = useCallback((e) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const srcData = form.new_images_src;
      const images = form.new_images;
      for (let i = 0; i < e.target.files.length; i++) {
        srcData.push(URL.createObjectURL(e.target.files[i]))
        images.push(e.target.files[i])
      }
      setForm({
        ...form,
        new_images: images,
        new_images_src: srcData
      })
    }
  }, [form])

  const removeImage = useCallback((index) => {
    const images = form.images;
    const imagesSrc = form.imagesSrc;
    images.splice(index, 1);
    imagesSrc.splice(index, 1);
    setForm({
      ...form,
      images: images,
      imagesSrc: imagesSrc
    })
  }, [form])

  const removeOldImage = useCallback((index) => {
    const images = form.images.split(',');
    images.splice(index, 1);
    setForm({
      ...form,
      images: images.join(),
    })
  }, [form])

  const submitHandler = useCallback(async (e) => {
    
    let services = "";
    if (form.services_available && form.services_available.length > 0) {
      services = form.services_available.map(service => service.value).join();
    }
    const cleanData = {
      name: form.name.trim(),
      price: form.price,
      description: form.description.trim(),
      location_url: form.location_url.trim(),
      area: form.area,
      address: form.address.trim(),
      city: form.city.trim(),
      state: form.state.trim(),
      country: form.country.trim(),
      zipcode: form.zipcode.trim(),
      area: form.area.trim(),
      contact_email: form.contact_email.trim(),
      contact_number: form.contact_number.trim(),
      bedrooms: form.bedrooms,
      bathrooms: form.bathrooms,
      property_type: form.property_type,
      services_available: services,
      builder_id: form.builder_id,
      user_id: form.user_id,
      thumbnail: form.thumbnail,
      images: form.images
    }

    if (cleanData.name && cleanData.price && cleanData.description && cleanData.location_url && cleanData.address
      && cleanData.city && cleanData.state && cleanData.country && cleanData.zipcode && cleanData.area && cleanData.contact_email
      && cleanData.contact_number && cleanData.property_type && cleanData.thumbnail) {

      if (cleanData.contact_number.length !== 10) {
        setError("Please enter a valid mobile number")
      } else if (cleanData.zipcode.length !== 6) {
        setError("Please enter a valid Zipcode ")
      } else {
        const formData = new FormData();

        formData.append('name', cleanData.name);
        formData.append('description', cleanData.description);
        formData.append('price', cleanData.price);
        formData.append('location_url', cleanData.location_url);
        formData.append('address', cleanData.address);
        formData.append('city', cleanData.city);
        formData.append('state', cleanData.state);
        formData.append('country', cleanData.country);
        formData.append('zipcode', cleanData.zipcode);
        formData.append('area', cleanData.area);
        formData.append('contact_email', cleanData.contact_email);
        formData.append('contact_number', cleanData.contact_number);
        formData.append('bedrooms', cleanData.bedrooms);
        formData.append('bathrooms', cleanData.bathrooms);
        formData.append('property_type', cleanData.property_type);
        formData.append('services_available', services);
        formData.append('builder_id', cleanData.builder_id);
        formData.append('user_id', cleanData.user_id);
        formData.append('thumbnail', form.thumbnail);
        formData.append('images', form.images)

        if (form.new_images && form.new_images.length > 0) {
          form.new_images.forEach((image, index) => {
            formData.append(`new_images`, image)
          })
        }

        const response = await editProperty(form.id, formData);
        if (response) {
          if (response === 401) {
            useLocalStorage.removeServiceProvider();
            logout();
          } else {
            setForm({
              name: "",
              price: "",
              description: "",
              location_url: "",
              address: "",
              city: "",
              state: "",
              country: "",
              zipcode: "",
              area: "",
              contact_email: "",
              contact_number: "",
              bedrooms: "",
              bathrooms: "",
              services_available: "0",
              builder_id: "0",
              user_id: "0",
              property_type: "0",
              thumbnail: "",
              thumbnailSrc: "",
              new_images: "",
              new_images_src: ""
            })
          }
          hideEditModal();
          fetchData();
        }
      }
    } else {
      setError('Please fill all mandatory fields')
      setForm({ form })
    }
  }, [form, logout, hideEditModal])


  useEffect(() => {
    fetch()
  }, [])
  console.log(form.new_images)

  const fetch = useCallback(async () => {
    if (data.services_available) {
      const temp = data.services_available.split(',').map((service, index) => ({
        value: parseInt(service),
        label: data.services.split(',')[index]
      }))
      setForm({
        ...form,
        services_available: temp
      })
    }
    const apiResponse = await fetchPropertyAddEditData();
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeAdmin();
        navigate('/admin/login')
      } else {
        setAdmins([...apiResponse.admins]);
        setBuilders([...apiResponse.builders]);
        setPropertyTypes([...apiResponse.property_types]);
        if (apiResponse.property_services && apiResponse.property_services.length > 0) {
          const temp = [];
          apiResponse.property_services.forEach(item => {
            temp.push({
              value: item.id,
              label: item.name
            })
            setPropertyServices([...temp]);
          });
        }
      }
    } else {
      setAdmins([]);
      setBuilders([]);
      setPropertyServices([]);
      setPropertyTypes([]);
    }
  }, [form])
  
 console.log(form.services_available,"services")
  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Edit Property</h3>
          <button className='close-btn' onClick={hideEditModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form>
            <div className='image-box'>
              <div className='image-wrapper'>
                {form.thumbnailSrc && (<img src={form.thumbnailSrc} alt='' />)}
              </div>
              <div className='image-box-actions'>
                <label htmlFor='thumbnail'>Upload Thumbnail</label>
                <input onChange={uploadThumbnail} name="thumbnail" id='thumbnail' type='file' />
              </div><span className='mandatory-field'>&#42;</span>
            </div>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>Name<span className='mandatory-field'>&#42;</span></label>
                <input name="name" value={form.name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Price<span className='mandatory-field'>&#42;</span></label>
                <input type='number' name="price" value={form.price} onChange={changeHandler} required />
              </div>
            </div>
            <div className='modal-input-box'>
              <label>Location<span className='mandatory-field'>&#42;</span></label>
              <input name="location_url" value={form.location_url} onChange={changeHandler} required />
            </div>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>Address<span className='mandatory-field'>&#42;</span></label>
                <input name="address" value={form.address} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>City<span className='mandatory-field'>&#42;</span></label>
                <input name="city" value={form.city} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>State<span className='mandatory-field'>&#42;</span></label>
                <input name="state" value={form.state} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Country<span className='mandatory-field'>&#42;</span></label>
                <input name="country" value={form.country} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Zip code<span className='mandatory-field'>&#42;</span></label>
                <input type='number' name="zipcode" value={form.zipcode} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Area<span className='mandatory-field'>&#42;</span></label>
                <input name="area" value={form.area} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Contact Email<span className='mandatory-field'>&#42;</span></label>
                <input type='email' name="contact_email" value={form.contact_email} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Contact Number<span className='mandatory-field'>&#42;</span></label>
                <input type='number' name="contact_number" value={form.contact_number} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Bedrooms</label>
                <input type='number' name="bedrooms" value={form.bedrooms} onChange={changeHandler} />
              </div>
              <div className='modal-input-box'>
                <label>Bathrooms</label>
                <input type='number' name="bathrooms" value={form.bathrooms} onChange={changeHandler} />
              </div>
              <div className='modal-input-box'>
                <label>Units</label>
                <input type='number' name="units" value={form.units} onChange={changeHandler} />
              </div>
              <div className='modal-input-box'>
                <label>Property Type:<span className='mandatory-field'>&#42;</span></label>
                <select name="property_type" value={form.property_type} onChange={changeHandler}>
                  <option value={0}>None</option>
                  {(propertyTypes && propertyTypes.length > 0) &&
                    propertyTypes.map(item => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>

              </div>
              <div className='modal-input-box'>
                <label>Builders:</label>
                <select name="builder_id" value={form.builder_id} onChange={changeHandler} >
                  <option value={0}>None</option>
                  {(builders && builders.length > 0) &&
                    builders.map(item => (
                      <option value={item.id}>{item.display_name}</option>
                    ))}
                </select>
              </div>
              <div className='modal-input-box'>
                <label>Admins:</label>
                <select name="user_id" value={form.user_id} onChange={changeHandler}>
                  <option value={0}>None</option>
                  {(admins && admins.length > 0) &&
                    admins.map(item => (
                      <option value={item.id}>{item.first_name} {item.last_name}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className='modal-input-box'>
              <label>Services Available:</label>
              <Select value={form.services_available} isMulti closeMenuOnSelect={false} options={propertyServices} onChange={servicesAvailablehandler} />
            </div>
            <div className='modal-input-box'>
              <label>Description<span className='mandatory-field'>&#42;</span></label>
              <textarea name="description" value={form.description} onChange={changeHandler} rows={6} required>{form.description}</textarea>
            </div>
            <div className='images-box'>
              <ul>
                {(form.images && form.images.split(',').length > 0) && (
                  <>
                    {form.images.split(',').map((image, index) => (
                      <li>
                        <img src={`https://nriconnect.io/realEstate/Uploads/${image}`} alt='' />
                        <button onClick={() => removeOldImage(index)} type='button'><FaTimes size={16} /></button>
                      </li>
                    ))}
                  </>
                )}
                {(form.new_images_src && form.new_images_src.length > 0) && (
                  <>
                    {form.new_images_src.map((image, index) => (
                      <li>
                        <img src={image} alt='' />
                        <button onClick={() => removeImage(index)} type='button'><FaTimes size={16} /></button>
                      </li>
                    ))}
                  </>
                )}
              </ul>
              <div className='images-box-actions'>
                <label htmlFor='images'>Upload images</label>
                <input multiple onChange={uploadNewImages} accept="images/*" name="images" id='images' type='file' />
              </div>
            </div>
            {error && (<p className='error-message'>{error}</p>)}
            <div className='action-box'>
              <button type='button' onClick={submitHandler} className='create'>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutServiceProviderRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(EditProperty) 