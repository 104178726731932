import React from "react";
import "./loadingbar.css";

const LoadingBar =() => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
}

export default LoadingBar
