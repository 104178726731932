import React from 'react'

const Offer = () => {
    return (
        <div className='wrapper wrapper-border-top landing-offer'>
            <div className='wrapper-header'>What We Offer</div>
            <div className='content-wrapper'>
                <h2>Our <span>qualified team</span> is ready to help you</h2>
                <div className='offer-cards'>
                    <ul>
                        <li>
                            <div />
                            <h3>Complete Documentation</h3>
                            <p>Our team will take care of paperwork required for the chosen service, so that you don't have to worry about.</p>
                        </li>
                        <li>
                            <div />
                            <h3>Exceptional Advisors</h3>
                            <p>Our team of advisors will give you on-point advice without ever being pushy about buying any of the service. </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Offer