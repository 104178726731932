import React from "react";
import { Link, useNavigate } from "react-router-dom";

function OTPverfication() {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/services/realestate/sellproperty/propertyDetail");
  };

  return (
    <div>
      <label htmlFor="otp">Please enter OTP sent to +91 - 98xxxxxx89</label>
      <div className="otp-details">
        <input type="number" placeholder="Enter the OTP" name="otp" id="otp" />
        <div className="otp-btn">
          <button type="button" onClick={handleSubmit}>
            <p>Verify</p>
          </button>
          <Link to="">
            <p>Resend OTP</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OTPverfication;
