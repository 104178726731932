import React, { useCallback } from "react";
import "./ConfirmBid.css";
import useLocalStorage from "../../../utils/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { addBid } from "../../../Api/serviceProvider";

function ConfirmBid({ hideConfirmBox, setBidConfirm, bid }) {
  const navigate = useNavigate();
  const confirmBid = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      price: bid.price,
      comment: bid.comment.trim(),
      property_id: bid.property_id,
    };
    if (cleanData.price && cleanData.comment && cleanData.property_id) {
      const apiResponse = await addBid(cleanData);
      if (apiResponse) {
          if (apiResponse === 401) {
            useLocalStorage.removeServiceProvider();
            navigate("/serviceProvider/login");
          }
          setBidConfirm(true);
          hideConfirmBox();
        }
      // } else {
      //   useLocalStorage.removeServiceProvider();
      //   navigate("/serviceProvider/login");
      // }
    }
  }, []);

  return (
    <div className="modal-container">
      <div className="modal-popup">
        <div className="modal-content">
          <div className="top">
            <div className="image">
              <div className="like"></div>
            </div>
            <div className="heading">
              <h3>Great !</h3>
              <p>You are just one step away to Place a bid.</p>
            </div>
          </div>
          <p>Are you sure to place bid of INR {bid.price}?</p>
          <div className="action-box">
            <button onClick={confirmBid}>Yes</button>
            <button onClick={hideConfirmBox}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmBid;
