import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Accordion from '../../../Components/Accordion/Accordion';
import { showFaqs, showFinancialPlanFaqs } from '../../../Api/faqs';

const Faqs = () => {
    const path = useLocation().pathname;
    const { id } = useParams();
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        fetchData();
    }, [path]);

    const fetchData = async () => {
        if (path.includes('/financial/planDetails')) {
            const apiResponse = await showFinancialPlanFaqs(id, { limit: 3 });
            if (apiResponse) {
                setFaqs([...apiResponse])
            } else {
                setFaqs([])
            }
        } else {
            const apiResponse = await showFaqs(2, { limit: 3 });
            if (apiResponse) {
                setFaqs([...apiResponse])
            } else {
                setFaqs([])
            }
        }
    }

    return (
        <div className='wrapper wrapper-border-top landing-faqs'>
            <div className='wrapper-header'>FAQs</div>
            <div className='content-wrapper'>
                {(faqs && faqs.length > 0) && (
                    <>
                        <h2>Frequently asked <span>questions</span></h2>
                        <div className='faqs-box'>
                            {faqs.map(faq => <Accordion label={faq.question} content={faq.answer} />)}
                        </div>
                        <Link to={path.includes('/financial/planDetails') ? `/faqs/plan/${id}` : "/faqs/financial"}><button className='more-faqs-btn' type='button'>More FAQs</button></Link>
                    </>
                )}
                <div className='faqs-enquire'>
                    <div className='content'>
                        <p>Do you have any questions?</p>
                        <p>Put your email address and we will get back to you</p>
                    </div>
                    <div className='input'>
                        <input placeholder='Enter your email' />
                        <button type='button'>Enquire Now</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faqs