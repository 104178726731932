import React, { useState, useCallback, useEffect } from "react";
import useLocalStorage from "../../../utils/useLocalStorage";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  activateDeactivateProperty,
  fetchActivePropertyTypes,
  fetchProperties,
  fetchUserProperties,
} from "../../../Api/serviceProvider";
import { GoSearch } from "react-icons/go";

const ServiceProviderSellerProperties = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    request_type: 0,
    offset: 0,
    property_type: 0,
    search: "",
  });
  const [userProperties, setUserProperties] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentpage] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchData();
  }, [state]);

  const fetchData = useCallback(async () => {
    const apiResponse = await fetchUserProperties(state);
    const response = await fetchActivePropertyTypes();
    if (apiResponse || response) {
      if (apiResponse === 401 || response === 401) {
        useLocalStorage.removeServiceProvider();
        navigate("/serviceProvider/login");
      } else {
        console.log(apiResponse,"console")
        setUserProperties([...apiResponse.properties]);
        setPropertyTypes([...response]);
        if (apiResponse.total_user_properties > 0) {
          if (apiResponse.total_user_properties % 10 > 0) {
            setTotalPages(parseInt(apiResponse.total_user_properties / 10) + 1);
          } else {
            setTotalPages(parseInt(apiResponse.total_user_properties / 10));
          }
        }
      }
    } else {
      setUserProperties([]);
      setTotalPages(0);
    }
  }, [state]);

  const requestTypeHandler = useCallback(
    (e) => {
      setState({
        ...state,
        request_type: e.target.value,
      });
    },
    [state]
  );

  const propertyTypeHandler = useCallback(
    (e) => {
      setState({
        ...state,
        property_type: e.target.value,
      });
    },
    [state]
  );

  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search]
  );

  const submitSearchHandler = useCallback(() => {
    setState({
      ...state,
      offset: 0,
      search: search,
    });
  }, [search, state]);

  const currentPageHandler = useCallback((pageNumber) => {
    setCurrentpage(pageNumber);
    setState({
      ...state,
      offset: (pageNumber - 1) * 10,
    });
  }, []);

  const viewPropertyHandler = useCallback((id) => {
    navigate(`/serviceProvider/userproperty/${id}`);
  }, []);

  return (
    <>
      <div className="admin-container">
        <div className="breadcrumbs">
          <p>
            ServiceProvider <b>/ User Properties</b>
          </p>
          <span>User Properties</span>
        </div>
        <div className="content-wrapper">
          <div className="table-view">
            <div className="header">
              <h1>Properties List</h1>
            </div>
            <div className="table-filters">
              <div className="search">
                <input
                  placeholder="Search..."
                  type="text"
                  value={search}
                  onChange={searchHandler}
                />
                <button onClick={submitSearchHandler}>
                  <GoSearch size={16} />
                </button>
              </div>
              {/* <div className="dropdown">
                <label>Property Type:</label>
                <select
                  value={state.property_type}
                  onChange={propertyTypeHandler}
                >
                  <option value={0}>All</option>
                  {propertyTypes &&
                    propertyTypes.length > 0 &&
                    propertyTypes.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div> */}
              <div className="dropdown">
                <label>Status:</label>
                <select
                  value={state.request_type}
                  onChange={requestTypeHandler}
                >
                  <option value={0}>All</option>
                  <option value={1}>Active</option>
                  <option value={2}>Not active</option>
                </select>
              </div>
            </div>

            {userProperties && userProperties.length > 0 && (
              <>
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        {/* <th>Sr. No.</th> */}
                        {/* <th>Image</th> */}
                        <th>Name</th>
                        <th>Price</th>
                        {/* <th>Area</th>
                        <th>Property Type</th>
                        <th>Address</th>
                        <th>Contact Name</th>
                        <th>Contact Email</th>
                        <th>Contact Number</th>*/}
                        <th>Date Added</th>
                        <th>Date Modified</th> 
                        <th>Status</th>
                        <th>Bid Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userProperties.map((item, index) => (
                        <tr key={index}>
                          {/* <td>{index + 1}</td>
                          <td>
                            <img
                              src={`https://nriconnect.io/realEstate/Uploads/${item.thumbnail}`}
                              alt=""
                            />
                          </td> */}
                          <td
                            className="head"
                            onClick={() => viewPropertyHandler(item.id)}
                          >
                            {item.name}
                          </td>
                          <td>₹{item.price}</td>
                          {/* <td>{item.area}</td>
                          <td>{item.property_type_name}</td>
                          <td>{item.address}, {item.city}, {item.state}, {item.country}</td>
                          <td>{item.contact_name}</td>
                          <td>{item.contact_email}</td>
                          <td>{item.contact_number}</td> */}
                          <td>{item.created_at}</td>
                          <td>{item.updated_at}</td>
                          <td>
                            <span
                              className={
                                item.is_active === 1 ? "active" : "deactive"
                              }
                            >
                              {item.is_active === 1 ? "Active" : "Not Active"}
                            </span>
                          </td>
                          <td>
                          <span
                              className={
                                item.bid_status === 'Accepted' ? "active" : "deactive"
                              }
                            >
                              {item.bid_status ? item.bid_status : 'No Bid Added'} 
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {totalPages && totalPages > 1 ? (
                  <div className="admin-pagination">
                    <button
                      type="button"
                      className={currentPage === 1 ? "disabled" : ""}
                      onClick={
                        currentPage === 1
                          ? null
                          : () => currentPageHandler(currentPage - 1)
                      }
                    >
                      <AiFillCaretLeft size={16} />
                    </button>
                    <span>{`Page ${currentPage} / ${totalPages}`}</span>
                    <button
                      type="button"
                      className={currentPage === totalPages ? "disabled" : ""}
                      onClick={
                        currentPage === totalPages
                          ? null
                          : () => currentPageHandler(currentPage + 1)
                      }
                    >
                      <AiFillCaretRight size={16} />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceProviderSellerProperties;
