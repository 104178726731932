import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import AddAdmin from '../../../Components/Popup/Admins/AddAdmin';
import EditAdmin from '../../../Components/Popup/Admins/EditAdmin';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { activateDeactivateAdmin, fetchAdmins } from '../../../Api/admin';
import { loadAdminRequest } from '../../../Actions/admin';
import { GoSearch } from 'react-icons/go';
import useLocalStorage from '../../../utils/useLocalStorage';
import Avatar from '../../../Components/Avatar/Avatar';

const Admins = () => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        request_type: 0,
        offset: 0,
        service_id: 0,
        search: ''
    });
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState('');
    const [admins, setAdmins] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1);
    const [search, setSearch] = useState("");

    const addModalHandler = () => {
        setAddModal(!addModal)
    }

    const editModalHandler = (data) => {
        setEditData(data)
        setEditModal(!editModal);
    }

    useEffect(() => {
        fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchAdmins(state);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setAdmins([...apiResponse.admins]);
                if (apiResponse.total_admins > 0) {
                    if (apiResponse.total_admins % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_admins / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_admins / 10))
                    }
                }
            }
        } else {
            setAdmins([]);
            setTotalPages(0)
        }
    }, [state])

    const serviceTypeHandler = useCallback((e) => {
        setState({
            ...state,
            service_id: e.target.value,
            offset: 0
        })
    }, [state])

    const requestTypeHandler = useCallback((e) => {
        setState({
            ...state,
            request_type: e.target.value,
            offset: 0
        })
    }, [state])

    const searchHandler = useCallback((e) => {
        setSearch(e.target.value)
    }, [search])

    const submitSearchHandler = useCallback(() => {
        setState({
            ...state,
            offset: 0,
            search: search
        })
    }, [search, state])

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, [])

    const activateDeactivateHandler = async (data) => {
        let status = 0;
        if (data.is_active === 0) {
            status = 1;
        }
        const apiResponse = await activateDeactivateAdmin(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                fetchData();
            }
        }
    }

    return (
        <>
            {addModal && <AddAdmin fetchData={fetchData} hideAddModal={addModalHandler} />}
            {editModal && <EditAdmin fetchData={fetchData} data={editData} hideEditModal={editModalHandler} />}
            <div className='admin-container'>
                <div className="breadcrumbs">
                    <p>Admin / Users <b>/ Admins</b></p>
                    <span>Admins</span>
                </div>
                <div className='content-wrapper'>
                    <div className='table-view'>
                        <div className='header'>
                            <h1>Admins</h1>
                            <button className='add-btn' onClick={addModalHandler}>Add</button>
                        </div>
                        <div className='table-filters'>
                            <div className='search'>
                                <input placeholder='Search...' type='text' value={search} onChange={searchHandler} />
                                <button onClick={submitSearchHandler}><GoSearch size={16} /></button>
                            </div>
                            <div className='dropdown'>
                                <label>Area Service:</label>
                                <select value={state.service_id} onChange={serviceTypeHandler}>
                                    <option value={0}>All</option>
                                    <option value={1}>Real Estate</option>
                                    <option value={2}>Financial</option>
                                </select>
                            </div>
                            <div className='dropdown'>
                                <label>Status:</label>
                                <select value={state.request_type} onChange={requestTypeHandler}>
                                    <option value={0}>All</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Not active</option>
                                </select>
                            </div>
                        </div>

                        {(admins && admins.length > 0) && (
                            <>
                                <div className='table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Area of service</th>
                                                <th>Date Added</th>
                                                <th>Date Modified</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {admins.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{<Avatar name={item.first_name} image={item.image} />}</td>
                                                    <td className='text-capitalize'>{item.first_name} {item.last_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{(item.service_ids === "1,2" || item.service_ids === '2,1') ? "Real Estate, Financial" : (item.service_ids === '1' ? 'Real Estate' : 'Financial')}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.updated_at}</td>
                                                    <td>
                                                        <span className={item.is_active === 1 ? 'active' : 'deactive'} >
                                                            {item.is_active === 1 ? 'Active' : 'Not Active'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className='table-actions'>
                                                            <button className={item.is_active === 0 ? 'activate-btn' : 'deactivate-btn'} onClick={() => activateDeactivateHandler(item)}>
                                                                {item.is_active === 0 ? "Activate" : "Deactivate"}
                                                            </button>
                                                            {item.is_active === 1 ? <button className='edit-btn' onClick={() => editModalHandler(item)} >
                                                                Edit
                                                            </button>
                                                                : <></>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {(totalPages && totalPages > 1) ?
                                    <div className='admin-pagination'>
                                        <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                                            <AiFillCaretLeft size={16} />
                                        </button>
                                        <span>{`Page ${currentPage} / ${totalPages}`}</span>
                                        <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                                            <AiFillCaretRight size={16} />
                                        </button>
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    loadAdmin: loadAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(Admins)
