import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaUserTie } from 'react-icons/fa';
import { SiSalesforce } from 'react-icons/si';
import { IoDocumentTextSharp } from 'react-icons/io5';
import { RiBuildingFill } from 'react-icons/ri';
import { MdPayment, MdBurstMode } from 'react-icons/md'
import { CgTimer } from 'react-icons/cg';
import { dashboard } from '../../../Api/admin';
import './dashboard.css';

const Dashboard = ({ sidebar }) => {
  const [data, setData] = useState({
    total_visits: 0,
    total_payment: 0,
    total_appointments: 0,
    total_realestate_enquiries: 0,
    total_general_enquiries: 0,
    total_properties: 0,
    total_financial_plans: 0
  })
  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = useCallback(async () => {
    const apiResponse = await dashboard();
    if (apiResponse) {
      setData(apiResponse)
    } else {
      setData({
        total_visits: 0,
        total_payment: 0,
        total_appointments: 0,
        total_realestate_enquiries: 0,
        total_general_enquiries: 0,
        total_properties: 0,
        total_financial_plans: 0
      })
    }
  }, [data])

  return (
    <div className={sidebar ? "admin-container active" : "admin-container"}>
      <div className="breadcrumbs">
        <p>Admin <b>/ Dashboard</b></p>
        <span>Dashboard</span>
      </div>
      <div className="content-wrapper dashboard">
        <ul className="statistics">
          <li>
            <div className="header">
              <FaUserTie size={20} />
            </div>
            <div className='content'>
              <span>Total Visits</span>
              <span>{data && (data.total_visits || 0)}</span>
            </div>
            <div className='footer'>
              <p><CgTimer /> just updated</p>
            </div>
          </li>
          <li>
            <Link to ='/admin/transactions'>
              <div className="header">
                <MdPayment size={20} />
              </div>
              <div className='content'>
                <span>Payments</span>
                <span>{data && (data.total_payment || 0)}</span>
              </div>
              <div className='footer'>
                <p><CgTimer /> just updated</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/admin/appointments'>
              <div className="header">
                <IoDocumentTextSharp size={20} />
              </div>
              <div className='content'>
                <span>Appointments</span>
                <span>{data && (data.total_appointments || 0)}</span>
              </div>
              <div className='footer'>
                <p><CgTimer /> just updated</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/admin/realestateEnquiries'>
              <div className="header">
                <SiSalesforce size={20} />
              </div>
              <div className='content'>
                <span>Total RealEstate Queries</span>
                <span>{data && (data.total_realestate_enquiries || 0)}</span>
              </div>
              <div className='footer'>
                <p><CgTimer /> just updated</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/admin/generalEnquiries'>
              <div className="header">
                <SiSalesforce size={20} />
              </div>
              <div className='content'>
                <span>Total General Queries</span>
                <span>{data && (data.total_general_enquiries || 0)}</span>
              </div>
              <div className='footer'>
                <p><CgTimer /> just updated</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/admin/properties'>
              <div className="header">
                <RiBuildingFill size={20} />
              </div>
              <div className='content'>
                <span>Total Properties</span>
                <span>{data && (data.total_properties || 0)}</span>
              </div>
              <div className='footer'>
                <p><CgTimer /> just updated</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/admin/financial/plans'>
              <div className="header">
                <MdBurstMode size={20} />
              </div>
              <div className='content'>
                <span>Total Finance Plans</span>
                <span>{data && (data.total_financial_plans || 0)}</span>
              </div>
              <div className='footer'>
                <p><CgTimer /> just updated</p>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  sidebar: state.sidebar
})

export default connect(mapStateToProps)(Dashboard);