import api from "../utils/api";
import { toast } from "react-toastify";


export const addFaq = async (data) => {
    const apiResponse = await api.applicationApi('faqs/addFaq', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editFaq = async (id, data) => {
    const apiResponse = await api.applicationApi(`faqs/editFaq/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}

export const fetchFaqs = async (data) => {
    const apiResponse = await api.applicationApi('faqs/fetchFaqs', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateFaq = async (id, data) => {
    const apiResponse = await api.applicationApi(`faqs/activateDeactivateFaq/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const showFaqs = async (id, data) => {
    const apiResponse = await api.applicationApi(`users/showFaqs/${id}`, 'POST', data, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const showFinancialPlanFaqs = async (id, data) => {
    const apiResponse = await api.applicationApi(`users/showFinancialPlanFaqs/${id}`, 'POST', data, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
