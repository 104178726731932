import api from "../utils/api";
import { toast } from "react-toastify";


//FINANCIAL PLANS CATEGORY APIs

export const addFinancialPlansCategory = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/addFinancialPlansCategory', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editFinancialPlansCategory = async (id, data) => {
    const apiResponse = await api.applicationApi(`financialPlans/editFinancialPlansCategory/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}

export const fetchFinancialPlansCategories = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/fetchFinancialPlansCategories', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateFinancialPlansCategory = async (id, data) => {
    const apiResponse = await api.applicationApi(`financialPlans/activateDeactivateFinancialPlansCategory/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

//FINANCIAL PLANS APIs

export const addFinancialPlan = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/addFinancialPlan', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editFinancialPlan = async (id, data) => {
    const apiResponse = await api.applicationApi(`financialPlans/editFinancialPlan/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateFinancialPlan = async (id, data) => {
    const apiResponse = await api.applicationApi(`financialPlans/activateDeactivateFinancialPlan/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const fetchFinancialPlans = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/fetchFinancialPlans', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const fetchFinancialPlansAddEditData = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/fetchFinancialPlansAddEditData', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const showFinancialPlans = async (data) => {
    const apiResponse = await api.applicationApi('users/showFinancialPlans', 'POST', data, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const showFinancialPlan = async (id) => {
    const apiResponse = await api.applicationApi(`users/showFinancialPlan/${id}`, 'POST', null, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

//FINANCIAL PLANS ADDON APIs

export const fetchFinancialPlansAddons = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/fetchFinancialPlansAddons', 'POST', data, 1);
    console.log(apiResponse, "response")
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const addFinancialPlanAddon = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/addFinancialPlanAddon', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editFinancialPlanAddon = async (id, data) => {
    const apiResponse = await api.applicationApi(`financialPlans/editFinancialPlanAddon/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateFinancialPlanAddon = async (id, data) => {
    const apiResponse = await api.applicationApi(`financialPlans/activateDeactivateFinancialPlanAddon/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const fetchFinancialPlansAddonsAddEditData = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/fetchFinancialPlansAddonsAddEditData', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

// FINANCIAL PLANS PRICINGS APIs

export const fetchFinancialPlansPricings = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/fetchFinancialPlansPricings', 'POST', data, 1);
    console.log(apiResponse, "response")
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const addFinancialPlanPricing = async (data) => {
    const apiResponse = await api.applicationApi('financialPlans/addFinancialPlanPricing', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editFinancialPlanPricing = async (id, data) => {
    const apiResponse = await api.applicationApi(`financialPlans/editFinancialPlanPricing/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateFinancialPlanPricing = async (id, data) => {
    const apiResponse = await api.applicationApi(`financialPlans/activateDeactivateFinancialPlanPricing/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}