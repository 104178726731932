import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { serviceProviderVerification } from '../../../Api/serviceProvider';

const ServiceProviderVerification = () => {

    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const searchParams = useLocation().search.split('?')[1].split('&').map(param => param.split('='));
    const [state, setState] = useState(null);
    const [error, setError] = useState(false)
    
    const [form, setForm] = useState({
        password: "",
        confirmPassword: ""
    })

    useEffect(() => {
        if (searchParams.length > 0) {
            const data = {};
            searchParams.forEach(param => {
                data[param[0]] = param[1].trim();
            })
            setState(data)
        }
    }, [])

    const onChangeHandler = useCallback((e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }, [form])

    const showPasswordHandler = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])

    const showConfirmPasswordHandler = useCallback(() => {
        setShowConfirmPassword(!showConfirmPassword)
    }, [showConfirmPassword])

    const submitHandler = useCallback(async () => {
        const cleanData = {
            password: form.password.trim(),
            confirmPassword: form.confirmPassword.trim()
        }
        if(cleanData.password && cleanData.confirmPassword){
        if (cleanData.password.length < 8) {
            setError('password must contain atleast 8 charachters')
        } else if (cleanData.confirmPassword !== cleanData.password) {
            setError('passwords does not match')
        } else {
            setError(false);
            const apiResponse = await serviceProviderVerification({
                email: state.email,
                password: cleanData.password,
                key: state.key
            });

            if (apiResponse) {
                navigate('/serviceProvider/login')
            } else {
                setError('some error occured')
            }
        }
    }else{
        setError('Please fill all mandatory fields')
    }
    }, [form])


    return (
        <>
            <div className='admin-auth-container'>
                        <div className='auth-box'>
                            <p>Password set up</p>
                            <p>Please set up your password</p>
                            <form>
                                <div className={form.password ? 'input-box active' : 'input-box'}>
                                    <label>Password</label>
                                    <input onChange={onChangeHandler} value={form.password} name='password' type={showPassword ? 'text' : 'password'} placeholder='Password*' />
                                    <button type='button' onClick={showPasswordHandler}>{showPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
                                </div>
                                <div className={form.confirmPassword ? 'input-box active' : 'input-box'}>
                                    <label>Confirm Password</label>
                                    <input onChange={onChangeHandler} value={form.confirmPassword} name='confirmPassword' type={showConfirmPassword ? 'text' : 'password'} placeholder='Confirm Password*' />
                                    <button type='button' onClick={showConfirmPasswordHandler}>{showConfirmPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
                                </div>
                                {error && (<p className='error-message'>{error}</p>)}
                                <button type='button' onClick={submitHandler}>Set Password</button>
                            </form>
                        </div>
            </div>
        </>
    )
}

export default ServiceProviderVerification;