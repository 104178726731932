import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { TbArrowsDownUp } from "react-icons/tb";
import { fetchTransactions } from "../../../Api/user";
import useLocalStorage from "../../../utils/useLocalStorage";
import { useNavigate } from "react-router-dom";

const PaymentDetails = ({ user }) => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState();
  const [state, setState] = useState({
    limit: 10,
  });
  const [showAll, setShowAll] = useState(true);

  useEffect(() => {
    fetchData();
  }, [state]);

  const fetchData = useCallback(async () => {
    const apiResponse = await fetchTransactions({
      user_id: user.data.id,
      limit: state.limit,
    });
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeUser();
        navigate("/login");
      } else {
        setTransactions([...apiResponse.transactions]);
        setTotal(apiResponse.total_transactions);
      }
    } else {
      setTransactions([]);
      setTotal([]);
    }
  }, [state]);

  const showAllHandler = useCallback(
    (e) => {
      e.preventDefault();
      setState({
        ...state,
        limit: total,
      });
      setShowAll(false);
    },
    [state]
  );

  const showLessHandler = useCallback(
    (e) => {
      e.preventDefault();
      setState({
        ...state,
        limit: 10,
      });
      setShowAll(true);
    },
    [state]
  );

  //   console.log(transactions,"transactions")

  return (
    <>
      <div className="table-container">
        <div className="view">
          <h1>Payment Details</h1>
          <div className="tables">
            <table>
              <thead>
                <tr>
                  <th>Transaction Id</th>
                  <th>Case ID</th>
                  <th>Plan Name</th>
                  <th>Amount</th>
                  <th>
                    Date <TbArrowsDownUp />
                  </th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((item) => (
                  <tr>
                    <td className="text-capitalize">{item.transaction_id}</td>
                    <td className="text-capitalize">{item.case_id}</td>
                    <td className="text-capitalize">{item.plan_name}</td>
                    <td>${item.total_amount}</td>
                    <td>{item.created_at}</td>
                    <td>
                      <span
                        className={
                          item.payment_status === "paid" ? "active" : "deactive"
                        }
                      >
                        {item.payment_status === "paid"
                          ? "Complete"
                          : "Pending"}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {(total && total) > 10 && (
            <div className="table-action">
              {showAll ? (
                <a href="" onClick={showAllHandler}>
                  Show All
                </a>
              ) : (
                <a href="" onClick={showLessHandler}>
                  Show Less
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(PaymentDetails);
