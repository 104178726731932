import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { GoSearch } from 'react-icons/go';
import { fetchAppointments, completeAppointment } from '../../../Api/appointment';
import { loadAdminRequest } from '../../../Actions/admin';
import useLocalStorage from '../../../utils/useLocalStorage';

const Appointments = ({ logout }) => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        request_type: 0,
        offset: 0,
        search: ''
    });
    const [appointments, setApoointments] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1);
    const [search, setSearch] = useState("");

    useEffect(() => {
        fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchAppointments(state, 1);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                logout();
                navigate('/admin/login')
            } else {
                setApoointments([...apiResponse.appointments]);
                if (apiResponse.total_appointments > 0) {
                    if (apiResponse.total_appointments % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_appointments / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_appointments / 10))
                    }
                }
            }
        } else {
            setApoointments([]);
            setTotalPages(0)
        }
    }, [state])

    const requestTypeHandler = useCallback((e) => {
        setState({
            ...state,
            request_type: e.target.value,
            offset: 0
        })
    }, [state])

    const searchHandler = useCallback((e) => {
        setSearch(e.target.value)
    }, [search])

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, []);

    const submitSearchHandler = useCallback(() => {
        setState({
            ...state,
            offset: 0,
            search: search
        })
    }, [search, state])

    const completeAppointmentHandler = async (id) => {
        const apiResponse = await completeAppointment(id, 1);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                logout();
                navigate('/admin/login')
            } else {
                await fetchData();
            }
        }
    }

    return (
        <>
            <div className='admin-container'>
                <div className="breadcrumbs">
                    <p>Admin  / Property Services <b>/ Appointments</b></p>
                    <span>Appointments</span>
                </div>
                <div className='content-wrapper'>
                    <div className='table-view'>
                        <div className='header'>
                            <h1>Appointments</h1>
                        </div>
                        <div className='table-filters'>
                            <div className='search'>
                                <input placeholder='Search...' type='text' value={search} onChange={searchHandler} />
                                <button onClick={submitSearchHandler}><GoSearch size={16} /></button>
                            </div>
                            <div className='dropdown'>
                                <label>Status:</label>
                                <select value={state.request_type} onChange={requestTypeHandler}>
                                    <option value={0}>All</option>
                                    <option value={1}>Pending</option>
                                    <option value={2}>Completed</option>
                                </select>
                            </div>
                        </div>

                        {(appointments && appointments.length > 0) && (
                            <>
                                <div className='table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Appointment Number</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Appointment Date</th>
                                                <th>Appointment Slot</th>
                                                <th>Service</th>
                                                <th>Query</th>
                                                <th>Date Added</th>
                                                <th>Date Modified</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {appointments.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.appointment_id}</td>
                                                    <td className='text-capitalize'>{item.first_name} {item.last_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.time}</td>
                                                    <td>{item.service_id === '1' ? 'Real Estate' : 'Financial'}</td>
                                                    <td>{item.query}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.updated_at}</td>
                                                    <td>
                                                        <span className={item.status === 1 ? 'active' : 'deactive'} >
                                                            {item.status === 1 ? 'Completed' : 'Pending'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className='table-actions'>
                                                            {item.status === 0 && (<button onClick={() => completeAppointmentHandler(item.id)} className='activate-btn'>Mark as complete</button>)}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {(totalPages && totalPages > 1) ? 
                                <div className='admin-pagination'>
                                    <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                                        <AiFillCaretLeft size={16} />
                                    </button>
                                    <span>{`Page ${currentPage} / ${totalPages}`}</span>
                                    <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                                        <AiFillCaretRight size={16} />
                                    </button>
                                </div>
                                :
                                <></>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout: loadAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(Appointments)
