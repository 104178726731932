import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import image from "../../../Assets/Images/image1.jpeg";
import Liked from "../../../Assets/Images/liked.png";
import { showProperties } from "../../../Api/properties";
import { AiOutlineHeart } from "react-icons/ai";
import { MdOutlineFavorite } from "react-icons/md";

const Properties = () => {
  const path = useLocation().search;
  const searchParams = useLocation()
    .search.split("?")[1]
    .split("&")
    .map((param) => param.split("="));
  const [state, setState] = useState(null);
  const [properties, setProperties] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (searchParams.length > 0) {
      const data = {};
      searchParams.forEach((param) => {
        if (param[0] === "page") {
          const page = parseInt(param[1]) || 1;
          data["offset"] = (page - 1) * 10;
          setCurrentPage(page);
        } else {
          data[param[0]] = param[1].trim();
        }
      });
      data.search = data.search.split("%20").join(" ").trim();
      setState(data);
    }
  }, [path]);

  const fetchData = useCallback(async () => {
    const apiResponse = await showProperties(state);
    if (apiResponse) {
      if (apiResponse.properties && apiResponse.properties.length > 0) {
        setProperties(apiResponse.properties);
      } else {
        setProperties([]);
      }
      const totalProperties = parseInt(apiResponse.total_properties);
      if (totalProperties % 10 !== 0) {
        setTotalPages(parseInt(apiResponse.total_properties) / 10 + 1);
      } else {
        setTotalPages(apiResponse.total_properties / 10);
      }
    } else {
      setTotalPages(1);
      setProperties([]);
      setCurrentPage(1);
    }
  }, [properties, state]);

  useEffect(() => {
    fetchData();
  }, [state]);

  return (
    <div className="properties">
      {properties && properties.length > 0 && (
        <ul className="list">
          {properties.map((property) => (
            <li className="property">
              <div className="property-image">
                <img
                  src={`https://nriconnect.io/realEstate/Uploads/${property.thumbnail}`}
                ></img>
                {/* <div className='liked_unlike_icon_div'>
                                    <img src={Liked} alt="icon" />
                                    <MdOutlineFavorite size={20} color="black" />
                                    <AiOutlineHeart color="white" size={20}/>
                                </div> */}
              </div>
              <div className="property-details">
                <h3>{property.name}</h3>
                <ul>
                  <li>
                    <span>{`${property.city}, ${property.state}`}</span>
                  </li>
                  <li>
                    <a>Show on map</a>
                  </li>
                  <li>
                    <p>8.2 miles from center:</p>
                  </li>
                </ul>
                <h4>{property.property_type}</h4>
                <p>
                  Description:
                  <br />
                  {property.description}
                </p>
                <Link
                  to={`/services/realestate/propertyDetails/${property.id}`}
                >
                  <button>View property</button>
                </Link>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Properties;
