import React, { useState, useEffect, useCallback } from "react";
import { showFinancialPlans } from "../../../Api/financialPlans";
import Plans from "./Plans";
import { useLocation } from "react-router-dom";

const Categories = () => {
  const [financialPlansData, setFinancialPlansData] = useState([]);
  const path = useLocation().search;
  const searchParams = useLocation()
    .search.split("?")[1]
    .split("&")
    .map((param) => param.split("="));

  const [state, setState] = useState({
    search: "",
    sort: 0,
  });

  useEffect(() => {
    if (searchParams?.length > 0) {
      const data = {};
      searchParams?.forEach((param) => {
        data[param[0]] = param[1].trim();
      });
      setState(data);
    }
  }, [path]);

  useEffect(() => {
    fetchData();
  }, [state]);

  const fetchData = async () => {
    const apiResponse = await showFinancialPlans(state);
    if (apiResponse) {
      setFinancialPlansData([...apiResponse]);
    }
  };

  return (
    <>
      {financialPlansData && financialPlansData.length > 0 && (
        <>
          {financialPlansData.map((category) => (
            <div className="wrapper wrapper-border-top financial-plans">
              <div className="wrapper-header">{category.name}</div>
              <div className="content-wrapper">
                {category.financial_plans &&
                  category.financial_plans.length > 0 && (
                    <Plans data={category.financial_plans} />
                  )}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Categories;
