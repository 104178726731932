import React, { useCallback, useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import "./SignDocs.css";
import { FaBuromobelexperte } from "react-icons/fa";

function SignDocs({ form, setForm, onFilechangeHandler, onBackClick }) {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const handleSubmit = () => {
    if (!form.three_months_mend && !form.confidentiality_doc) {
      setError("Please upload the required documents.");
    } else {
      navigate("/services/realestate/sellproperty/confirm", {
        state: { formData: form },
      });
    }
  };
  const removeFile = useCallback((fieldName) => {
    setForm((prevForm) => ({
      ...prevForm,
      [fieldName]: "",
    }));
  }, []);
  return (
    <>
      {/* <div className="heading">
        <p>Sign and Upload Documents</p>
        <p>Step 4/4</p>
      </div> */}
      <div className="details">
        <div className="note">
          <p>
            <span>Note* </span> : Please download following documents and upload
            a signed copy
          </p>
        </div>
        <div className="title-deed">
          <div className="heading">
            <div className="info">
              <p>3 Months Mandate</p>
              <AiOutlineInfoCircle />
            </div>
            <div className="download">
              <p>Download Here</p>
              <div className="arrow"></div>
            </div>
          </div>
          <div className="upload-data">
            <div className="file-box-actions">
              {form.three_months_mend ? (
                <div className="file-detail1">
                  <p>{form.three_months_mend.name}</p>
                  <div
                    className="cancel-btn"
                    onClick={() => removeFile("three_months_mend")}
                  ></div>
                </div>
              ) : (
                <>
                  <label htmlFor="three_months_mend">
                    <div className="data"></div>
                  </label>
                  <input
                    onChange={onFilechangeHandler}
                    name="three_months_mend"
                    id="three_months_mend"
                    type="file"
                    hidden
                  />
                  <div className="file-detail">
                    <p>3 Months Mandate</p>
                    <p>Drop or upload file here</p>
                    <p>Allowed files pdf, docs, jpg, svg within 2mb</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="fard">
          <div className="heading">
            <div className="info">
              <p>Confidentiality Document</p>
              <AiOutlineInfoCircle />
            </div>
            <div className="download">
              <p>Download Here</p>
              <div className="arrow"></div>
            </div>
          </div>
          <div className="upload-data">
            <div className="file-box-actions">
              {form.confidentiality_doc ? (
                <div className="file-detail1">
                  <p>{form.confidentiality_doc.name}</p>
                  <div
                    className="cancel-btn"
                    onClick={() => removeFile("confidentiality_doc")}
                  ></div>
                </div>
              ) : (
                <>
                  <label htmlFor="confidentiality_doc">
                    <div className="data"></div>
                  </label>
                  <input
                    onChange={onFilechangeHandler}
                    name="confidentiality_doc"
                    id="confidentiality_doc"
                    type="file"
                    hidden
                  />
                  <div className="file-detail">
                    <p>Confidentiality Document</p>
                    <p>Drop or upload file here</p>
                    <p>Allowed files pdf, docs, jpg, svg within 2mb</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <p className="error-message">{error && <p>{error}</p>}</p>
        <div className="footer-btn">
          <button>
            <p onClick={() => onBackClick(3)}>Back</p> <AiOutlineArrowLeft />
          </button>
          <button type="button" onClick={handleSubmit}>
            <p>Continue</p> <AiOutlineArrowRight />
          </button>
        </div>
      </div>
    </>
  );
}

export default SignDocs;
