import React from "react";
import { BsCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Works = () => {
  const navigate = useNavigate();
  const findPlan = () => {
    navigate("/services/financial?search=&sort=0");
  };

  const findProperty = () => {
    navigate("/services/realestate");
  };
  return (
    <div className="wrapper wrapper-border-top landing-works">
      <div className="wrapper-header">Our Solutions</div>
      <div className="content-wrapper">
        {/* <section>
          <div />
          <div />
          <div />
        </section> */}
        <section>
          <ul>
            {/* <li>
              <BsCheck />
              <div>
                <span>01</span>
                <progress value={33.33} max={100} />
                <h5>Book a Free Consulation.</h5>
                <p>
                  Book a <b>FREE</b> 15 min consultation with our expert on call
                  or mail us your query.The expert will understand your queries
                  and will guide you next steps.
                </p>
              </div>
            </li>
            <li>
              <BsCheck />
              <div>
                <span>02</span>
                <progress value={66.66} max={100} />
                <h5>Provide us your details.</h5>
                <p>
                  Fill in the <b>application form</b> provided by our expert. It
                  should not take you more than 5-10 min to complete it.
                </p>
              </div>
            </li>
            <li>
              <BsCheck />
              <div>
                <span>03</span>
                <progress value={100} max={100} />
                <h5>The Finish Line awaits</h5>
                <p>Experience what our experience can do for you..</p>
              </div>
            </li> */}
            <li>
              <div className="icon" />
              <h3>Real Estate</h3>
              <p>
                Explore hassle-free property transactions with our NRI-centric
                solutions. Invest and sell property confidently, while we manage
                all documentation, remittance, and legal procedures, ensuring a
                smooth and secure experience.
              </p>
              <button onClick={findProperty}>Find Property</button>
            </li>
            <li>
              <div className="icon" />
              <h3>Financial Services</h3>
              <p>
                Discover Comprehensive NRI Financial Services to simplify your
                financial journey. From Tax Management to FEMA Compliance, Land
                Record Searches to Tax Document Procurement - All with
                Transparent Pricing
              </p>
              <button onClick={findPlan}>Find Plans</button>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Works;
