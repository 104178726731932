import { toast } from 'react-toastify';
import api from '../utils/api';

export const addPropertyType = async (data) => {
    const apiResponse = await api.applicationApi('propertyTypes/addPropertyType', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editPropertyType = async (id, data) => {
    const apiResponse = await api.applicationApi(`propertyTypes/editPropertyType/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivatePropertyType = async (id, data) => {
    const apiResponse = await api.applicationApi(`propertyTypes/activateDeactivatePropertyType/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const fetchPropertyTypes = async (data) => {
    const apiResponse = await api.applicationApi('propertyTypes/fetchPropertyTypes', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            if (apiResponse.status === 401) {
                return 401;
            }
            if (apiResponse.status === 403) {
                toast.error(apiResponse.data.message);
            }
            return false;
        }
    } else {
        return false;
    }
}

export const fetchActivePropertyTypes = async (data) => {
    const apiResponse = await api.applicationApi('propertyTypes/fetchActivePropertyTypes', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            if (apiResponse.status === 401) {
                return 401;
            }
            if (apiResponse.status === 403) {
                toast.error(apiResponse.data.message);
            }
            return false;
        }
    } else {
        return false;
    }
}