import { LOAD_USER, LOGOUT_USER } from "../utils/actionTypes"


export const loadUserRequest = (data) => ({
    type: LOAD_USER,
    payload: data
});

export const logoutUserRequest = () => ({
    type: LOGOUT_USER
})