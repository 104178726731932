import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { editAdmin, fetchAdminDetails } from '../../../Api/admin';
import { loadAdminRequest, logoutAdminRequest } from '../../../Actions/admin';
import useLocalStorage from '../../../utils/useLocalStorage';
import AdminChangePassowrd from '../../../Components/Popup/Admins/AdminChangePassowrd';

const AdminProfile = ({ admin, loadAdmin, logout }) => {
  const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL : process.env.REACT_APP_DEVELOPMENT_API_BASE_URL;

  const navigate = useNavigate();
  const [passwordModal, setPasswordModal] = useState(false);
  const [form, setForm] = useState(null);
  const [error, setError] = useState(false);

  const passwordModalHandler = () => {
    setPasswordModal(!passwordModal)
  }

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }, [form])

  const uploadThumbnail = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      setForm({
        ...form,
        image: e.target.files[0],
        imageSrc: URL.createObjectURL(e.target.files[0])
      })
    }
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      first_name: form.first_name.toLowerCase().trim(),
      last_name: form.last_name.toLowerCase().trim(),
      phone: form.phone.trim(),
      image: form.image
    }
    if (cleanData.first_name && cleanData.last_name && cleanData.phone && cleanData.image) {
      if (cleanData.phone.length !== 10) {
        setError('Please enter a valid mobile number')
      } else {
        const formData = new FormData();

        formData.append('first_name', cleanData.first_name);
        formData.append('last_name', cleanData.last_name);
        formData.append('phone', cleanData.phone);
        formData.append('image', cleanData.image);

        let apiResponse = await editAdmin(cleanData);

        if (apiResponse) {
          if (apiResponse === 401) {
            useLocalStorage.removeAdmin();
            logout();
            navigate('/admin/login')
          } else {
            apiResponse = await fetchAdminDetails();
            if (apiResponse) {
              if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
                logout();
              } else {
                loadAdmin(apiResponse)
              }
            }
          }
        } else {
          setForm({ ...form })
        }
      }
    } else {
      setError('Please fill all mandatory fields');
      setForm({ cleanData })
    }
  }, [form, logout])

  useEffect(() => {
    if (admin.isAuthenticated && admin.data) {
      setForm({
        ...admin.data,
        imageSrc: admin.data.image ? `${BASE_URL}${admin.data.image}` : "",
      })
    }
  }, [admin])

  return (
    <>
      {passwordModal && <AdminChangePassowrd hidePasswordModal={passwordModalHandler} />}
      {(admin.isAuthenticated && form) && (

        <div className='admin-container'>
          <div className="breadcrumbs">
            <p>Admin <b>/ Profile</b></p>
            <span>Profile</span>
          </div>
          <div className='content-wrapper'>
            <div className='profile'>
              <div className="info" key={form.id}>
                <div>
                  {admin.data.image && (
                    <img src={`${BASE_URL}${admin.data.image}`} alt="" />
                  )}
                </div>
                <h5>
                  {admin.data.first_name + " " + admin.data.last_name}
                </h5>
                <p>
                  {admin.data.email}
                </p>
                <p className="phone">+91 {admin.data.phone}</p>
                <button className='create' onClick={passwordModalHandler}>
                  Change Password
                </button>
              </div>
              <div className='profile-edit'>
                <form onSubmit={submitHandler}>
                  <div className='image-box'>
                    <div className='image-wrapper'>
                      {form.imageSrc && (<img src={form.imageSrc} alt='' />)}
                    </div>
                    <div className='image-box-actions'>
                      <label htmlFor='image'>Upload</label>
                      <input onChange={uploadThumbnail} name="image" id='image' type='file' />
                    </div>
                  </div>
                  <div className='modal-input-grid'>
                    <div className='modal-input-box'>
                      <label>First Name</label>
                      <input name="first_name" value={form.first_name} onChange={changeHandler} required />
                    </div>
                    <div className='modal-input-box'>
                      <label>Last Name</label>
                      <input name="last_name" value={form.last_name} onChange={changeHandler} required />
                    </div>
                    <div className='modal-input-box'>
                      <label>Phone</label>
                      <input type="number" name="phone" value={form.phone} onChange={changeHandler} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
                    </div>
                  </div>
                  {error && (<p className='error-message'>{error}</p>)}
                  <div className='action-box'>
                    <button className='create'>
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  admin: state.admin
})

const mapDispatchToProps = dispatch => bindActionCreators({
  loadAdmin: loadAdminRequest,
  logout: logoutAdminRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile)
