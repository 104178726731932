import React, { useCallback, useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../../../utils/useLocalStorage';
import { addFilter, fetchFiltersAddEditData } from '../../../Api/filter';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


const AddFilter = ({ hideAddModal, fetchData, logout }) => {
  const navigate = useNavigate();
  const [filterCategory, setFilterCategory] = useState([]);

  const [form, setForm] = useState({
    name: "",
    type: 0,
    value: 0,
    min_value: 0,
    max_value: 0,
    placement_order: 0,
    category_id: 0,
  })
  const [error, setError] = useState(false);

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      name: form.name.toLowerCase().trim(),
      type: form.type,
      value: form.value,
      min_value: form.min_value,
      max_value: form.max_value,
      placement_order: form.placement_order,
      category_id: form.category_id,
    }
    if (cleanData.name && cleanData.type && cleanData.category_id) {
      const response = await addFilter(cleanData);
      if (response) {
        if (response === 401) {
          useLocalStorage.removeAdmin();
          logout();
        } else {
          setForm({
            name: "",
            type: 0,
            value: 0,
            min_value: 0,
            max_value: 0,
            placement_order: 0,
            category_id: 0,
          })
        }
        hideAddModal();
        fetchData();
      } else {
        setForm({
          ...cleanData
        })
      }

    } else {
      setError('Please fill all mandatory fields')
    }
  }, [form, hideAddModal, logout])

  useEffect(() => {
    fetch()
  }, [])

  const fetch = useCallback(async () => {
    const apiResponse = await fetchFiltersAddEditData();
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeAdmin();
        navigate('/admin/login')
      } else {
        setFilterCategory([...apiResponse.filter_categories]);
      }
    } else {
      setFilterCategory([])
    }
  }, [form])



  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Add Filter</h3>
          <button className='close-btn' onClick={hideAddModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>Category:<span className='mandatory-field'>&#42;</span></label>
                <select name="category_id" value={form.category_id} onChange={changeHandler}>
                  <option value={0}></option>
                  {(filterCategory && filterCategory.length > 0) && (
                    <>
                      {filterCategory.map(filter => (
                        <option value={filter.id}>{filter.name}</option>
                      ))}
                    </>
                  )}
                </select>
              </div>
              {form.category_id != 0 && (
                <>
                  <div className='modal-input-box'>
                    <label>Name<span className='mandatory-field'>&#42;</span></label>
                    <input name="name" value={form.name} onChange={changeHandler} required />
                  </div>
                  <div className='modal-input-box'>
                    <label>Type<span className='mandatory-field'>&#42;</span></label>
                    <select name="type" value={form.type} onChange={changeHandler}>
                      <option value={0}>None</option>
                      <option value={1}>Range</option>
                      <option value={2}>Equals</option>
                      <option value={3}>Less Than</option>
                      <option value={4}>Greater Than</option>
                    </select>
                  </div>
                  {form.type == 1 && (
                    <>
                      <div className='modal-input-box'>
                        <label>Minimum Value</label>
                        <input type="number" name="min_value" value={form.min_value} onChange={changeHandler} />
                      </div>
                      <div className='modal-input-box'>
                        <label>Maximum Value</label>
                        <input type="number" name="max_value" value={form.max_value} onChange={changeHandler} />
                      </div>
                    </>
                  )}
                  {form.type == 2 && (
                    <div className='modal-input-box'>
                      <label>Value</label>
                      <input name="value" value={form.value} onChange={changeHandler} required />
                    </div>
                  )}
                  {form.type == 3 && (
                    <div className='modal-input-box'>
                      <label>Maximum Value</label>
                      <input type="number" name="max_value" value={form.max_value} onChange={changeHandler} />
                    </div>
                  )}
                  {form.type == 4 && (
                    <div className='modal-input-box'>
                      <label>Minimum Value</label>
                      <input type="number" name="min_value" value={form.min_value} onChange={changeHandler} />
                    </div>
                  )}
                  {(form.type == 1 || form.type == 2)  && (
                  <div className='modal-input-box'>
                    <label>Placement Order</label>
                    <input type="number" name="placement_order" value={form.placement_order} onChange={changeHandler} />
                  </div>
                  )}
                </>
              )}
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button className='create'>
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(AddFilter)