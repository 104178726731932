import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./user.css";

const ConfirmBox = ({ id, hideConfirmBox }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onSubmit = () => {
    if (id) {
      navigate(`/login?planId=${id}`);
    } else {
      navigate(`/login`);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal-popup">
        <div className="modal-content">
          {location.pathname.includes("/services/realestate") ? (
            <h3>You Need to Login first to post a property</h3>
          ) : (
            <h3>You Need to Login first to buy this plan</h3>
          )}
          <div className="action-box">
            <button onClick={onSubmit}>Ok</button>
            <button onClick={hideConfirmBox}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;
