import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateTransactions } from '../../../../Api/transactions';
import useLocalStorage from '../../../../utils/useLocalStorage';
import '../../../Auth/auth.css'

const Success = () => {
    const navigate = useNavigate();
    const [close , showClose] = useState(true);

    const search = useLocation().search
    const sessionId = search.includes('?') ? search.split('?')[1].split('=')[1] : '';
    console.log(sessionId,"id")
    
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = useCallback(async () => {
        const apiResponse = await updateTransactions({session_id: sessionId});
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeUser();
                navigate('/login')
            }
        } 
    }, [])

    useEffect(() => {
        if(search.includes('custompaymentlink')){
            showClose(false);
        }
    })

    const submitHandler = useCallback(() =>{
        navigate('/services/financial?search=&sort=0')
    })
    return (
        <div className='auth-container'>
            <div className='auth-background'></div>
            <div className='auth-wrapper'>   
                <section>
                    <div className='auth-box'>
                        <div className='auth-content'>
                           <h2>Plan payment is successfully done</h2>
                           <p></p>
                           <p>You can see plan details in profile section</p>
                           {close === true ? <button type='submit' onClick={submitHandler} >Close</button> : ''}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Success;