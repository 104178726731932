import React, { useCallback, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLocalStorage from '../../../utils/useLocalStorage';
import { editPropertyType } from '../../../Api/propertyTypes';
import { useNavigate } from 'react-router-dom';

const EditPropertyType = ({ data, fetch, hideEditModal, logout }) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({ ...data })
  const [error, setError] = useState(false)

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    setError(false)
    const cleanData = {
      name: form.name.toLowerCase().trim()
    }
    if (cleanData.name) {
      const apiResponse = await editPropertyType(data.id, cleanData);
      if (apiResponse) {
        if (apiResponse === 401) {
          useLocalStorage.removeAdmin();
          logout();
          navigate('/admin/login')
        } else {
          setForm({
            id: "",
            name: ""
          })
        }
        hideEditModal();
        fetch();
      } else {
        setForm({
          ...cleanData,
          id: form.id
        })
      }
    } else {
      setError('please fill all the fields')
      setForm({
        ...cleanData
      })
    }
  }, [form, hideEditModal, logout])

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Edit Property Type</h3>
          <button className='close-btn' onClick={hideEditModal} >
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>Name<span className='mandatory-field'>&#42;</span></label>
                <input className='text-capitalize' name="name" value={form.name} onChange={changeHandler} required />
              </div>
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(EditPropertyType)