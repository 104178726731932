import React, { useCallback, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { logoutAdminRequest } from "../../../Actions/admin";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import useLocalStorage from "../../../utils/useLocalStorage";
import { addTestimonial } from "../../../Api/testimonial";
import { useNavigate } from "react-router-dom";

const AddTestimonials = ({ hideAddModal, fetchData, logout }) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: "",
    comment: "",
    image: "",
    imageSrc: "",
  });
  const [error, setError] = useState(false);

  const changeHandler = useCallback(
    (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form]
  );

  const uploadIcon = useCallback(
    (e) => {
      if (e.target.files && e.target.files[0]) {
        setForm({
          ...form,
          image: e.target.files[0],
          imageSrc: URL.createObjectURL(e.target.files[0]),
        });
      }
    },
    [form]
  );

  const submitHandler = useCallback(
    async (e) => {
      e.preventDefault();
      setError(false);
      const cleanData = {
        name: form.name.toLowerCase().trim(),
        comment: form.comment.trim(),
        image: form.image,
      };
      if (cleanData.name || cleanData.comment || cleanData.image) {
        let formData = new FormData();
        formData.append("name", cleanData.name);
        formData.append("comment", cleanData.comment);
        formData.append("image", cleanData.image);

        const apiResponse = await addTestimonial(formData);
        if (apiResponse) {
          if (apiResponse === 401) {
            useLocalStorage.removeAdmin();
            logout();
            navigate("/admin/login");
          } else {
            setForm({
              name: "",
              comment: "",
              image: "",
              imageSrc: "",
            });
          }
          hideAddModal();
          fetchData();
        } else {
          setForm({
            ...cleanData,
            imageSrc: form.imageSrc,
          });
        }
      } else {
        setError("please fill all the fields");
        setForm({
          ...cleanData,
          imageSrc: "",
        });
      }
    },
    [form, hideAddModal, logout]
  );

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="header">
          <h3>Add Testimonial</h3>
          <button className="close-btn" onClick={hideAddModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className="content">
          <form onSubmit={submitHandler}>
            <div className="modal-input-grid">
              <div className="image-box">
                <div className="image-wrapper">
                  {form.imageSrc && <img src={form.imageSrc} alt="" />}
                </div>
                <div className="image-box-actions">
                  <label htmlFor="image">
                    Upload<span className="mandatory-field">&#42;</span>
                  </label>
                  <input
                    onChange={uploadIcon}
                    name="image"
                    id="image"
                    type="file"
                  />
                </div>
              </div>
              <div className="modal-input-box">
                <label>
                  Name<span className="mandatory-field">&#42;</span>
                </label>
                <input
                  name="name"
                  value={form.name}
                  onChange={changeHandler}
                  required
                />
              </div>
            </div>
            <div className="modal-input-box">
              <label>
                Comment<span className="mandatory-field">&#42;</span>
              </label>
              <textarea
                name="comment"
                value={form.comment}
                onChange={changeHandler}
                rows={6}
                required
              >
                {form.comment}
              </textarea>
            </div>
            <div className="action-box">
              {error && <p className="error-message">{error}</p>}
              <button>Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: logoutAdminRequest,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddTestimonials);
