import React from "react";
import "./DocViewer.css";
const DocViewer = ({ url, onClose }) => {
  const isImage = /\.(png|jpe?g|gif)$/i.test(url); // Check if the URL points to an image

  return (
    <div className="document-viewer">
      <div className="viewer-header">
        <span>Document</span>
        <button onClick={onClose}>X</button>
      </div>
      <div className="viewer-content">
        {isImage ? (
          <img src={url} alt="Document" />
        ) : (
          <iframe
            src={url}
            title="Document"
            width="100%"
            height="100vh"
            frameBorder="0"
          />
        )}
      </div>
    </div>
  );
};

export default DocViewer;
