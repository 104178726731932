import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { loadAdminRequest } from '../../../Actions/admin';
import { GoSearch } from 'react-icons/go';
import useLocalStorage from '../../../utils/useLocalStorage';
import { fetchTransactions } from '../../../Api/admin';


const Transactions = () => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        payment_status: 0,
        offset: 0,
        search: ''
    });
    const [transactions, setTransactions] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1);
    const [search, setSearch] = useState("");

    useEffect(() => {
        fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchTransactions(state);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setTransactions([...apiResponse.transactions]);
                if (apiResponse.total_transactions > 0) {
                    if (apiResponse.total_transactions % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_transactions / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_transactions / 10))
                    }
                }
            }
        } else {
            setTransactions([]);
            setTotalPages(0)
        }
    }, [state])

    const paymentStatusHandler = useCallback((e) => {
        if(e.target.value == 0){
        setState({
            ...state,
            payment_status: parseInt(e.target.value)
        })
        }else{
            setState({
                ...state,
                payment_status: e.target.value
            }) 
        }
    }, [state])

    const searchHandler = useCallback((e) => {
        setSearch(e.target.value)
    }, [search])

    const submitSearchHandler = useCallback(() => {
        setState({
            ...state,
            offset: 0,
            search: search
        })
    }, [search, state])

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, [])

    return (
        <>
            <div className='admin-container'>
                <div className="breadcrumbs">
                    <p>Admin <b>/ Transactions</b></p>
                    <span>Transactions</span>
                </div>
                <div className='content-wrapper'>
                    <div className='table-view'>
                        <div className='header'>
                            <h1>Transactions</h1>
                        </div>
                        <div className='table-filters'>
                            <div className='search'>
                                <input placeholder='Search...' type='text' value={search} onChange={searchHandler} />
                                <button onClick={submitSearchHandler}><GoSearch size={16} /></button>
                            </div>
                            <div className='dropdown'>
                                <label>Status:</label>
                                <select value={state.payment_status} onChange={paymentStatusHandler}>
                                    <option value={0}>All</option>
                                    <option value={'paid'}>Completed</option>
                                    <option value={'unpaid'}>Pending</option>
                                </select>
                            </div>
                        </div>

                        {(transactions && transactions.length > 0) && (
                            <>
                                <div className='table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Transaction Id</th>
                                                <th>Total Amount</th>
                                                <th>User Email</th>
                                                <th>User Name</th>
                                                <th>User Phone</th>
                                                <th>Date Modified</th>
                                                <th>Status</th>
                                                <th>Payment Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions.map((item, index) => (
                                                 <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.transaction_id}</td>
                                                    <td>${item.total_amount}</td>
                                                    <td>{item.user_email}</td>
                                                    <td className='text-capitalize'>{item.user_name}</td>
                                                    <td>{item.user_phone}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.status}</td>
                                                    <td>
                                                        <span className={item.payment_status === 'paid' ? 'active' : 'deactive'} >
                                                           {item.payment_status === 'paid' ? 'Completed' : 'Pending'}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                               {(totalPages && totalPages > 1) ?  
                                <div className='admin-pagination'>
                                    <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                                        <AiFillCaretLeft size={16} />
                                    </button>
                                    <span>{`Page ${currentPage} / ${totalPages}`}</span>
                                    <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                                        <AiFillCaretRight size={16} />
                                    </button>
                                </div>
                                :
                                <></>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    loadAdmin: loadAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(Transactions)
