import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import "./SellPropertyDetail.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PriceDetail from "./PriceDetail";
import country from "../../../../Country";
import UploadDocs from "./UploadDocs";
import SignDocs from "./SignDocs";
import { fetchPropertyAddEditData } from "../../../../Api/user";
import useLocalStorage from "../../../../utils/useLocalStorage";
import { Country, State, City } from "country-state-city";
import Save from "../../../../Components/Popup/User/Save";
import { toast } from "react-toastify";

function SellPropertyDetail({ user, onBackClick }) {
  const navigate = useNavigate();
  const [nextStep, setNextStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [propertyTypes, setPropertyTypes] = useState([]);
  const location = useLocation();
  const formDatas = location.state ? location.state.formDatas : null;
  const [showModal, setShowModal] = useState(false);
  const [form, setForm] = useState(() => {
    if (formDatas) {
      return { ...formDatas };
    } else {
      return {
        address: "",
        name: "",
        city: "",
        country: "",
        state: "",
        zipcode: "",
        property_type: "",
        property_type_name: "",
        area: "",
        area_unit: "",
        contact_number: user.phone ? user.phone : "",
        contact_email: user.email ? user.email : "",
        contact_name: user.name
          ? user.name
          : user.first_name + " " + user.last_name,
        token_amount: "",
        title_deed: "",
        three_months_mend: "",
        confidentiality_doc: "",
        fard: "",
        price: "",
        currency: "INR",
      };
    }
  });
  // console.log(formDatas);
  // const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  // const [countryIsoCode, setCountryIsoCode] = useState([]);
  const fetch = useCallback(async () => {
    const apiResponse = await fetchPropertyAddEditData();
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeUser();
        navigate("/login");
      } else {
        setPropertyTypes([...apiResponse.property_types]);
      }
    } else {
      setPropertyTypes([]);
    }
  }, [propertyTypes]);
  // const fetchCountries = () => {
  //   const countriesData = Country.getAllCountries();
  //   setCountries(countriesData);
  // };
  useEffect(() => {
    fetch();
    // fetchCountries();
  }, []);
  const onChangeHandler = useCallback(
    (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form]
  );
  const onFilechangeHandler = useCallback((e) => {
    const name = e.target.name;
    if (e.target.files && e.target.files[0]) {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: e.target.files[0],
      }));
    }
  }, []);

  const onDetails = (val) => {
    setNextStep(val);
  };
  // const handleCountryChange = (event) => {
  //   const selectedCountryName = event.target.value;
  //   const selectedCountry = countries.find(
  //     (country) => country.name === selectedCountryName
  //   );
  //   if (selectedCountry) {
  //     setCountryIsoCode(selectedCountry.isoCode);
  //     setStates(State.getStatesOfCountry(selectedCountry.isoCode));
  //     setCities([]);
  //     setForm({
  //       ...form,
  //       country: selectedCountryName,
  //     });
  //   }
  // };
  // const handleStateChange = (event) => {
  //   const selectedStateName = event.target.value;
  //   const selectedState = states.find(
  //     (state) => state.name === selectedStateName
  //   );
  //   if (selectedState) {
  //     setCities(City.getCitiesOfState(form.isoCode, selectedState.isoCode));
  //     setForm({
  //       ...form,
  //       state: selectedStateName,
  //     });
  //   }
  // };

  const handleCityChange = (event) => {
    const selectedCityName = event.target.value;
    setForm({
      ...form,
      city: selectedCityName,
    });
  };
  const handleSubmit = () => {
    const mandatoryFields = [
      "address",
      "city",
      "country",
      "state",
      "zipcode",
      "property_type",
      "area",
      "area_unit",
    ];

    let isValid = true;

    mandatoryFields.forEach((fieldName) => {
      if (!form[fieldName].trim()) {
        isValid = false;
      }
    });

    if (!isValid) {
      // If any mandatory field is not filled, set a generic error message
      setErrors({ genericError: "Please enter all mandatory fields." });
    } else {
      // All mandatory fields are filled, proceed to the next step
      setNextStep(2);
    }
  };
  const handlePropertyTypeChange = (e) => {
    const selectedTypeId = e.target.value;
    const selectedType = propertyTypes.find(
      (type) => type.id === parseInt(selectedTypeId, 10)
    );
    const propertyTypeName = selectedType ? selectedType.name : "";

    setForm({
      ...form,
      property_type: selectedTypeId,
      property_type_name: propertyTypeName,
    });
  };
  const onCancel = () => {
    setShowModal(!showModal);
  };
  // useEffect(() => {
  //   if (formDatas && countries.length > 0 && states.length > 0) {
  //     const { state, city } = formDatas;

  //     const selectedCountry = countries.find(
  //       (country) => country.name === formDatas.country
  //     );

  //     if (selectedCountry) {
  //       const selectedCountryIsoCode = selectedCountry.isoCode;

  //       setCountryIsoCode(selectedCountryIsoCode);
  //       setStates(State.getStatesOfCountry(selectedCountryIsoCode));

  //       const selectedState = states.find((s) => s.name === state);

  //       if (selectedState) {
  //         const selectedStateName = selectedState.name;

  //         setCities(
  //           City.getCitiesOfState(selectedCountryIsoCode, selectedState.isoCode)
  //         );

  //         setForm((prevForm) => ({
  //           ...prevForm,
  //           state: selectedStateName,
  //           city,
  //         }));
  //       }
  //     }
  //   }
  // }, [formDatas, countries, states]); // Only run the effect when formDatas, countries, or states change
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      const message =
        "Are you sure you want to leave? All provided data will be lost.";
      e.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <div className="sections">
      {/* {showModal && <Save onSave="" hideConfirmBox={onCancel} />} */}
      <section>
        <div className="property-detail">
          <div className="heading">
            <p>
              {nextStep === 1
                ? "Property Details"
                : nextStep === 2
                ? "Price Details"
                : nextStep === 3
                ? "Upload Documents"
                : "Sign and Upload Documents"}
            </p>
            <p>Step {nextStep}/4</p>
          </div>
          {nextStep === 2 ? (
            <PriceDetail
              form={form}
              onDetails={onDetails}
              onChangeHandler={onChangeHandler}
              onFilechangeHandler={onFilechangeHandler}
            />
          ) : nextStep === 3 ? (
            <UploadDocs
              form={form}
              setForm={setForm}
              onChangeHandler={onChangeHandler}
              onFilechangeHandler={onFilechangeHandler}
              onBackClick={onDetails}
            />
          ) : nextStep === 4 ? (
            <SignDocs
              form={form}
              setForm={setForm}
              onChangeHandler={onChangeHandler}
              onFilechangeHandler={onFilechangeHandler}
              onBackClick={onDetails}
            />
          ) : (
            <div className="details">
              <input
                type="text"
                placeholder="Property Address*"
                name="address"
                onChange={onChangeHandler}
                value={form.address}
                required
              />
              <input
                type="text"
                placeholder="Property Name (optional)"
                name="name"
                onChange={onChangeHandler}
                value={form.name}
              />
              <select
                name="property_type"
                value={form.property_type}
                onChange={handlePropertyTypeChange}
                required
              >
                <option value={0}>Property Type*</option>
                {propertyTypes &&
                  propertyTypes.length > 0 &&
                  propertyTypes.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
              <div className="additional">
                <input
                  type="number"
                  placeholder="Carpet Area"
                  name="area"
                  value={form.area}
                  onChange={onChangeHandler}
                  required
                />
                <select
                  name="area_unit"
                  value={form.area_unit}
                  onChange={onChangeHandler}
                >
                  <option value="0">Unit</option>
                  <option value="sqft">Sq ft</option>
                  <option value="sqyd">Sq yd</option>
                </select>
              </div>
              {/* <select
                name="country"
                value={form.country}
                onChange={handleCountryChange}
                required
              >
                <option value={0}>Country*</option>
                {countries &&
                  countries.length > 0 &&
                  countries.map((item) => (
                    <option value={item.name} key={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
              </select> */}
              <select
                name="country"
                value={form.country}
                onChange={onChangeHandler}
                required
              >
                <option value={0}>Country*</option>
                {country &&
                  country.length > 0 &&
                  country.map((item) => (
                    <option value={item.label}>{item.label}</option>
                  ))}
              </select>

              <input
                type="text"
                placeholder="State*"
                name="state"
                value={form.state}
                onChange={onChangeHandler}
                required
              />
              {/* <select
                name="state"
                value={form.state}
                onChange={handleStateChange}
                required
              >
                <option value="">State*</option>
                {states &&
                  states.length > 0 &&
                  states.map((item) => (
                    <option value={item.name} key={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
              </select> */}
              <div className="additional1">
                {/* <select
                  name="city"
                  value={form.city}
                  onChange={handleCityChange}
                  required
                >
                  <option value="">City*</option>
                  {cities &&
                    cities.length > 0 &&
                    cities.map((item) => (
                      <option value={item.name} key={item.name}>
                        {item.name}
                      </option>
                    ))}
                </select> */}
                <input
                  type="text"
                  placeholder="City*"
                  name="city"
                  value={form.city}
                  onChange={onChangeHandler}
                  required
                />
                <input
                  type="number"
                  placeholder="Zip Code*"
                  name="zipcode"
                  value={form.zipcode}
                  onChange={onChangeHandler}
                  required
                />
              </div>
              <p className="error-message">
                {errors.genericError && <p>{errors.genericError}</p>}
              </p>
              <div className="footer-btn">
                <button>
                  <p onClick={onBackClick}>Back</p> <AiOutlineArrowLeft />
                </button>
                <button type="button" onClick={handleSubmit}>
                  <p>Continue</p> <AiOutlineArrowRight />
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
      <section>
        <div className="property-score">
          <div className="score">
            <div className="score-label">
              <div
                className={
                  nextStep === 1
                    ? "score-chart25"
                    : nextStep === 2
                    ? "score-chart50"
                    : nextStep === 3
                    ? "score-chart75"
                    : "score-chart100"
                }
              ></div>
              <p>
                {nextStep === 1
                  ? "25%"
                  : nextStep === 2
                  ? "50%"
                  : nextStep === 3
                  ? "75%"
                  : "100%"}
              </p>
            </div>
            <div className="score-heading">
              <p>Property Score</p>
            </div>
            <div className="score-detail">
              <p>
                The score of your property depends on the accuracy and
                completeness of the data you submit.
              </p>
            </div>
            {/* <div className="autosave">
              <input type="checkbox" id="autosave" className="round-check" />
              <label htmlFor="autosave">Autosave On</label>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default SellPropertyDetail;
