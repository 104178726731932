import React, { useCallback, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import "./PriceDetail.css";
import { Link, useNavigate } from "react-router-dom";
import { needPriceConsultation } from "../../../../Api/user";
import useLocalStorage from "../../../../utils/useLocalStorage";

function PriceDetail({
  form,
  setForm,
  onDetails,
  onChangeHandler,
  onFilechangeHandler,
}) {
  const [consultChecked, setConsultChecked] = useState(false);
  const [error, setError] = useState("");

  const handleConsultChange = (e) => {
    setConsultChecked(e.target.checked);
    setError(""); // Clear error when checkbox changes
  };
  const navigate = useNavigate();
  const needConsultation = useCallback(async (e) => {
    const cleanData = {
      phone: form.contact_number.trim(),
      email: form.contact_email,
      name: form.contact_name.trim(),
    };
    if (cleanData.name && cleanData.email && cleanData.phone) {
      const response = await needPriceConsultation(cleanData);
      if (response) {
        if (response === 401) {
          useLocalStorage.removeUser();
          navigate("/login");
        }
      }
    }
  }, []);
  const handleSubmit = () => {
    if (!consultChecked) {
      if (!form.price || !form.token_amount) {
        setError("Please provide prices or check 'Need price consultation'");
        return;
      }
    }

    // Clear error if no validation issues
    setError("");
    if (consultChecked) {
      needConsultation();
    }
    onDetails(3);
  };
  return (
    <section>
      {/* {nextStep ? (
        <UploadDocs
          form={form}
          setForm={setForm}
          onChangeHandler={onChangeHandler}
          onFilechangeHandler={onFilechangeHandler}
          onBackClick={onPrice}
        />
      ) : ( */}
      <div className="property-detail">
        {/* <div className="heading">
          <p>Price Details</p>
          <p>Step 2/4</p>
        </div> */}
        <div className="details">
          <div className="price-inputs">
            <div className="currency-select">
              <select
                name="currency"
                onChange={onChangeHandler}
                value={form.currency}
              >
                <option value="INR">INR</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </select>
            </div>
            <div className="price">
              <input
                type="number"
                placeholder="Enter expected price"
                name="price"
                value={form.price}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="price-inputs">
            <div className="currency-select">
              <select>
                <option value="INR">INR</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </select>
            </div>
            <div className="price">
              <input
                type="number"
                placeholder="Token Price"
                name="token_amount"
                value={form.token_amount}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="consultation">
            <input
              type="checkbox"
              name="consult"
              id="consult"
              value="1"
              checked={consultChecked}
              onChange={handleConsultChange}
            />
            <label htmlFor="consult">Need price consultation</label>
          </div>
          <p className="error-message">{error && <p>{error}</p>}</p>
          <div className="footer-btn">
            <button>
              <p onClick={() => onDetails(1)}>Back</p> <AiOutlineArrowLeft />
            </button>
            <button type="button" onClick={() => handleSubmit()}>
              <p>Continue</p> <AiOutlineArrowRight />
            </button>
          </div>
        </div>
      </div>
      {/* // )} */}
    </section>
  );
}

export default PriceDetail;
