import React, { useCallback, useEffect, useState } from "react";
import styles from "./EditUserproperty.module.css";
import delete_red from "../../../../../Assets/Images/delete_red.png";
import Addicon from "../../../../../Assets/Images/addphoto.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useLocalStorage from "../../../../../utils/useLocalStorage";
import {
    addImages,
    fetchPropertyAddEditData,
    fetchPropertyDetail,
} from "../../../../../Api/user";
import addimage from "../../../../../Assets/Images/addimage.png";
import { BiChevronDown } from "react-icons/bi";
import country from "../../../../../Country";
import { AiOutlineInfoCircle } from "react-icons/ai";
import upload from "../../../../../Assets/Images/u_upload.png";
import uploadDone from "../../../../../Assets/Images/upload_done.png";
import cancleUpload from "../../../../../Assets/Images/cancel.png";
import { IoAlertCircleOutline } from "react-icons/io5";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import DocViewer from "../../../../../Components/Document Viewer/DocViewer";
import LoadingBar from "../../../../../Components/LoadingBar/LoadingBar";

const EditUserproperty = () => {
    const [edit, setEdit] = useState(null);
    const [propertyType, setPropertyTypes] = useState(null);
    const [loading, setLoader] = useState(false);
    const [allimages, setAllimages] = useState({
        main: "",
        images: [],
        fard: "",
        title_deed: "",
        confidentiality_doc: "",
        three_months_mend: "",
        area: "",
        area_unit: "",
        token_amount: "",
        price: ""
    });
    const [viewerVisible, setViewerVisible] = useState(false); // State for the viewer
    const [viewerUrl, setViewerUrl] = useState("");

    const BASE_URL =
        process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL
            : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;

    const { id } = useParams();
    const navigate = useNavigate();

    const fetchDetails = useCallback(async () => {
        const apiResponse = await fetchPropertyDetail(id);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeUser();
                navigate("/login");
            } else {
                const images =
                    apiResponse?.images.length !== 0
                        ? apiResponse?.images.split(",").map((src) => BASE_URL + src)
                        : [];
                const main =
                    apiResponse.thumbnail.length > 0
                        ? BASE_URL + apiResponse.thumbnail
                        : "";
                setEdit({
                    ...apiResponse,
                    images:
                        apiResponse?.images.length !== 0
                            ? apiResponse.images?.split(",")
                            : [],
                });
                setAllimages({
                    main: main,
                    images: images,
                    fard: apiResponse.fard,
                    title_deed: apiResponse.title_deed,
                    confidentiality_doc: apiResponse.confidentiality_doc,
                    three_months_mend: apiResponse.three_months_mend,
                    area: apiResponse.area?.split(" ")[0],
                    area_unit: apiResponse.area?.split(" ")[1],
                    price: apiResponse.price,
                    token_amount: apiResponse.token_amount
                });
            }
        } else {
            setEdit([]);
        }
    }, [id]);

    const fetchPropertyType = useCallback(async () => {
        const apiResponse = await fetchPropertyAddEditData();
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeUser();
                navigate("/login");
            } else {
                setPropertyTypes(apiResponse.property_types);
            }
        } else {
            setPropertyTypes([]);
        }
    }, [propertyType]);
    useEffect(() => {
        fetchDetails();
        fetchPropertyType();
    }, [id]);
    const openViewer = (url) => {
        setViewerUrl(url);
        setViewerVisible(true);
    };
    const handleSelectThumbnail = useCallback(
        (e) => {
            const MAX_FILE_SIZE = 20000; // kb
            const img = e.target.files[0];
            const fileSizeKiloBytes = img.size / 1024;
            if (e.target && e.target.files && e.target.files.length > 0) {
                // if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                //     toast.error("File size is very large")
                // } else {
                // }
                const preview = URL.createObjectURL(e.target.files[0]);
                setAllimages((prev) => ({ ...prev, main: preview }));
                setEdit((prev) => ({ ...prev, thumbnail: [img] }));
            }
        },
        [allimages.main, edit?.thumbnail]
    );
    const handleSelectImages = useCallback(
        (e) => {
            const MAX_FILE_SIZE = 20000; // kb
            const img = e.target.files[0];
            const fileSizeKiloBytes = img.size / 1024;
            if (e.target && e.target.files && e.target.files.length > 0) {
                if (allimages.images.length + e.target.files.length > 15) {
                    toast.error("You can select only 15 images");
                } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    toast.error("File size is very large");
                } else {
                    const filesArray = Array.from(e.target.files).map((file) =>
                        URL.createObjectURL(file)
                    );
                    const img = [...allimages.images];
                    setAllimages((prev) => ({ ...prev, images: img.concat(filesArray) }));
                    const imgs = edit?.new_images ? [...edit.new_images] : [];
                    for (let i = 0; i < e.target.files.length; i++) {
                        imgs.push(e.target.files[i]);
                    }
                    setEdit({ ...edit, new_images: imgs });
                    // if (!Array.isArray(edit?.new_images)) {
                    // } else {
                    //     const images = [...edit.images];
                    //     for (let i = 0; i < e.target.files.length; i++) {
                    //         images.push(e.target.files[i]);
                    //     }
                    //     setEdit({ ...edit, new_images: images });
                    // }
                }
            }
        },
        [allimages, edit]
    );
    const handleRemove = useCallback(
        (e) => {
            const id = e.target.id;
            const pre_img = [...allimages.images];
            pre_img?.splice(id, 1);
            setAllimages({ ...allimages, images: pre_img });
            if (id <= (edit.images.length - 1)) {
                const imgs = [...edit?.images];
                imgs?.splice(id, 1);
                setEdit({ ...edit, images: imgs });
            } else {
                const imgs = [...edit?.new_images];
                const i = id - edit.images.length;
                imgs?.splice(i, 1);
                setEdit({ ...edit, new_images: imgs });
            }
        },
        [edit, allimages]
    );
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === "number") {
            setEdit({ ...edit, [name]: Number(value) });
        } else {
            setEdit({ ...edit, [name]: value });
        }
    };
    const handleSubmit = useCallback(async () => {
        const { address,
            city,
            country,
            state,
            zipcode,
            property_type,
            area,
            area_unit,
            title_deed,
            three_months_mend,
            confidentiality_doc,
            fard } = edit
        console.log(edit, allimages)
        if (address.length === 0 ||
            city.length === 0 ||
            country.length === 0 ||
            state.length === 0 ||
            zipcode.length === 0 ||
            property_type.length === 0 ||
            area.length === 0 ||
            title_deed.length === 0 ||
            three_months_mend.length === 0 ||
            confidentiality_doc.length === 0 ||
            fard.length === 0) {
            toast.error("Please enter all mandatory fields.");
        }
        else if (edit.images.length > 0 && edit.thumbnail <= 0) {
            toast.error("Please Select Thumbnail");
        } else if (allimages.price === 0 && (edit.price !== 0 || edit.token_amount !== 0)) {
            toast.error("Please Remove your price or token amount");
        }
        else {
            setLoader(true)
            const apiResponse = await addImages("23232", edit);
            if (apiResponse) {
                if (apiResponse === 401) {
                    useLocalStorage.removeUser();
                    navigate("/login");
                } else {
                    setLoader(false)
                    navigate("/propertylists");
                }
            }
        }
    }, [edit]);
    const handleChangeSelect = useCallback(
        (e) => {
            let value = e.target.value;
            const name = e.target.name;
            if (name === "area_unit") {
                setAllimages({ ...allimages, area_unit: value });
                setEdit({ ...edit, area: allimages.area + " " + value });
            } else {
                setAllimages({ ...allimages, area: value });
                setEdit({ ...edit, area: value + " " + allimages.area_unit });
            }
        },
        [edit, allimages]
    );
    const handleSlectFile = useCallback(
        (e) => {
            const file = e.target.files[0];
            const name = e.target.name;
            if (file) {
                if (name === "fard") {
                    setEdit({ ...edit, fard: file });
                    setAllimages({ ...allimages, fard: file.name });
                } else if (name === "title_deed") {
                    setEdit({ ...edit, title_deed: file });
                    setAllimages({ ...allimages, title_deed: file.name });
                } else if (name === "confidentiality_doc") {
                    setEdit({ ...edit, confidentiality_doc: file });
                    setAllimages({ ...allimages, confidentiality_doc: file.name });
                } else {
                    setEdit({ ...edit, three_months_mend: file });
                    setAllimages({ ...allimages, three_months_mend: file.name });
                }
            }
        },
        [edit, allimages]
    );
    const handleRemoveDetailDoc = useCallback(
        (e) => {
            const name = e.target.name;
            if (name === "title_deed") {
                setEdit({ ...edit, title_deed: "" });
                setAllimages({ ...allimages, title_deed: "" });
            } else if (name === "fard") {
                setEdit({ ...edit, fard: "" });
                setAllimages({ ...allimages, fard: "" });
            } else if (name === "confidentiality_doc") {
                setEdit({ ...edit, confidentiality_doc: "" });
                setAllimages({ ...allimages, confidentiality_doc: "" });
            } else if (name === "three_months_mend") {
                setEdit({ ...edit, title_deed: "" });
                setAllimages({ ...allimages, three_months_mend: "" });
            }
        },
        [edit, allimages]
    );
    return (
        <>
            {viewerVisible && (
                <div className="viewer-overlay">
                    <div className="viewer-popup">
                        {/* Your DocumentViewer component that takes viewerUrl as prop */}
                        <DocViewer
                            url={viewerUrl}
                            onClose={() => setViewerVisible(false)}
                        />
                    </div>
                </div>
            )}
            <div className={styles.navigator_link}>
                Account &gt; <Link to="/propertylists">My Properties</Link>
            </div>
            <div className={styles.container}>
                <h1 className={styles.main_heading}>Edit & Preview your Property</h1>
                <p className={styles.subheading}>
                    To edit your property click on respective edit button
                </p>
                <label className={styles.thumbnail_div} htmlFor="add_image">
                    <img
                        src={allimages.main.length !== 0 ? allimages.main : addimage}
                        alt="img"
                        height="100%"
                        width="200px"
                    />
                    <input
                        type="file"
                        name="add_image"
                        id="add_image"
                        accept="image/*"
                        hidden
                        multiple
                        onChange={handleSelectThumbnail}
                    />
                    <p>Edit Thumbnail</p>
                </label>
                <section>
                    <h4 className={styles.section_heading}>Property Detail</h4>
                    <div className={styles.input_div}>
                        <label>Propery Name (optional)</label>
                        <input
                            type="text"
                            placeholder="Enter property name"
                            onChange={handleChange}
                            name="name"
                            value={edit?.name}
                            className={styles.input_feild}
                        />
                        {/* <p>Edit</p> */}
                    </div>
                    <div className={styles.input_div}>
                        <label>Property Locality</label>
                        <input
                            type="text"
                            placeholder="Enter property locality"
                            onChange={handleChange}
                            name="address"
                            value={edit?.address}
                            className={styles.input_feild}
                        />
                        {/* <p>Edit</p> */}
                    </div>
                    <div className={styles.input_div}>
                        <label>Property Type</label>
                        {/* <input type='text' placeholder='Enter property Type' onChange={handleChange} name='property_type' value={edit?.property_type} className={styles.input_feild} /> */}
                        <div className={styles.select_property_type_div}>
                            <select
                                value={edit?.property_type}
                                name="property_type"
                                className={styles.select_property_type}
                                onChange={handleChange}
                            >
                                {propertyType &&
                                    propertyType.map((val) => (
                                        <>
                                            <option value={val.id}>{val.name}</option>
                                        </>
                                    ))}
                            </select>
                            <BiChevronDown className="down-icon" size={16} sx={{ pl: -5 }} />
                        </div>
                        {/* <p>Edit</p> */}
                    </div>
                    <div className={styles.carpet_input_div}>
                        <div className={styles.carpet_first_child}>
                            <label>Carpet Area</label>
                        </div>
                        <div className={styles.carpet_second_child}>
                            <input
                                type="text"
                                name="area"
                                placeholder="Enter Carpet Area"
                                onChange={handleChangeSelect}
                                value={allimages?.area}
                                className={styles.input_feild}
                            />
                            <div className={styles.carpet_select_property_type_div}>
                                <select
                                    name="area_unit"
                                    value={allimages?.area_unit}
                                    className={styles.select_property_type}
                                    onChange={handleChangeSelect}
                                >
                                    <option value="sqft">Sq ft</option>
                                    <option value="sqyd">Sq yd</option>
                                </select>
                                <BiChevronDown
                                    className="down-icon"
                                    size={16}
                                    sx={{ pl: -5 }}
                                />
                            </div>
                        </div>
                        {/* <p>Edit</p> */}
                    </div>
                    <div className={styles.input_div}>
                        <label>Country </label>
                        <div className={styles.select_property_type_div}>
                            <select
                                value={edit?.country}
                                name="country"
                                className={styles.select_property_type}
                                onChange={handleChange}
                            >
                                {country &&
                                    country.map((val) => (
                                        <>
                                            <option value={val.label}>{val.label}</option>
                                        </>
                                    ))}
                            </select>
                            <BiChevronDown className="down-icon" size={16} sx={{ pl: -5 }} />
                        </div>
                        {/* <p>Edit</p> */}
                    </div>
                    <div className={styles.input_div}>
                        <label>State </label>
                        <input
                            type="text"
                            placeholder="Enter State"
                            onChange={handleChange}
                            name="state"
                            value={edit?.state}
                            className={styles.input_feild}
                        />
                        {/* <p>Edit</p> */}
                    </div>
                    <div className={styles.input_div}>
                        <label>City </label>
                        <input
                            type="text"
                            placeholder="Enter City"
                            onChange={handleChange}
                            name="city"
                            value={edit?.city}
                            className={styles.input_feild}
                        />
                        {/* <p>Edit</p> */}
                    </div>
                    <div className={styles.input_div}>
                        <label>Zipcode</label>
                        <input
                            type="number"
                            placeholder="Enter Zipcode"
                            onChange={handleChange}
                            name="zipcode"
                            value={edit?.zipcode}
                            className={styles.input_feild}
                        />
                        {/* <p>Edit</p> */}
                    </div>
                </section>

                <section>
                    <h4 className={styles.section_heading}>Price Detail</h4>
                    <div className={styles.input_div}>
                        <label>Expected Amount </label>
                        <input
                            type="number"
                            placeholder="Enter Expected Amount"
                            onChange={handleChange}
                            name="price"
                            value={edit?.price}
                            className={styles.input_feild}
                        />
                        {/* <p>Edit</p> */}
                    </div>
                    <div className={styles.input_div}>
                        <label>Token Amount </label>
                        <input
                            type="number"
                            placeholder="Enter Token Amount"
                            onChange={handleChange}
                            name="token_amount"
                            value={edit?.token_amount}
                            className={styles.input_feild}
                        />
                        {/* <p>Edit</p> */}
                    </div>
                </section>

                <section>
                    <div className={styles.input_div}>
                        <h4 className={styles.section_heading}>Document</h4>
                    </div>
                    <label className={styles.titles}>Title Deed*</label>
                    <div
                        className={
                            !allimages?.title_deed
                                ? styles.prp_detail_flex
                                : styles.property_detail_div
                        }
                    >
                        {!allimages?.title_deed ? (
                            <>
                                <label htmlFor="title_deed" style={{ cursor: "pointer" }}>
                                    <img src={upload} alt="img" />
                                </label>
                                <div>
                                    <label className={styles.prp_detail_heading}>
                                        Title Deed{" "}
                                    </label>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        hidden
                                        placeholder="Enter property name"
                                        onChange={handleSlectFile}
                                        name="title_deed"
                                        id="title_deed"
                                        className={styles.input_feild}
                                    />
                                    <p className={styles.prp_detail_sub_head}>
                                        Drop or upload file here
                                        <br />
                                        Allowed files pdf, docs, jpg, svg within 2mb
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.doc_selected}>
                                    <label htmlFor="title_deed" style={{ cursor: "pointer" }}>
                                        <img src={uploadDone} alt="img" />
                                    </label>
                                    <label className={styles.prp_detail_heading}>
                                        {allimages?.title_deed}
                                    </label>
                                </div>
                                <div>
                                    <img
                                        src={cancleUpload}
                                        alt="img"
                                        name="title_deed"
                                        onClick={handleRemoveDetailDoc}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <label className={styles.titles}>Fard*</label>
                    <div
                        className={
                            !allimages?.fard
                                ? styles.prp_detail_flex
                                : styles.property_detail_div
                        }
                    >
                        {!allimages?.fard ? (
                            <>
                                <label htmlFor="fard" style={{ cursor: "pointer" }}>
                                    <img src={upload} alt="img" />
                                </label>
                                <div>
                                    <label className={styles.prp_detail_heading}>Fard </label>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        hidden
                                        onChange={handleSlectFile}
                                        name="fard"
                                        id="fard"
                                        className={styles.input_feild}
                                    />
                                    <p className={styles.prp_detail_sub_head}>
                                        Drop or upload file here
                                        <br />
                                        Allowed files pdf, docs, jpg, svg within 2mb
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.doc_selected}>
                                    <label htmlFor="fard" style={{ cursor: "pointer" }}>
                                        <img src={uploadDone} alt="img" />
                                    </label>
                                    <label className={styles.prp_detail_heading}>
                                        {allimages?.fard}
                                    </label>
                                </div>
                                <div>
                                    <img
                                        src={cancleUpload}
                                        alt="img"
                                        name="fard"
                                        onClick={handleRemoveDetailDoc}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles.download_btn_div}>
                        <label className={styles.titles}>3 Months Mandate*</label>
                        <button
                            className={styles.download_btn}
                            onClick={() =>
                                openViewer(`${BASE_URL}${allimages?.three_months_mend}`)
                            }
                        >
                            Download Here{" "}
                            <MdOutlineDownloadForOffline size={30} color="#0084D1" />
                        </button>
                    </div>
                    <div
                        className={
                            !allimages?.three_months_mend
                                ? styles.prp_detail_flex
                                : styles.property_detail_div
                        }
                    >
                        {!allimages?.three_months_mend ? (
                            <>
                                <label
                                    htmlFor="three_months_mend"
                                    style={{ cursor: "pointer" }}
                                >
                                    <img src={upload} alt="img" />
                                </label>
                                <div>
                                    <label className={styles.prp_detail_heading}>
                                        3 Month Mandate{" "}
                                    </label>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        hidden
                                        onChange={handleSlectFile}
                                        name="three_months_mend"
                                        id="three_months_mend"
                                        className={styles.input_feild}
                                    />
                                    <p className={styles.prp_detail_sub_head}>
                                        Drop or upload file here
                                        <br />
                                        Allowed files pdf, docs, jpg, svg within 2mb
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.doc_selected}>
                                    <label
                                        htmlFor="three_months_mend"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img src={uploadDone} alt="img" />
                                    </label>
                                    <label className={styles.prp_detail_heading}>
                                        {allimages?.three_months_mend}
                                    </label>
                                </div>
                                <div>
                                    <img
                                        src={cancleUpload}
                                        alt="img"
                                        name="three_months_mend"
                                        onClick={handleRemoveDetailDoc}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles.download_btn_div}>
                        <label className={styles.titles}>Condidentiality Document*</label>
                        <button
                            className={styles.download_btn}
                            onClick={() =>
                                openViewer(`${BASE_URL}${allimages?.confidentiality_doc}`)
                            }
                        >
                            Download Here{" "}
                            <MdOutlineDownloadForOffline size={30} color="#0084D1" />
                        </button>
                    </div>
                    <div
                        className={
                            !allimages?.confidentiality_doc
                                ? styles.prp_detail_flex
                                : styles.property_detail_div
                        }
                    >
                        {!allimages?.confidentiality_doc ? (
                            <>
                                <label
                                    htmlFor="confidentiality_doc"
                                    style={{ cursor: "pointer" }}
                                >
                                    <img src={upload} alt="img" />
                                </label>
                                <div>
                                    <label className={styles.prp_detail_heading}>
                                        Condidentiality Document{" "}
                                    </label>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        hidden
                                        placeholder="Enter property name"
                                        onChange={handleSlectFile}
                                        name="confidentiality_doc"
                                        id="confidentiality_doc"
                                        className={styles.input_feild}
                                    />
                                    <p className={styles.prp_detail_sub_head}>
                                        Drop or upload file here
                                        <br />
                                        Allowed files pdf, docs, jpg, svg within 2mb
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.doc_selected}>
                                    <label
                                        htmlFor="confidentiality_doc"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img src={uploadDone} alt="img" />
                                    </label>
                                    <label className={styles.prp_detail_heading}>
                                        {allimages?.confidentiality_doc}
                                    </label>
                                </div>
                                <div>
                                    <img
                                        src={cancleUpload}
                                        alt="img"
                                        name="confidentiality_doc"
                                        onClick={handleRemoveDetailDoc}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </section>

                <section>
                    <h4 className={styles.section_heading}>Property Images</h4>
                    <div className={styles.images_div}>
                        {allimages &&
                            allimages.images.map((src, i) => (
                                <div key={i}>
                                    <img src={src} alt="img" height="100%" width="100%" />
                                    <img
                                        src={delete_red}
                                        alt="delete"
                                        id={i}
                                        onClick={handleRemove}
                                        className={styles.delete_img}
                                    />
                                </div>
                            ))}
                        <label className={styles.add_img_btn}>
                            <img src={Addicon} alt="img" />
                            <input
                                type="file"
                                name="add_image"
                                id="images"
                                accept="image/*"
                                hidden
                                multiple
                                onChange={handleSelectImages}
                            />
                            <label htmlFor="images" style={{ cursor: "pointer" }}>
                                Add Photo
                            </label>
                        </label>
                    </div>
                </section>
                <div className={styles.save_btn}>
                    {loading ?
                        <button
                            className={styles.save_btn}
                        >
                            <LoadingBar />
                        </button>
                        :
                        <button
                            className={styles.save_btn}
                            onClick={handleSubmit}
                        >
                            Save Changes
                        </button>
                    }
                </div>
                <div className="faqs-enquire">
                    <div className="content">
                        <p>Do you have any questions?</p>
                        <p>Put your email address and we will get back to you</p>
                    </div>
                    <div className="input">
                        <input placeholder="Enter your email" />
                        <button type="button">Enquire Now</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditUserproperty;




// question paper {
// paper_type:"iit"
// info:""
// }