import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { generalEnquireNow } from "../../Api/generalEnquiries";

const ContactUs = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState(false);

  const onChangeHandler = useCallback(
    (e) => {
      setForm((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    },
    [form]
  );

  const queryHandler = useCallback(
    async (e) => {
      e.preventDefault();
      const cleanData = {
        name: form.name.trim(),
        email: form.email.trim(),
        subject: form.subject.trim(),
        message: form.message.trim(),
      };
      if (
        cleanData.name &&
        cleanData.email &&
        cleanData.subject &&
        cleanData.message
      ) {
        const response = await generalEnquireNow(cleanData);
        if (response) {
          setForm({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        } else {
          setForm({
            ...cleanData,
          });
        }
      } else {
        setError("Please fill all mandatory fields");
      }
    },
    [form]
  );

  const findPlan = () => {
    navigate("/services/financial?search=&sort=0");
  };

  const findProperty = () => {
    navigate("/services/realestate");
  };

  return (
    <div className="container static">
      <div className="wrapper">
        <div className="content-wrapper">
          <ul className="services-tabs">
            <li onClick={findProperty}>
              <div />
              <h3>Real Estate</h3>
            </li>
            <li onClick={findPlan}>
              <div />
              <h3 style={{ color: "#6716A1" }}>Financial Services</h3>
            </li>
            {/* <li>
                            <div />
                            <h3 style={{color: '#066D74'}}>Medical Services</h3>
                        </li> */}
          </ul>
          <h2>Contact Us</h2>
          <div className="contact-us">
            <section>
              <div className="address">
                <h3>London</h3>
                <p>
                  54 Portland Place
                  <br />
                  London, W1B 1DY
                  <br />
                  United Kingdom
                </p>
                <p>
                  <span style={{ color: "#0F1C49" }}>8:00</span> am -{" "}
                  <span style={{ color: "#0F1C49" }}>5:00</span> pm (GMT -3)
                </p>
                <p>
                  <span style={{ color: "#09A4D8" }}>
                    <u>
                      <Link to="">Phone: +44 207 323 3888</Link>
                    </u>
                  </span>
                </p>
              </div>
              <div className="address">
                <h3>San Francisco</h3>
                <p>
                  548 Market Street, #88872
                  <br />
                  San Francisco, CA 94104-5401
                  <br />
                  United States
                </p>
                <p>
                  <span style={{ color: "#0F1C49" }}>8:00</span> am -{" "}
                  <span style={{ color: "#0F1C49" }}>5:00</span> pm (GMT -3)
                </p>
                <p>
                  <span style={{ color: "#09A4D8" }}>
                    <u>
                      <Link to="">Phone: +44 207 323 3888</Link>
                    </u>
                  </span>
                </p>
              </div>
              <div className="address">
                <h3>San Francisco</h3>
                <p>
                  548 Market Street, #88872
                  <br />
                  San Francisco, CA 94104-5401
                  <br />
                  United States
                </p>
                <p>
                  <span style={{ color: "#0F1C49" }}>8:00</span> am -{" "}
                  <span style={{ color: "#0F1C49" }}>5:00</span> pm (GMT -3)
                </p>
                <p>
                  <span style={{ color: "#09A4D8" }}>
                    <u>
                      <Link to="">Phone: +44 207 323 3888</Link>
                    </u>
                  </span>
                </p>
              </div>
              <div className="address">
                <h3>San Francisco</h3>
                <p>
                  548 Market Street, #88872
                  <br />
                  San Francisco, CA 94104-5401
                  <br />
                  United States
                </p>
                <p>
                  <span style={{ color: "#0F1C49" }}>8:00</span> am -{" "}
                  <span style={{ color: "#0F1C49" }}>5:00</span> pm (GMT -3)
                </p>
                <p>
                  <span style={{ color: "#09A4D8" }}>
                    <u>
                      <Link to="">Phone: +44 207 323 3888</Link>
                    </u>
                  </span>
                </p>
              </div>
            </section>
            <section>
              <h2>Feel free to contact us</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et.
              </p>
              <div className="message">
                <input
                  placeholder="Your name*"
                  value={form.name}
                  name="name"
                  onChange={onChangeHandler}
                  required
                />
                <input
                  placeholder="Your email*"
                  value={form.email}
                  name="email"
                  onChange={onChangeHandler}
                  required
                />
                <input
                  placeholder="Subject*"
                  value={form.subject}
                  name="subject"
                  onChange={onChangeHandler}
                  required
                />
                <textarea
                  placeholder="Your message*"
                  rows={6}
                  value={form.message}
                  name="message"
                  type="text"
                  onChange={onChangeHandler}
                  required
                />
                {error && <p className="error-message">{error}</p>}
                <button type="button" onClick={queryHandler}>
                  Send us a message
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
