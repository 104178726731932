import React, { useCallback, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './user.css'
import { editUser, fetchUserDetails } from '../../../Api/user';
import { loadUserRequest, logoutUserRequest } from '../../../Actions/user';
import useLocalStorage from '../../../utils/useLocalStorage';


const UserProfile = ({ hideProfileModal, data , logout , loadUser}) => {
  const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL : process.env.REACT_APP_DEVELOPMENT_API_BASE_URL;
  const navigate = useNavigate();
  const [form, setForm] = useState({
    ...data,
    imageSrc: `${BASE_URL}${data.image}`
  })
  const [error, setError] = useState(false);

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }, [form])

  const uploadImage = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      setForm({
        ...form,
        image: e.target.files[0],
        imageSrc: URL.createObjectURL(e.target.files[0])
      })
    }
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      first_name: form.first_name.toLowerCase().trim(),
      last_name: form.last_name.toLowerCase().trim(),
      phone: form.phone.trim(),
      image: form.image
    }
    if (cleanData.first_name && cleanData.last_name && cleanData.phone && cleanData.image) {
      if (cleanData.phone.length !== 10) {
        setError('Please enter a valid mobile number')
      } else {
        const formData = new FormData();

        formData.append('first_name', cleanData.first_name);
        formData.append('last_name', cleanData.last_name);
        formData.append('phone', cleanData.phone);
        formData.append('image', cleanData.image);

        let apiResponse = await editUser(cleanData);

        if (apiResponse) {
          if (apiResponse === 401) {
            useLocalStorage.removeUser();
            logout();
            navigate('/login')
          } else {  
            console.log("hello") 
            hideProfileModal()
            apiResponse = await fetchUserDetails();
            if (apiResponse) {
              if (apiResponse === 401) {
                useLocalStorage.removeUser();
                navigate('/login')
                logout();
              } else {
                loadUser(apiResponse)
              }
            }
          }
        } else {
          setForm({ ...form })
        }
      }
    } else {
      setError('Please fill all mandatory fields');
      setForm({ cleanData })
    }
  }, [form,hideProfileModal, logout])

  return (
    <div className='modal-container'>
      <div className='modal-popup'>
        <div className='header'>
          <h3>Change Profile</h3>
          <button className='close-btn' onClick={hideProfileModal}>
            <FaTimes color='#000000' size={16} />
          </button>
        </div>
        <div className='content'>
          <div className='image'>
            <div >
              {form.imageSrc && (<label htmlFor='image'><img src={form.imageSrc} alt='' /></label>)}
            </div>
            <input onChange={uploadImage} name="image" id='image' type='file' />
            <p>Maximum size: 2 MB,<br />at least 100px by 100px</p>
          </div>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>First Name<span className='mandatory-field'>&#42;</span></label>
                <input name="first_name" value={form.first_name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Last Name<span className='mandatory-field'>&#42;</span></label>
                <input name="last_name" value={form.last_name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Phone<span className='mandatory-field'>&#42;</span></label>
                <input type="number" name="phone" value={form.phone} onChange={changeHandler} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
              </div>
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button className={(form.first_name && form.last_name && form.phone && form.imageSrc) ? 'active' : 'save'}>Save Changes</button>
              <button className='cancel' onClick={hideProfileModal}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutUserRequest,
  loadUser: loadUserRequest,
}, dispatch)

export default connect(null, mapDispatchToProps)(UserProfile)  