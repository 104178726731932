import axios from 'axios';
import React, { useState, useEffect } from 'react'

const Avatar = ({ name, image }) => {
    const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;
    const [error, setError] = useState(true)
    useEffect(() => {
        if (image) {
            // fetch(`${BASE_URL}${image}`).then(res => {
            //     console.log(res,"response")
            //     if (res.status === 200) {
                    setError(false)
            //     }
            // })
        }
    }, [])
    return (
        <div className='avatars'>
            {error ? <span>{name.split('')[0]}</span> : <img src={`${BASE_URL}${image}`} />}
        </div>
    )
}

export default Avatar;