import React, { useRef, useEffect, useState, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiPhone } from "react-icons/bi";
import { FiBell } from "react-icons/fi";
import { GrLogout } from "react-icons/gr";
import { FaRegUserCircle } from "react-icons/fa";
import { HiMenuAlt3 } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import { fetchUserDetails, userLogout } from "../../Api/user";
import { loadUserRequest, logoutUserRequest } from "../../Actions/user";
import UserAvatar from "../Avatar/UserAvatar";
import useLocalStorage from "../../utils/useLocalStorage";
import "./navbar.css";

const Navbar = ({ user, loadUser, logout }) => {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // State for dropdown menu
  const menuOpenButtonRef = useRef(null);
  const menuCloseButtonRef = useRef(null);
  const menuRef = useRef(null);
  const settingsBtn = useRef(null);
  const settingsMenuRef = useRef(null);

  const menuHandler = useCallback((event) => {
    if (menuOpenButtonRef.current?.contains(event.target)) {
      setShowMenu(true);
    } else if (menuCloseButtonRef.current?.contains(event.target)) {
      setShowMenu(false);
    } else {
      if (menuRef.current?.contains(event.target)) {
        if (event.target.tagName === "A" || event.target.tagName === "BUTTON") {
          setShowMenu(false);
        }
      } else {
        setShowMenu(false);
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", menuHandler);
    return () => {
      document.removeEventListener("click", menuHandler);
    };
  }, [menuHandler]);

  useEffect(() => {
    if (
      path.includes("admin") ||
      path.includes("serviceProvider") ||
      path.includes("login") ||
      path.includes("signup") ||
      path.includes("forgotPassword") ||
      path.includes("verification")
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [path]);

  useEffect(() => {
    if (
      !path.includes("admin") &&
      !user.isAuthenticated &&
      useLocalStorage.getUser()
    ) {
      fetchData();
    }
  }, [path]);

  const fetchData = async () => {
    const apiResponse = await fetchUserDetails();
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeUser();
        logout();
        navigate("/");
      } else {
        loadUser(apiResponse);
      }
    }
  };

  const logoutHandler = async () => {
    const response = await userLogout();
    if (response) {
      useLocalStorage.removeUser();
      logout();
      navigate("/");
    }
  };

  const clickHandler = (event) => {
    if (settingsBtn.current?.contains(event.target)) {
      setSettingsMenu(true);
    } else {
      setSettingsMenu(false);
    }
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const hideDropdown = () => {
    setShowDropdown(false);
  };
  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, []);

  return (
    <>
      {show && (
        <nav
          className={
            user.isAuthenticated && useLocalStorage.getUser()
              ? "navbar active"
              : "navbar"
          }
        >
          <div className="navbar-max-width">
            
          <div className="logo" onClick={() => navigate("/")}>
            <div className="sign"></div>
          </div>
          {settingsMenu && (
            <ul ref={settingsMenuRef} className="user-settings-menu">
              <li>
                <Link to="/profile">
                  <FaRegUserCircle size={16} />
                  My Profile
                </Link>
              </li>
              <li>
                <Link to="/propertylists">
                  {/* <FaRegUserCircle size={16} /> */}
                  View Properties
                </Link>
              </li>
              <li>
                <button onClick={logoutHandler} type="button">
                  <GrLogout size={16} />
                  Logout
                </button>
              </li>
            </ul>
          )}
          <ul className={showMenu ? "menu active" : "menu"} ref={menuRef}>
            <li>
              <button
                ref={menuCloseButtonRef}
                type="button"
                className="nav-btn"
              >
                <GrClose size={28} />
              </button>
            </li>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li
              onMouseEnter={toggleDropdown}
              onMouseLeave={hideDropdown}
              className="solutions-link"
            >
              <a>Solutions</a>
              {/* Dropdown menu content */}
              {showDropdown && (
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/services/realestate">Real Estate</Link>
                  </li>
                  <hr />
                  <li>
                    <Link to="/services/financial?search=&sort=0">Finance</Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/aboutUs">About Us</Link>
            </li>
            <li>
              <Link to="/contactUs">Contact Us</Link>
            </li>
            <li>
              <span>
                <BiPhone size={28} />
                +91- 9876445400
              </span>
            </li>
            {!user.isAuthenticated && !useLocalStorage.getUser() && (
              <li>
                <Link to="/login">
                  <button type="button">Login or Sign Up</button>
                </Link>
              </li>
            )}
          </ul>
          {/* <button className='nav-btn notification-btn'><FiBell size={26} /><span>10</span></button> */}
          <button ref={menuOpenButtonRef} type="button" className="nav-btn">
            <HiMenuAlt3 size={28} />
          </button>
          {user.isAuthenticated && useLocalStorage.getUser() && (
            <button ref={settingsBtn} className="user-icon">
              <UserAvatar name={user.data.first_name} image={user.data.image} />
            </button>
          )}
          </div>
        </nav>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadUser: loadUserRequest,
      logout: logoutUserRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
