import React, { useCallback, useState ,useEffect} from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLocalStorage from '../../../utils/useLocalStorage';
import { fetchUsers } from '../../../Api/user';
import { fetchCustomPaymentData, generateCustomPaymentLink } from '../../../Api/customPayments';
import LoadingBar from '../../LoadingBar/LoadingBar';


const GenerateCustomPayments = ({ hideCustomModal , fetch, logout }) => {

   
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [cases, setCases] = useState([]);
    const [loading , setLoading] = useState(false);
    const [form, setForm] = useState({
        user_id: 0,
        case_id: 0,
        price: '',
        description: ''
    });
    const [error, setError] = useState(false)
    
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchUsers();
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setUsers([...apiResponse.users]);
            }
        } else {
            setUsers([]);
        }
    }, [])

    const changeHandler = useCallback(async (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }, [form])

    const fetchCaseData = useCallback(async () => {
        if (form.user_id !== 0) {
            const response = await fetchCustomPaymentData({ user_id: form.user_id });
            if (response) {
                if (response === 401) {
                    useLocalStorage.removeAdmin();
                    navigate('/admin/login')
                } else {
                    setCases([...response.custom_payment_case_data]);
                }
            } else {
                setCases([]);
            }
        }
    }, [form.user_id])

    useEffect(() => {
        fetchCaseData()
    }, [form.user_id])

    const submitHandler = useCallback( async(e) => {
        e.preventDefault();
        setLoading(true)
        const cleanData = {
            user_id : form.user_id,
            case_id : form.case_id,
            price : form.price,
            description : form.description.trim(),
        }
        
        if(cleanData.user_id !== 0 && cleanData.case_id !== 0 && cleanData.price && cleanData.description){
            const apiResponse = await generateCustomPaymentLink(cleanData);
            if (apiResponse) {
              if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                logout();
                navigate('/admin/login')
              } else {
                setForm({
                    user_id: 0,
                    case_id: 0,
                    price: '',
                    description: ''
                })
                setLoading(false);
              }
              hideCustomModal();
              fetch()
            } else {
              setForm({
                ...cleanData,
                useer_id: form.user_id,
                case_id: form.case_id
              })
            }
        }else{
            setLoading(false);
            setError('Please fill all mandatory fields')
            setForm({
                user_id: 0,
                case_id: 0,
                price: '',
                description: ''
            })
        }
    }, [form, hideCustomModal,logout])

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Generate Custom Payment Link</h3>
          <button className='close-btn' onClick={hideCustomModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
           <form onSubmit={submitHandler}>
                <div className='modal-input-grid'>
                   <div className='modal-input-box'>
                <label>Users:<span className='mandatory-field'>&#42;</span></label>
                <select name="user_id" value={form.user_id} onChange={changeHandler} >
                  <option value={0}></option>
                  {(users && users.length > 0) && (
                    <>
                      {users.map(user => (
                        <option value={user.id}>{user.first_name} {user.last_name}</option>
                      ))}
                    </>
                  )}
                </select>
              </div>
              <div className='modal-input-box'>
                <label>Cases:<span className='mandatory-field'>&#42;</span></label>
                <select name="case_id" value={form.case_id} onChange={changeHandler} >
                  <option value={0}></option>
                  {(cases && cases.length > 0) && (
                    <>
                      {cases.map(data => (
                        <option className='text-capitalize' value={data.case_id}>CASE{data.case_id} - PLAN {data.plan_name}</option>
                      ))}
                    </>
                  )}
                </select>
              </div>
                <div className='modal-input-box'>
                <label>Price<span className='mandatory-field'>&#42;</span></label>
                <input type="number" name="price" value={form.price} onChange={changeHandler} required />
              </div>
            <div className='modal-input-box'>
              <label>Description<span className='mandatory-field'>&#42;</span></label>
              <input type="text" name="description" value={form.description} onChange={changeHandler} required />
            </div>
              </div>
                 
                  {error && (<p className='error-message'>{error}</p>)}
                  <div className='action-box'>
                    {loading === true ? <button className='create'><LoadingBar /></button> :<button className='create'>Generate Custom Payment Link</button>}
                  </div>
                </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(GenerateCustomPayments)  