import React, { useState, useEffect } from 'react';
import { BsCheck } from 'react-icons/bs';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Plans = ({ data }) => {

    const [startIndex, setStartIndex] = useState(0);
    const [tabCount, setTabCount] = useState(1);

    useEffect(() => {
        const width = window.innerWidth;
        if (width < 768) {
            setTabCount(1)
        } else if (width >= 768 && width < 1024) {
            setTabCount(2)
        } else {
            setTabCount(3)
        }
    }, [window.innerWidth])

    const indexHandler = (type) => {
        if (type === '-') {
            if (startIndex > 0) {
                if (startIndex - tabCount >= 0) {
                    setStartIndex(startIndex - tabCount)
                } else {
                    setStartIndex(0)
                }
            }
        }
        if (type === "+") {
            if ((startIndex + tabCount) < data.length) {
                if (startIndex + tabCount <= data.length) {
                    setStartIndex(startIndex + tabCount)
                } else {
                    setStartIndex(data.length - tabCount)
                }
            }
        }
    }

    return (
        <section>
            <div className='plans-actions-left'>
                <button type='button' onClick={((startIndex - tabCount) >= 0) ? () => indexHandler('-') : null} className={((startIndex - tabCount) >= 0) ? 'active' : ''}>
                    <AiOutlineLeft size={20} />
                </button>
            </div>
            <ul className='plans'>
                {data.map((plan, index) => {
                    if (index < (startIndex + tabCount) && index >= startIndex) {
                        return (
                            <li className='plan'>
                                <h3>{plan.name}</h3>
                                <span className='price'><b>${plan.price}</b>{plan.additional_charges === 1 ? "onwards" : ""}</span>
                                <p className='desc'>{plan.description}</p>
                                <ul>
                                    {plan.benefits.split('\n').map(item => {
                                        if (item) {
                                            return (
                                                <li><BsCheck size={12} />{item}</li>
                                            )
                                        }
                                    })}
                                </ul>
                                {plan.additional_charges === 1 ?
                                    <p className='addons'><input type='checkbox' checked />Additional Chargeable Services</p>
                                    :
                                    <p className='addons'>No Additional Chargeable Services</p>
                                }
                                <Link to={`/services/financial/planDetails/${plan.id}`}><button>View Plan</button></Link>
                            </li>
                        )
                    }
                })}
            </ul>
            <div className='plans-actions-right'>
                <button type='button' onClick={((startIndex + tabCount) < data.length) ? () => indexHandler('+') : null} className={((startIndex + tabCount) < data.length) ? 'active' : ''}>
                    <AiOutlineRight size={20} />
                </button>
            </div>
        </section>
    )
}

export default Plans