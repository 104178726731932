import React, { useState, useCallback } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendUserVerificationMail, signup } from '../../Api/user';
import './auth.css'

const Signup = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        email: "",
        phone: "",
        password: "",
        first_name: "",
        last_name: "",
        confirm_password: "",
        is_human: false
    })
   
    const [verificationView, setVerificationView] = useState(false)
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const changeHandler = useCallback((e) => {
        if (e.target.name === 'is_human') {
            setForm(data => ({
                ...data,
                'is_human': !form.is_human
            }));
        } else {
            setForm(data => ({
                ...data,
                [e.target.name]: e.target.value
            }));
        }
    }, [form])

    const submitHandler = async (e) => {
        e.preventDefault();
        const cleanData = {
            first_name: form.first_name.trim(),
            last_name: form.last_name.trim(),
            email: form.email.trim(),
            phone: form.phone.trim(),
            password: form.password.trim(),
            confirm_password: form.confirm_password.trim()
        }

        if (form.is_human) {
            if (cleanData.first_name && cleanData.last_name && cleanData.email && cleanData.phone && cleanData.password && cleanData.confirm_password) {
                if (cleanData.phone.length !== 10) {
                    setError('please enter a valid phone number')
                } else {
                    if (cleanData.password.length < 8 || cleanData.confirm_password.length < 8) {
                        setError('password must have minimum 8 characters')
                    } else if (cleanData.password !== cleanData.confirm_password) {
                        setError('password does not match')
                    } else {
                        delete cleanData['confirm_password'];
                        const response = await signup(cleanData);
                        if (response) {
                            setVerificationView(!verificationView);
                        }
                    }
                }
            } else {
                setForm(data => ({
                    ...data,
                    ...cleanData
                }))
                setError("please fill all the mandatory fields")
            }
        } else {
            setError('Please proove that you are human')
        }
    };

    const resendEmail = useCallback(async()=> {
        console.log(form.email,"email")
        const cleanData={
            email: form.email.trim()
        }
        const apiResponse = await sendUserVerificationMail(cleanData)
        if(apiResponse){
            toast('Email has been resend')
        }else{
            toast('Some Error occured')
        }
    },[form])

    const showPasswordHandler = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])

    const showConfirmPasswordHandler = useCallback(() => {
        setShowConfirmPassword(!showConfirmPassword)
    }, [showConfirmPassword])


    return (
        <div className='auth-container'>
            <div className='auth-background'></div>
            <div className='auth-wrapper'>
                <section>
                    <h1>Get Solutions to your Problems!</h1>
                    <Link to="">Get Started anyway</Link>
                </section>
                <section>
                    {verificationView ? 
                    <div className='auth-box'>
                        <div className='auth-content'>
                           <h2>Please verify your email</h2>
                           <p>You’re almost there! We sent an email to <b>{form.email}</b></p>
                           <p>Just click on the link in that email to complete your sign up. If you don’t see it,you may need to <b>check your spam</b> folder.</p>
                           <p> Still can’t find the mail?</p>
                           <button type='submit' onClick={resendEmail}>Resend Email</button>
                           <p><b>Need Help?</b> <Link to="">Contact Us</Link></p>
                        </div>
                    </div>
                    :
                    <div className='auth-box'>
                        <h2>Welcome</h2>
                        <p>Sign up | <Link to="/login">Login to your account</Link></p>
                        <form onSubmit={submitHandler}>
                            <div className='input-group'>
                                <div className={form.firstName ? 'input-box active' : 'input-box'}>
                                    <label>First Name</label>
                                    <input onChange={changeHandler} value={form.first_name} type='text' name='first_name' placeholder='First Name*' required />
                                </div>
                                <div className={form.lastName ? 'input-box active' : 'input-box'}>
                                    <label>Last Name</label>
                                    <input onChange={changeHandler} value={form.last_name} type='text' name='last_name' placeholder='Last Name*' required />
                                </div>
                            </div>
                            <div className={form.email ? 'input-box active' : 'input-box'}>
                                <label>Email</label>
                                <input onChange={changeHandler} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={form.email} name='email' placeholder='Enter your email' required />
                            </div>
                            <div className={form.email ? 'input-box active' : 'input-box'}>
                                <label>Phone</label>
                                <input onChange={changeHandler} value={form.phone} pattern='[1-9]{1}[0-9]{9}' name='phone' type='number' placeholder='Enter your phone nmumber*' required />
                            </div>
                            <div className={form.password ? 'input-box active' : 'input-box'}>
                                <label>Password</label>
                                <input onChange={changeHandler} value={form.password} name='password' type={showPassword ? 'text' : 'password'} placeholder='Set Password*' required />
                                <button type='button' onClick={showPasswordHandler}>{showPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
                            </div>
                            <div className={form.confirmPassword ? 'input-box active' : 'input-box'}>
                                <label>Confirm Password</label>
                                <input onChange={changeHandler} value={form.confirm_password} name='confirm_password' type={showConfirmPassword ? 'text' : 'password'} placeholder='Confirm Password*' required />
                                <button type='button' onClick={showConfirmPasswordHandler}>{showConfirmPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
                            </div>
                            <div className='signup-form-footer'>
                                <label className='checkbox-container success'>I'm not a robot
                                    <input name='is_human' onChange={changeHandler} checked={form.is_human} type='checkbox' />
                                    <span className='checkmark' />
                                </label>
                                <div className='captcha' />
                            </div>
                            {error && (<p className='error-message'>{error}</p>)}
                            <button type='submit'>Sign Up</button>
                        </form>
                    </div>
                    }
                </section>
            </div>
        </div>
    )
}

export default Signup