import api from "../utils/api";
import { toast } from "react-toastify";

export const addCase = async (data) => {
    const apiResponse = await api.applicationApi('cases/addCase', 'POST', data, 3);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}
