import React, { useState, useCallback, useEffect } from "react";
import useLocalStorage from "../../../utils/useLocalStorage";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  activateDeactivateProperty,
  fetchActivePropertyTypes,
  fetchProperties,
} from "../../../Api/serviceProvider";
import AddProperty from "../../../Components/Popup/ServiceProvider/AddProperty";
import EditProperty from "../../../Components/Popup/ServiceProvider/EditProperty";
import { GoSearch } from "react-icons/go";

const ServiceProviderProperties = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    request_type: 0,
    offset: 0,
    property_type: 0,
    search: "",
  });
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState("");
  const [properties, setProperties] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentpage] = useState(1);
  const [search, setSearch] = useState("");

  const addModalHandler = () => {
    setAddModal(!addModal);
  };

  const editModalHandler = (data) => {
    setEditModal(!editModal);
    setEditData(data);
  };

  useEffect(() => {
    fetchData();
  }, [state]);

  const fetchData = useCallback(async () => {
    const apiResponse = await fetchProperties(state);
    const response = await fetchActivePropertyTypes();
    if (apiResponse || response) {
      if (apiResponse === 401 || response === 401) {
        useLocalStorage.removeServiceProvider();
        navigate("/serviceProvider/login");
      } else {
        setProperties([...apiResponse.properties]);
        setPropertyTypes([...response]);
        if (apiResponse.total_properties > 0) {
          if (apiResponse.total_properties % 10 > 0) {
            setTotalPages(parseInt(apiResponse.total_properties / 10) + 1);
          } else {
            setTotalPages(parseInt(apiResponse.total_properties / 10));
          }
        }
      }
    } else {
      setProperties([]);
      setTotalPages(0);
    }
  }, [state]);

  const requestTypeHandler = useCallback(
    (e) => {
      setState({
        ...state,
        request_type: e.target.value,
      });
    },
    [state]
  );

  const propertyTypeHandler = useCallback(
    (e) => {
      setState({
        ...state,
        property_type: e.target.value,
      });
    },
    [state]
  );

  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search]
  );

  const submitSearchHandler = useCallback(() => {
    setState({
      ...state,
      offset: 0,
      search: search,
    });
  }, [search, state]);

  const currentPageHandler = useCallback((pageNumber) => {
    setCurrentpage(pageNumber);
    setState({
      ...state,
      offset: (pageNumber - 1) * 10,
    });
  }, []);
  const viewPropertyHandler = useCallback((id) => {
    navigate(`/serviceProvider/properties/${id}`);
  }, []);
  const activateDeactivateHandler = async (data) => {
    let status = 0;
    if (data.is_active === 0) {
      status = 1;
    }
    const apiResponse = await activateDeactivateProperty(data.id, {
      request_type: status,
    });
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeServiceProvider();
        navigate("/serviceProvider/login");
      } else {
        fetchData();
      }
    }
  };

  return (
    <>
      {addModal && (
        <AddProperty fetchData={fetchData} hideAddModal={addModalHandler} />
      )}
      {editModal && (
        <EditProperty
          fetchData={fetchData}
          data={editData}
          hideEditModal={editModalHandler}
        />
      )}
      <div className="admin-container">
        <div className="breadcrumbs">
          <p>
            ServiceProvider <b>/ Properties</b>
          </p>
          <span>Properties</span>
        </div>
        <div className="content-wrapper">
          <div className="table-view">
            <div className="header">
              <h1>Properties List</h1>
              <button className="add-btn" onClick={addModalHandler}>
                Add
              </button>
            </div>
            <div className="table-filters">
              <div className="search">
                <input
                  placeholder="Search..."
                  type="text"
                  value={search}
                  onChange={searchHandler}
                />
                <button onClick={submitSearchHandler}>
                  <GoSearch size={16} />
                </button>
              </div>
              <div className="dropdown">
                <label>Property Type:</label>
                <select
                  value={state.property_type}
                  onChange={propertyTypeHandler}
                >
                  <option value={0}>All</option>
                  {propertyTypes &&
                    propertyTypes.length > 0 &&
                    propertyTypes.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
              <div className="dropdown">
                <label>Status:</label>
                <select
                  value={state.request_type}
                  onChange={requestTypeHandler}
                >
                  <option value={0}>All</option>
                  <option value={1}>Active</option>
                  <option value={2}>Not active</option>
                </select>
              </div>
            </div>

            {properties && properties.length > 0 && (
              <>
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Description</th>
                        <th>Location Url</th>
                        <th>Property Type</th>
                        <th>Services</th>
                        <th>Contact Email</th>
                        <th>Contact Number</th>
                        <th>Date Added</th>
                        <th>Date Modified</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {properties.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={`https://nriconnect.io/realEstate/Uploads/${item.thumbnail}`}
                              alt=""
                            />
                          </td>
                          <td
                            className="head"
                            onClick={() => viewPropertyHandler(item.id)}
                          >
                            {item.name}
                          </td>
                          <td>₹{item.price}</td>
                          <td>{item.description}</td>
                          <td>{item.location_url}</td>
                          <td>{item.property_type_name}</td>
                          <td>{item.services}</td>
                          <td>{item.contact_email}</td>
                          <td>{item.contact_number}</td>
                          <td>{item.created_at}</td>
                          <td>{item.updated_at}</td>
                          <td>
                            <span
                              className={
                                item.is_active === 1 ? "active" : "deactive"
                              }
                            >
                              {item.is_active === 1 ? "Active" : "Not Active"}
                            </span>
                          </td>
                          <td>
                            <div className="table-actions">
                              <button
                                className={
                                  item.is_active === 0
                                    ? "activate-btn"
                                    : "deactivate-btn"
                                }
                                onClick={() => activateDeactivateHandler(item)}
                              >
                                {item.is_active === 0
                                  ? "Activate"
                                  : "Deactivate"}
                              </button>
                              {item.is_active === 1 ? (
                                <button
                                  className="edit-btn"
                                  onClick={() => editModalHandler(item)}
                                >
                                  Edit
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {totalPages && totalPages > 1 ? (
                  <div className="admin-pagination">
                    <button
                      type="button"
                      className={currentPage === 1 ? "disabled" : ""}
                      onClick={
                        currentPage === 1
                          ? null
                          : () => currentPageHandler(currentPage - 1)
                      }
                    >
                      <AiFillCaretLeft size={16} />
                    </button>
                    <span>{`Page ${currentPage} / ${totalPages}`}</span>
                    <button
                      type="button"
                      className={currentPage === totalPages ? "disabled" : ""}
                      onClick={
                        currentPage === totalPages
                          ? null
                          : () => currentPageHandler(currentPage + 1)
                      }
                    >
                      <AiFillCaretRight size={16} />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceProviderProperties;
