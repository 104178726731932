import React, { useCallback, useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import {
  addFinancialPlan,
  fetchFinancialPlansAddEditData,
} from "../../../Api/financialPlans";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../utils/useLocalStorage";
import { logoutAdminRequest } from "../../../Actions/admin";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const AddPlan = ({ hideAddModal, fetchData, logout }) => {
  const [form, setForm] = useState({
    name: "",
    description: "",
    benefits: "",
    price: "",
    category_id: 0,
    docs: "",
    detailed_plan: "",
  });
  const [error, setError] = useState(false);
  const [financial_plans_categories, setCategory] = useState([]);
  const navigate = useNavigate();

  const changeHandler = useCallback(
    (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form]
  );

  const submitHandler = useCallback(
    async (e) => {
      e.preventDefault();
      const cleanData = {
        name: form.name.trim(),
        description: form.description.trim(),
        detailed_plan: form.detailed_plan.trim(),
        docs: form.docs.toLowerCase().trim(),
        benefits: form.benefits.trim(),
        price: form.price,
        category_id: form.category_id,
      };
      if (
        cleanData.name &&
        cleanData.description &&
        cleanData.detailed_plan &&
        cleanData.benefits &&
        cleanData.price &&
        cleanData.category_id &&
        cleanData.docs
      ) {
        const response = await addFinancialPlan(cleanData);
        if (response) {
          if (response === 401) {
            useLocalStorage.removeAdmin();
            logout();
            navigate("/admin/login");
          } else {
            setForm({
              name: "",
              description: "",
              benefits: "",
              price: "",
              category_id: 0,
              docs: "",
              detailed_plan: "",
            });
          }
          hideAddModal();
          fetchData();
        } else {
          setForm({
            ...cleanData,
          });
        }
      } else {
        setError("Please fill all mandatory fields");
      }
    },
    [form, hideAddModal, logout]
  );

  useEffect(() => {
    fetch();
  }, []);

  const fetch = useCallback(async () => {
    const apiResponse = await fetchFinancialPlansAddEditData();
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeAdmin();
        logout();
        navigate("/admin/login");
      } else {
        setCategory([...apiResponse.financial_plans_categories]);
      }
    } else {
      setCategory([]);
    }
  }, [form]);

  const uploadThumbnail = useCallback(
    (e) => {
      if (e.target.files && e.target.files[0]) {
        setForm({
          ...form,
          thumbnail: e.target.files[0],
          thumbnailSrc: URL.createObjectURL(e.target.files[0]),
        });
      }
    },
    [form]
  );

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="header">
          <h3>Add Plan</h3>
          <button className="close-btn" onClick={hideAddModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className="content">
          <form onSubmit={submitHandler}>
            <div className="modal-input-grid">
              <div className="modal-input-box">
                <label>
                  Category:<span className="mandatory-field">&#42;</span>
                </label>
                <select
                  name="category_id"
                  value={form.category_id}
                  onChange={changeHandler}
                >
                  <option
                    value={0}
                    disabled
                    selected
                    style={{ display: "none" }}
                  >
                    None
                  </option>
                  {financial_plans_categories &&
                    financial_plans_categories.length > 0 &&
                    financial_plans_categories.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="modal-input-grid">
              <div className="modal-input-box">
                <label>
                  Name<span className="mandatory-field">&#42;</span>
                </label>
                <input
                  name="name"
                  value={form.name}
                  onChange={changeHandler}
                  required
                />
              </div>
              <div className="modal-input-box">
                <label>
                  Price<span className="mandatory-field">&#42;</span>
                </label>
                <input
                  type="number"
                  name="price"
                  value={form.price}
                  onChange={changeHandler}
                  required
                />
              </div>
            </div>
            <div className="modal-input-box">
              <label>
                Description<span className="mandatory-field">&#42;</span>
              </label>
              <input
                type="text"
                name="description"
                value={form.description}
                onChange={changeHandler}
                required
              />
            </div>
            <div className="modal-input-box">
              <label>
                Documents<span className="mandatory-field">&#42;</span>
              </label>
              <input
                type="text"
                name="docs"
                value={form.docs}
                onChange={changeHandler}
                required
              />
              <p className="info">
                *please use comma(<b>,</b>) as a seperator between documents
              </p>
            </div>
            <div className="modal-input-box">
              <label>
                Benefits<span className="mandatory-field">&#42;</span>
              </label>
              <textarea
                name="benefits"
                value={form.benefits}
                onChange={changeHandler}
                rows={6}
                required
              >
                {form.benefits}
              </textarea>
              <p className="info">
                *press <b>ENTER</b> to add another benifit
              </p>
            </div>
            <div className="modal-input-box">
              <label>
                Detailed Description
                <span className="mandatory-field">&#42;</span>
              </label>
              <textarea
                name="detailed_plan"
                value={form.detailed_plan}
                onChange={changeHandler}
                rows={6}
                required
              >
                {form.detailed_plan}
              </textarea>
            </div>
            <div className="action-box">
              {error && <p className="error-message">{error}</p>}
              <button className="create">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: logoutAdminRequest,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddPlan);
