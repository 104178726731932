import React from 'react';
import { useLocation } from 'react-router-dom';
import Faqs from './Faqs';
import Hero from './Hero';
import Categories from './Categories';
import './financial.css'
import PlanDetails from './PlanDetail/PlanDetails';

const Financial = () => {
    const path = useLocation().pathname
    return (
        <div className='container'>
            {!path.includes('/financial/planDetails') && <Hero />}
            <div className={path.includes('/financial/planDetails') ? '' : 'financial-data-container'}>
                {/* <Plans /> */}
                {path.includes('/financial/planDetails') ? <PlanDetails /> : <Categories />}
                <Faqs />
            </div>
        </div>
    )
}

export default Financial