import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Appointment from "../../Components/Appointment/Appointment";

const Hero = () => {
  const navigate = useNavigate();
  const [showAppointment, setShowAppointment] = useState(false);

  const findPlan = () => {
    navigate("/services/financial?search=&sort=0");
  };

  const findProperty = () => {
    navigate("/services/realestate");
  };

  const appointmentHandler = () => {
    if (showAppointment) {
      setShowAppointment(false);
    } else {
      setShowAppointment(true);
    }
  };

  return (
    <>
      {showAppointment && <Appointment close={appointmentHandler} />}
      <div className="wrapper landing-hero">
        <div className="content-wrapper">
          <div className="landing-hero-header-wrapper">
            <div className="landing-hero-header">
              <h1>Navigate your</h1>
              <ul>
                <li>
                  <h1>Real Estate</h1>
                </li>
                <li>
                  <h1>Financial</h1>
                </li>
                {/* <li><h1>Medical</h1></li> */}
              </ul>
              <h1>matters with Confidence</h1>
            </div>
            <p>
              NRIConnect unlocks the full potential with our comprehensive
              services, from hassle-free property transactions to expert
              financial management, including money transfers, FEMA compliance,
              and tax planning in India.{" "}
            </p>
            <div className="landing-hero-enquire">
              {/* <input placeholder="Enter your Mobile number Or Email ID" /> */}
              <button type="button" onClick={appointmentHandler}>
                Schedule a Call
              </button>
            </div>
          </div>
          <ul className="landing-hero-services">
            <li>
              <div className="icon" />
              <h3>Real Estate</h3>
              <p>
                Buy or sell property in India with our expertise and
                zero-hassle.
              </p>
              <button onClick={findProperty}>Find Property</button>
            </li>
            <li>
              <div className="icon" />
              <h3>Financial Services</h3>
              <p>
                Looking for tax services, investment opportunities in India.
              </p>
              <button onClick={findPlan}>Find Plans</button>
            </li>
            {/* <li>
              <div className="icon" />
              <h3>Medical Services</h3>
              <p>
                Skip the long wait times abroad. Find best treatment in India
                provided by Top Hospitals.
              </p>
              <button>Find Treatments</button>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Hero;
