import React, { useState, useEffect } from 'react';
import { BiChevronDown } from 'react-icons/bi';

const Filters = ({ data, header, onChange, selectedFilters, clear, isLocation }) => {
    const [open, setOpen] = useState(true);

    const handleOpen = () => {
        setOpen(!open);
    }

    const changeHandler = (id) => {
        onChange(id);
    }

    const clearHandler = () => {
        const filters = data.map(item => item.id);
        clear(filters)
    }

    return (
        <div className={open ? "filter active" : "filter"}>
            <div className='header'>
                <h4>{header}</h4>
                <button onClick={clearHandler}>clear</button>
                <button onClick={handleOpen} className='toggle-btn'><BiChevronDown size={18} /></button>
            </div>
            <ul>
                {isLocation ?
                    <>
                        {data.map(item => (

                            <li>
                                <label>
                                    <input checked={selectedFilters.split(',').indexOf(item) !== -1} type='checkbox' onChange={() => changeHandler(item)} />
                                    {item}
                                    {/* <span>({item.total})</span> */}
                                </label>
                            </li>
                        ))}
                    </>
                    :
                    <>
                        {data.map(item => (

                            <li>
                                <label>
                                    <input checked={selectedFilters.split(',').indexOf("" + item.id) !== -1} type='checkbox' onChange={() => changeHandler(item.id)} />
                                    {item.name}
                                    {/* <span>({item.total})</span> */}
                                </label>
                            </li>
                        ))}
                    </>
                }
            </ul>
        </div>
    )
}

export default Filters