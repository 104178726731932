import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "./user.css";

const ConfirmDelete = ({ id, handleDelete, handleDeletePop }) => {

    return (
        <div className="modal-container">
            <div className="modal-popup">
                <div className="modal-content">
                    <h3>Do you really want to delete this property?</h3>
                    <div className="action-box">
                        <button onClick={() => handleDelete(id)}>Delete</button>
                        <button onClick={handleDeletePop}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDelete;
