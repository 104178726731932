import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { userVerification } from '../../Api/user';
import { loadUserRequest } from '../../Actions/user';
import './auth.css'

const Verification = () => {
    
   const navigate = useNavigate();
   const [state, setState] = useState(null);
   const [isVerified, setIsVerified] = useState(true)
   const searchParams = useLocation().search.split('?')[1].split('&').map(param => param.split('='));

   useEffect(() => {
    if (searchParams.length > 0) {
        const data = {};
        searchParams.forEach(param => {
            data[param[0]] = param[1].trim();
        })
        setState(data)
    }
}, [])

    useEffect(() => {
        fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const cleanData = {
            email: state.email,
            key: state.key
        }
        const apiResponse = await userVerification(cleanData);
        if(apiResponse){
            setIsVerified(true)
        }else{
            setIsVerified(false)
        }
    }, [state])
  
   const loginHandler = () =>{
    if(isVerified===true){
       navigate('/login')
    }
   }

    return (
        <div className='auth-container'>
            <div className='auth-background'></div>
            <div className='auth-wrapper'>
                <section>
                    <h1>Get Solutions to your Problems!</h1>
                    <Link to="">Get Started anyway</Link>
                </section>
                <section>
                    {isVerified ?
                    <div className='auth-box'>
                        <div className='auth-content'>
                            <h2>Your account has been verified</h2>
                            <p>Please login to your account</p>
                            <button onClick={loginHandler}>Login</button>
                        </div>
                    </div>
                    :
                    <div className='auth-box'>
                        <div className='auth-content'>
                           <h2>Some Error occured</h2>
                           <p>Please try again after some time</p>
                        </div>
                    </div>
                    }
                </section>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    loadUser: loadUserRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(Verification);