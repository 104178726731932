import React, { useState, useEffect, useRef, useCallback } from 'react';
import './accordion.css'

const Accordion = ({ label, content }) => {
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0);
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(!open)
    }, [open])

    useEffect(() => {
        setContentHeight(contentRef.current?.scrollHeight + 16)
    }, [])

    return (
        <div className={open ? 'accordion active' : 'accordion'}>
            <div className='label' onClick={handleOpen}>
                <p>{label}</p>
            </div>
            <div ref={contentRef} className='content' style={open ? { height: `${contentHeight}px` } : { height: "0px" }}>
                <p>{content}</p>
            </div>
        </div>
    )
}

export default Accordion