import React,{useState ,useCallback , useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { loadAdminRequest } from '../../../Actions/admin';
import { activateDeactivateFinancialPlansCategory, fetchFinancialPlans, fetchFinancialPlansCategories } from '../../../Api/financialPlans';
import AddCategory from '../../../Components/Popup/Admins/AddCategory';
import EditCategory from '../../../Components/Popup/Admins/EditCategory';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { GoSearch } from 'react-icons/go';
import useLocalStorage from '../../../utils/useLocalStorage';

const FinancialPlanCategory = () => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        request_type: 0,
        offset: 0,
        search: ''
    });

    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState('');
    const [financial_plans_categories, setFinancialPlanCategories] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1);
    const [search, setSearch] = useState("");

    const addModalHandler = () => {
        setAddModal(!addModal)
    }

    const editModalHandler = (data) => {
        setEditData(data)
        setEditModal(!editModal)
    }

    useEffect(() => {
            fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchFinancialPlansCategories(state);
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setFinancialPlanCategories([...apiResponse.financial_plans_categories]);
                if (apiResponse.total_financial_plans_categories > 0) {
                    if (apiResponse.total_financial_plans_categories % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_financial_plans_categories / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_financial_plans_categories / 10))
                    }
                }
            }
        } else {
            setFinancialPlanCategories([]);
            setTotalPages(0)
        }
    }, [state])


    const activateDeactivateHandler = async (data) => {
        let status = 0;
        if (data.is_active === 0) {
            status = 1;
        }
        const apiResponse = await activateDeactivateFinancialPlansCategory(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                fetchData();
            }
        }
    }

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, [])

    const requestTypeHandler = useCallback((e) => {
        setState({
            ...state,
            request_type: e.target.value
        })
    }, [state])

    const searchHandler = useCallback((e) => {
        setSearch(e.target.value)
    }, [search])

    const submitSearchHandler = useCallback(() => {
        setState({
            ...state,
            offset: 0,
            search: search
        })
    }, [search, state])
   
    return (
        <>
        {addModal && <AddCategory fetchData={fetchData} hideAddModal={addModalHandler}/> }
        {editModal && <EditCategory fetchData={fetchData} data={editData} hideEditModal={editModalHandler}/> }
       
        <div className='admin-container'>
            <div className="breadcrumbs">
                <p>Admin /Financial Services <b>/Categories</b> </p>
                <span>Categories</span> 
            </div>

            <div className='content-wrapper'>
                <div className='table-view'>
                    <div className='header'>
                        <h1>Categories</h1>
                        <button className='add-btn' onClick={addModalHandler}>Add</button>
                </div>
                <div className='table-filters'>
                    <div className='search'>
                        <input placeholder='Search...' type='text' value={search} onChange={searchHandler} />
                        <button onClick={submitSearchHandler}><GoSearch size={16} /></button>
                    </div>
                    <div className='dropdown'>
                        <label>Status:</label>
                        <select value={state.request_type} onChange={requestTypeHandler}>
                            <option value={0}>All</option>
                            <option value={1}>Active</option>
                            <option value={2}>Not active</option>
                        </select>
                    </div>
                </div>

                   { (financial_plans_categories && financial_plans_categories.length > 0) && (
                        <>
                    <div className='table'>
                         <table>
                             <thead>
                                 <tr>
                                     <th>Sr. No.</th>
                                     <th>Name</th>
                                     <th>Date Added</th>
                                     <th>Date Modified</th>
                                     <th>Status</th>
                                     <th>Actions</th>
                                 </tr>
                             </thead>
                             <tbody>
                                   {financial_plans_categories.map((item, index) => (
                                             <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td className='text-capitalize'>{item.name}</td>
                                                <td>{item.created_at}</td>
                                                <td>{item.updated_at}</td>
                                                <td>
                                                    <span className={item.is_active === 1 ? 'active' : 'deactive'} >
                                                        {item.is_active === 1 ? 'Active' : 'Not Active'}
                                                    </span>
                                                </td>
                                                <td>
                                                    <div className='table-actions'>
                                                        <button className={item.is_active === 0 ? 'activate-btn' : 'deactivate-btn'} onClick={() => activateDeactivateHandler(item)}>
                                                           {item.is_active === 0 ? "Activate" : "Deactivate"}
                                                         </button>
                                                         {item.is_active === 1 ?<button className='edit-btn' onClick={() => editModalHandler({ id: item.id, name: item.name, description: item.description })}>
                                                            Edit
                                                         </button>
                                                         :<></>}
                                                     </div>
                                                 </td>
                                             </tr> 
                                    ))}
                             </tbody>
                         </table>
                    </div>
                    {(totalPages && totalPages > 1) ? 
                    <div className='admin-pagination'>
                        <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                            <AiFillCaretLeft size={16} />
                        </button>
                        <span>{`Page ${currentPage} / ${totalPages}`}</span>
                        <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                            <AiFillCaretRight size={16} />
                        </button>
                    </div>
                    :
                    <></>
                    }
                </>
                )
                }
               </div>
            </div>
        </div>
    </>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout: loadAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(FinancialPlanCategory)