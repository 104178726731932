import api from "../utils/api";
import { toast } from "react-toastify";


export const addProperty = async (data) => {
    const apiResponse = await api.multipartApi('property/addProperty', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const editProperty = async (id, data) => {
    const apiResponse = await api.multipartApi(`property/editProperty/${id}`, 'PUT', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}

export const fetchPropertyAddEditData = async (data) => {
    const apiResponse = await api.applicationApi('property/fetchPropertyAddEditData', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const fetchProperties = async (data) => {
    const apiResponse = await api.applicationApi('property/fetchProperties', 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const activateDeactivateProperty = async (id, data) => {
    const apiResponse = await api.applicationApi(`property/activateDeactivateProperty/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const setTopProperty = async (id, data) => {
    const apiResponse = await api.applicationApi(`property/setTopProperty/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const showTopProperties = async () => {
    const apiResponse = await api.applicationApi('users/showTopProperties', 'POST', null, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const showProperties = async (data) => {
    const apiResponse = await api.applicationApi('users/showProperties', 'POST', data, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const showProperty = async (id) => {
    const apiResponse = await api.applicationApi(`users/showProperty/${id}`, 'POST', null, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}


export const propertyApproval = async (id, data) => {
    const apiResponse = await api.applicationApi(`property/propertyApproval/${id}`, 'PATCH', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else {
            toast.error(apiResponse.data.message);
            if (apiResponse.status === 401) {
                return 401;
            }
            return false;
        }
    } else {
        return false;
    }
}

export const fetchPropertyById = async (id) => {
    const apiResponse = await api.applicationApi(`property/fetchProperty/${id}`, 'POST', null, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const fetchPropertiesSearchData = async () => {
    const apiResponse = await api.applicationApi(`users/fetchPropertiesSearchData`, 'POST', null, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            return false;
        }
    } else {
        return false;
    }
}