import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../../../utils/useLocalStorage';
import { editServiceProvider, fetchServiceProviderDetails } from '../../../Api/serviceProvider';
import { loadServiceProviderRequest, logoutServiceProviderRequest } from '../../../Actions/serviceProvider';
import ServiceProviderChangePassword from '../../../Components/Popup/ServiceProvider/ServiceProviderChangePassword';

const ServiceProviderProfile = ({ logout, serviceProvider, loadServiceProvider }) => {

  const navigate = useNavigate();
  const [passwordModal, setPasswordModal] = useState(false);
  const [form, setForm] = useState(null);
  const [error, setError] = useState(false);

  const passwordModalHandler = () => {
    setPasswordModal(!passwordModal)
  }

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }, [form])

  const uploadThumbnail = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      setForm({
        ...form,
        image: e.target.files[0],
        imageSrc: URL.createObjectURL(e.target.files[0])
      })
    }
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      first_name: form.first_name.toLowerCase().trim(),
      last_name: form.last_name.toLowerCase().trim(),
      phone: form.phone.trim(),
      image: form.image
    }
    if (cleanData.first_name && cleanData.last_name && cleanData.phone && cleanData.image) {
      if (cleanData.phone.length !== 10) {
        setError('Please enter a valid mobile number')
      } else {
        const formData = new FormData();

        formData.append('first_name', cleanData.first_name);
        formData.append('last_name', cleanData.last_name);
        formData.append('phone', cleanData.phone);
        formData.append('image', cleanData.image);


        let response = await editServiceProvider(cleanData);
        if (response) {
          if (response === 401) {
            useLocalStorage.removeServiceProvider();
            logout();
          } else {
            response = await fetchServiceProviderDetails();
            if (response) {
              if (response === 401) {
                useLocalStorage.removeServiceProvider();
                logout();
              } else {
                loadServiceProvider(response)
              }
            }
          }
        } else {
          setForm({ form })
        }
      }
    } else {
      setError('Please fill all mandatory fields');
      setForm({ cleanData })
    }
  }, [form, logout])

  useEffect(() => {
    if (serviceProvider.isAuthenticated && serviceProvider.data) {
      setForm({
        ...serviceProvider.data,
        imageSrc: serviceProvider.data.image ? `https://nriconnect.io/realEstate/Uploads/${serviceProvider.data.image}` : "",
      })
    }
  }, [serviceProvider])

  return (
    <>
      {passwordModal && <ServiceProviderChangePassword hidePasswordModal={passwordModalHandler} />}
      {(serviceProvider.isAuthenticated && form) && (

        <div className='admin-container'>
          <div className="breadcrumbs">
            <p>ServiceProvider <b>/ Profile</b></p>
            <span>Profile</span>
          </div>
          <div className='content-wrapper'>
            <div className='profile'>
              <div className="info" key={form.id}>
                <div>
                  {serviceProvider.data.image && (
                    <img src={`https://nriconnect.io/realEstate/Uploads/${serviceProvider.data.image}`} alt="" />
                  )}
                </div>
                <h5>
                  {serviceProvider.data.first_name + " " + serviceProvider.data.last_name}
                </h5>
                <p>
                  {serviceProvider.data.email}
                </p>
                <p className="phone">+91 {serviceProvider.data.phone}</p>
                <button className='create' onClick={passwordModalHandler}>
                  Change Password
                </button>
              </div>
              <div className='profile-edit'>
                <form onSubmit={submitHandler}>
                  <div className='image-box'>
                    <div className='image-wrapper'>
                      {form.imageSrc && (<img src={form.imageSrc} alt='' />)}
                    </div>
                    <div className='image-box-actions'>
                      <label htmlFor='image'>Upload</label>
                      <input onChange={uploadThumbnail} name="image" id='image' type='file' />
                    </div><span className='mandatory-field'>&#42;</span>
                  </div>
                  <div className='modal-input-grid'>
                    <div className='modal-input-box'>
                      <label>First Name<span className='mandatory-field'>&#42;</span></label>
                      <input name="first_name" value={form.first_name} onChange={changeHandler} required />
                    </div>
                    <div className='modal-input-box'>
                      <label>Last Name<span className='mandatory-field'>&#42;</span></label>
                      <input name="last_name" value={form.last_name} onChange={changeHandler} required />
                    </div>
                    <div className='modal-input-box'>
                      <label>Phone<span className='mandatory-field'>&#42;</span></label>
                      <input type="number" name="phone" value={form.phone} onChange={changeHandler} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
                    </div>
                  </div>
                  {error && (<p className='error-message'>{error}</p>)}
                  <div className='action-box'>
                    <button className='create'>
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  serviceProvider: state.serviceProvider
})

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutServiceProviderRequest,
  loadServiceProvider: loadServiceProviderRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderProfile)
