import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./user.css";

const TitleDeed = ({ id, hideConfirmBox }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="modal-container">
      <div className="modal-popup">
        <div className="modal-content">
          <div className="action">
            <h3>Title Deed</h3>
            <div className="cancel-btn" onClick={hideConfirmBox}></div>
          </div>
          <p>
            Title deeds are real estate documents used in India and other
            countries. It convey ownership of properties to parties purchasing
            them from the owners. Title deeds must be completed correctly and
            provide all the necessary information about the purchased property.
          </p>
          <p>
            Title deeds provide a lot of information about a property - who owns
            it, what rights or privileges that owner has, and how much it is
            worth. The country clerk-office creates it and can be found online
            or at government offices.
          </p>
          <ul>
            <li>
              This is a proof that you are the owner of the property that is
              being listed.
            </li>
            <li>This is a mandatory document for posting.</li>
            <li>
              User must own this, NRI connect cannot provide any assistance in
              obtaining this.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TitleDeed;
