import React from "react";
import styles from "./Properlist.module.css";
import { BiChevronDown } from "react-icons/bi";
import Card from "./card/card";
import { IoAddOutline } from "react-icons/io5";
import Search from "../../../../Assets/Images/search.png";
import Addphotos from "./addphoto/Addphotos";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import useLocalStorage from "../../../../utils/useLocalStorage";
import { Link, useNavigate } from "react-router-dom";
import { fetchPropertylist } from "../../../../Api/user";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import { toast } from "react-toastify";

const Propertylist = () => {
  const navigate = useNavigate();

  const [addImage, setAddImage] = useState(false)
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [state, setState] = useState({ offset: 0, limit: 10, search: "", property_type: 0, request_type: 0 });
  const [request_type, setRequest_type] = useState("all");

  const handleAddImage = useCallback((val) => {
    setAddImage(!addImage);
    setEdit(val)
  }, [addImage])
  // {"offset": 1, "limit" : 10,"search":"name/city/state/country","property_type":2,"request_type":0 --> all, 1--> active, 2 --> not active, 3 --> approved, 4 --> not approved, 5 --> top properties 6 -> not assign to any admin}
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    // setSearch(value);
    if (value.length === 0) {
      setState({ ...state, search: "" })
    } else {
      setState({ ...state, search: value })
    }
  }, [state.search])
  const fetchData = useCallback(async () => {
    setLoading(true)
    const apiResponse = await fetchPropertylist(state);
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeUser();
        navigate('/login')
      } else {
        setLoading(false)
        setData({ ...apiResponse, images: apiResponse?.images?.length > 0 ? apiResponse.images : [] });
      }
    } else {
      // setPropertyServices([]);
      // setTotalPages(0);
    }
  }, [state, data])
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData();
    }, 500)
    return () => {
      clearTimeout(timer);
    }
  }, [state])

  return (
    <div className={styles.container}>
      <label className={styles.navigator_link}>Account &gt; My Property</label>
      {addImage ?
        <Addphotos fetchData={fetchData} handleAddImage={handleAddImage} setAddImage={setAddImage} data={edit && edit} setEdit={setEdit} />
        :
        <>
          <section style={{ display: 'grid' }}>
            <h2>My Properties Listing</h2>
            <section className={styles.list_header}>
              <div className={styles.filter_div}>
                <div className={styles.search_div}>
                  <input
                    placeholder="Search by Name or Location"
                    className={styles.search_input}
                    onChange={handleChange}
                    value={state.search}
                  />
                  <img className={styles.search_img} src={Search} alt="img" />
                </div>
                {/* this is for -- recently post property dropdown  */}
                {/* <div className={styles.select_property_type_div}>
                  <select value={request_type} className={styles.select_property_type} onChange={handleChangeSelect}>
                    <option value="all">All</option>
                    <option value="recent">Recently Posted</option>
                  </select>
                  <BiChevronDown className="down-icon" size={16} sx={{ pl: -5 }} />
                </div> */}
                {/* </div> */}
              </div>
              <Link to='/services/realestate/sellproperty'>
                <button className={styles.add_property_btn}>
                  <IoAddOutline size={19} />
                  Add Property
                </button>
              </Link>
            </section>
            {loading ? <div className="loading_page"> <LoadingBar /></div> :
              data && data?.properties?.length === 0 ? <div className={styles.no_data}>No Property Added</div> :
                <Card fetchData={fetchData} request_type={request_type} data={data && data?.properties || []} handleAddImage={handleAddImage} />}
          </section>
          <div className="faqs-enquire">
            <div className="content">
              <p>Do you have any questions?</p>
              <p>Put your email address and we will get back to you</p>
            </div>
            <div className="input">
              <input placeholder="Enter your email" />
              <button type="button">Enquire Now</button>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default Propertylist;
