import React, { useCallback, useState } from "react";
import "./viewdetails.css";
import ConfirmPage from "./ConfirmPage";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "../../../../utils/useLocalStorage";
import { addProperty } from "../../../../Api/user";

function ViewDetails() {
  const [confirm, setConfirm] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { formData } = location.state;

  const submitHandler = useCallback(async (e) => {
    console.log("submit");
    const cleanData = {
      address: formData.address.trim(),
      name: formData.name ? formData.name : "",
      city: formData.city,
      country: formData.country,
      state: formData.state,
      zipcode: formData.zipcode,
      property_type: formData.property_type,
      area: formData.area + " " + formData.area_unit.trim(),
      contact_number: formData.contact_number.trim(),
      contact_email: formData.contact_email,
      contact_name: formData.contact_name.trim(),
      token_amount: formData.token_amount,
      title_deed: formData.title_deed,
      three_months_mend: formData.three_months_mend,
      confidentiality_doc: formData.confidentiality_doc,
      fard: formData.fard,
      price: formData.price, // add currency after discussion
    };
    if (
      cleanData.address &&
      cleanData.city &&
      cleanData.country &&
      cleanData.state &&
      cleanData.zipcode &&
      cleanData.property_type &&
      cleanData.area &&
      cleanData.contact_number &&
      cleanData.contact_email &&
      cleanData.contact_name &&
      cleanData.title_deed &&
      // cleanData.price &&
      cleanData.three_months_mend &&
      cleanData.confidentiality_doc
      // cleanData.token_amount
    ) {
      const response = await addProperty(cleanData);
      if (response) {
        if (response === 401) {
          useLocalStorage.removeUser();
          navigate("/login");
        }
        setConfirm(true);
      }
    }
  }, []);
  return (
    <div className="container">
      <div className="wrapper sell-property">
        {confirm ? (
          <ConfirmPage />
        ) : (
          <div className="detail-box">
            <div className="header">
              <p>
                Ready ! <span>To sell</span> your property
              </p>
              <p>We ensure 100% trust and zero hassle selling experience</p>
            </div>
            <div className="details">
              <p>Confirm Property Details</p>
              <div className="all-data">
                <p>Personal Details</p>
                <p>
                  {formData.contact_name} , {formData.contact_email},{" "}
                  {formData.contact_number}
                </p>
              </div>
              <div className="all-data">
                <p>Property Location Details</p>
                <p>
                  {formData.address}, {formData.city}, {formData.state},{" "}
                  {formData.country}
                </p>
              </div>
              <div className="all-data">
                <p>Property & Area Details</p>
                <p>
                  {formData.property_type_name},{" "}
                  {formData.area + " " + formData.area_unit}
                </p>
              </div>
              <div className="all-data">
                <p>Price Details</p>
                <p>
                  Estimated Price: {formData.currency + " " + formData.price}{" "}
                  ,Token Amount:{" "}
                  {formData.currency + " " + formData.token_amount}{" "}
                </p>
              </div>
              <div className="all-data">
                <p>Uploaded Property Documents</p>
                <p>
                  Title Deed -{" "}
                  {formData.title_deed ? "Uploaded" : "Not uploaded"}
                </p>
                <p>Fard - {formData.fard ? "Uploaded" : "Not uploaded"} </p>
                <p>
                  3 Months Mandate -{" "}
                  {formData.three_months_mend ? "Uploaded" : "Not uploaded"}{" "}
                </p>
                <p>
                  Confidentiality document -{" "}
                  {formData.confidentiality_doc ? "Uploaded" : "Not uploaded"}
                </p>
              </div>
              <div className="btn">
                <p
                  onClick={() =>
                    navigate(`/services/realestate/sellproperty`, {
                      state: { formDatas: formData },
                    })
                  }
                >
                  Edit
                </p>
                <button type="button" onClick={submitHandler}>
                  Post Property
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewDetails;
