import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "./Search";

const Hero = () => {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const findProperty = () => {
    navigate("/services/realestate");
  };

  return (
    <div
      className={
        path.includes("/financial/planDetails")
          ? "wrapper financial-hero"
          : "wrapper financial-hero financial-hero-background"
      }
    >
      <div className="content-wrapper">
        {!path.includes("/financial/planDetails") && (
          <>
            <ul className="services-tabs">
              <li onClick={findProperty}>
                <div />
                <h3>Real Estate</h3>
              </li>
              <li>
                <div />
                <h3>Financial Services</h3>
              </li>
              {/* <li>
                        <div />
                        <h3>Medical Services</h3>
                    </li> */}
            </ul>
            <Search />
          </>
        )}
      </div>
    </div>
  );
};

export default Hero;
