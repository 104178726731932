import React, { useEffect, useCallback, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showFinancialPlan } from "../../../../Api/financialPlans";
import { BsCheck, BsWindowSidebar } from "react-icons/bs";
import { BiPencil } from "react-icons/bi";
import "./PlanDetails.css";
import { addCase } from "../../../../Api/cases";
import useLocalStorage from "../../../../utils/useLocalStorage";
import ConfirmBox from "../../../../Components/Popup/User/ConfirmBox";
import LoadingBar from "../../../../Components/LoadingBar/LoadingBar";
import Hero from "../Hero";
import { AiOutlineArrowLeft } from "react-icons/ai";

const PlanDetails = (user) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [confirmBox, showConfirmBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    const apiResponse = await showFinancialPlan(id);
    if (apiResponse) {
      setData({ ...apiResponse });
    } else {
      setData(null);
    }
  }, [data]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const additionalCharges =
    data &&
    data.addons.length > 0 &&
    data.addons.reduce((accumulator, item) => {
      return accumulator + item.price;
    }, 0);

  const paymentHandler = useCallback(async (totalAmount) => {
    setLoading(true);
    const cleanData = {
      amount: totalAmount,
      plan_id: id,
      user_id: user.user.data.id,
    };
    if (user.user.isAuthenticated && useLocalStorage.getUser()) {
      const apiResponse = await addCase(cleanData);
      if (apiResponse) {
        setLoading(false);
        window.location.replace(apiResponse.url);
      }
    } else {
      showConfirmBox(true);
    }
  });

  const confirmBoxHandler = () => {
    showConfirmBox(!confirmBox);
  };
  const goBackHandler = () => {
    navigate(-1);
  };
  return (
    <>
      {confirmBox && <ConfirmBox id={id} hideConfirmBox={confirmBoxHandler} />}
      <div className="wrapper financial-plan-details">
        <div className="content-wrapper">
          <p className="back-btn" onClick={goBackHandler}>
            <AiOutlineArrowLeft size={16} />
            <p>Back to Financial Plans</p>
          </p>
          <h2>Plan Details</h2>
          {data && (
            <div className="plan">
              <section>
                {data.plan && (
                  <div className="plan-card details">
                    <h3>{data.plan.name}</h3>
                    {data.pricings && data.pricings.length > 0 ? (
                      <div className="pricings">
                        <p>Select Money Transfer Range to see the cost</p>
                        <ul>
                          {data.pricings.map((item) => (
                            <li>
                              <input
                                name="pricings"
                                value={item.id}
                                id="pricings"
                                type="radio"
                              />
                              <span>{item.name}</span>
                              <span>${item.price}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <span className="price">${data.plan.price}</span>
                    )}
                    <p className="desc">{data.plan.description}</p>
                    <ul className="benefits">
                      {data.plan.benefits.split("\n").map((item) => {
                        if (item) {
                          return (
                            <li>
                              <BsCheck />
                              <p>{item}</p>
                            </li>
                          );
                        }
                      })}
                    </ul>
                    {data.addons && data.addons.length > 0 ? (
                      <div className="addons">
                        <p>
                          <input type="checkbox" checked />
                          Additional Chargeable Services
                        </p>
                        <ul>
                          {data.addons.map((item) => (
                            <li>
                              <input
                                name="addons"
                                checked
                                value={item.id}
                                type="checkbox"
                              />
                              <p>{item.description}</p>
                              <span>${item.price}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p className="addons-desc">
                        No Additional Chargeable Services
                      </p>
                    )}
                  </div>
                )}
                {data.plan.detailed_plan && (
                  <div className="plan-card documents">
                    <h3>Detailed Description</h3>
                    <p>{data.plan.detailed_plan}</p>
                  </div>
                )}
                {data.plan.docs && (
                  <div className="plan-card documents">
                    <h3>
                      Documents Required <BiPencil size={20} />
                    </h3>
                    <ol>
                      {data.plan.docs.split(",").map((item) => (
                        <li>{item.trim()}</li>
                      ))}
                    </ol>
                  </div>
                )}
              </section>
              <section>
                {data.plan && (
                  <div className="plan-card payment">
                    <h3>Payment Summary</h3>
                    <div className="details">
                      <p>
                        <span>{data.plan.name} Plan</span>
                        <span>${data.plan.price}</span>
                      </p>
                      {data.addons && data.addons.length > 0 && (
                        <p>
                          <span>Additional Services</span>
                          <span>${additionalCharges}</span>
                        </p>
                      )}
                    </div>
                    {data.addons && data.addons.length > 0 ? (
                      <p className="total">
                        <span>Total Amount</span>
                        <span>${data.plan.price + additionalCharges}</span>
                      </p>
                    ) : (
                      <p className="total">
                        <span>Total Amount</span>
                        <span>${data.plan.price}</span>
                      </p>
                    )}
                    <p className="terms">Terms and Condition Apply*</p>
                    {loading === true ? (
                      <button>
                        <LoadingBar />{" "}
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          paymentHandler(data.plan.price + additionalCharges)
                        }
                      >
                        Proceed for Payment
                      </button>
                    )}
                  </div>
                )}
              </section>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(PlanDetails);
