import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import './footer.css';

const Footer = () => {
    const path = useLocation().pathname;
    const [show, setShow] = useState(false)
    useEffect(() => {
        if (path.includes('admin') || path.includes('serviceProvider') || path.includes('login') || path.includes('signup') || path.includes('forgotPassword')) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [path])
    return (
        <>
            {
                show && (
                    <footer className='footer'>
                        <section>
                            <ul>
                                <li>
                                    <Link to="/faqs/general">FAQs</Link>
                                </li>
                                <li>
                                    <Link to="/termsAndConditions">Terms and Conditions</Link>
                                </li>
                                <li>
                                    <Link to="/privacyPolicy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/aboutUs">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/contactUs">Contact</Link>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <p>All rights reserved</p>
                            <div>
                                <p>Follow us on</p>
                                <ul>
                                    <li>
                                        <Link to="">
                                            <span>
                                                <FaTwitter />
                                            </span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="">
                                            <span>
                                                <FaLinkedinIn />
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </footer>
                )
            }
        </>
    )
}

export default Footer