import React, { useCallback, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { editServiceProviderById } from '../../../Api/serviceProvider';
import { useNavigate } from 'react-router-dom';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLocalStorage from '../../../utils/useLocalStorage';

const EditServiceProvider = ({ data, fetchData, hideEditModal, logout }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false)
  const [form, setForm] = useState({ ...data })

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }, [form]);

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
      first_name: form.first_name.trim(),
      last_name: form.last_name.trim(),
      email: form.email.trim(),
      phone: form.phone.trim(),
      admin_id: form.admin_id
    }
    if (cleanData.first_name && cleanData.last_name && cleanData.email && cleanData.phone) {
      if (cleanData.phone.length !== 10) {
        setError('Please enter a valid mobile number')
      } else {
        const response = await editServiceProviderById(data.id, cleanData);
        if (response) {
          if (response === 401) {
            useLocalStorage.removeAdmin();
            logout();
            navigate('/admin/login')
          } else {
            setForm({
              id: "",
              first_name: "",
              last_name: "",
              phone: "",
              admin_id: 0
            })
          }
          hideEditModal();
          fetchData();
        } else {
          setForm({
            ...cleanData,
            id: form.id,
          })
        }
      }
    } else {
      setError('Please fill all mandatory fields')
    }
  }, [form, hideEditModal, logout])

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Edit Service Provider</h3>
          <button className='close-btn' onClick={hideEditModal}>
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>First Name<span className='mandatory-field'>&#42;</span></label>
                <input name="first_name" value={form.first_name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Last Name<span className='mandatory-field'>&#42;</span></label>
                <input name="last_name" value={form.last_name} onChange={changeHandler} required />
              </div>
              <div className='modal-input-box'>
                <label>Email<span className='mandatory-field'>&#42;</span></label>
                <input name="email" value={form.email} type='email' required />
              </div>
              <div className='modal-input-box'>
                <label>Phone<span className='mandatory-field'>&#42;</span></label>
                <input type="number" name="phone" value={form.phone} onChange={changeHandler} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
              </div>
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button className='create'>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}


const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(EditServiceProvider)