import React from "react";

const Partners = () => {
  return (
    <div className="wrapper wrapper-border-top landing-partners">
      <div className="wrapper-header">Our Partners</div>
      <div className="content-wrapper">
        <ul>
          <li />
          {/* <li /> */}
          {/* <li /> */}
          {/* <li /> */}
          <li />
          <li />
          <li />
        </ul>
      </div>
    </div>
  );
};

export default Partners;
