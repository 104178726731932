import { ACTIVATE_SIDEBAR, DEACTIVATE_SIDEBAR } from "../utils/actionTypes"

const sidebar = (state = false, action) => {
    switch (action.type) {
        case ACTIVATE_SIDEBAR: {
            return true;
        }
        case DEACTIVATE_SIDEBAR: {
            return false;
        }
        default: {
            return state;
        }
    }
}

export default sidebar;