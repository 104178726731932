import api from "../utils/api";
import { toast } from "react-toastify";

export const bookAppointment = async (data) => {
    const apiResponse = await api.applicationApi('users/bookAppointment', 'POST', data, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return apiResponse.data.data;
        } else {
            toast.error(apiResponse.data.message);
            return false;
        }
    } else {
        return false;
    }
}

export const editAppointment = async (id, data) => {
    const apiResponse = await api.applicationApi(`users/editAppointment/${id}`, 'PUT', data, 0);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}

export const fetchAppointments = async (data) => {
    const apiResponse = await api.applicationApi(`admins/fetchAppointments`, 'POST', data, 1);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            return apiResponse.data.data;
        } else if (apiResponse.status === 401) {
            return 401;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const completeAppointment = async (id, userType) => {
    const apiResponse = await api.applicationApi(`users/completeAppointment/${id}`, 'PATCH', null, userType);
    if (apiResponse) {
        if (apiResponse.status === 200) {
            toast.success(apiResponse.data.message)
            return true;
        } else if (apiResponse.status === 401) {
            toast.error(apiResponse.data.message)
            return 401;
        } else {
            toast.error(apiResponse.data.message)
            return false;
        }
    } else {
        return false;
    }
}