import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import useLocalStorage from "./utils/useLocalStorage";
import Login from "./Pages/Auth/Login";
import Signup from "./Pages/Auth/Signup";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import Landing from "./Pages/Landing/Landing";
import Financial from "./Pages/Services/Financial/Financial";
import RealEstate from "./Pages/Services/RealEstate/RealEstate";
import Dashboard from "./Pages/Admin/Dashboard/Dashboard";
import Admins from "./Pages/Admin/Admins/Admins";
import ServiceProviders from "./Pages/Admin/ServiceProviders/ServiceProviders";
import Users from "./Pages/Admin/Users/Users";
import Filters from "./Pages/Admin/Filters/Filters";
import Properties from "./Pages/Admin/Properties/Properties";
import AdminLogin from "./Pages/Admin/AdminLogin/AdminLogin";
import SuperAdmin from "./Pages/Admin/SuperAdmin/SuperAdmin";
import PropertyType from "./Pages/Admin/PropertyType/PropertyType";
import PropertyServices from "./Pages/Admin/PropertyServices/PropertyServices";
import Builders from "./Pages/Admin/Builders/Builder";
import FinancialPlanCategory from "./Pages/Admin/FinancialPlan/FinancialPlanCategory";
import FinancialPlan from "./Pages/Admin/FinancialPlan/FinancialPlan";
import FinancialPlanAddOn from "./Pages/Admin/FinancialPlan/FinancialPlanAddOn";
import FinancialPlanPricings from "./Pages/Admin/FinancialPlan/FinancialPlanPricings";
import Appointments from "./Pages/Admin/Appointments/Appointments";
import Schedules from "./Pages/Admin/Schedules/Schedules";
import AdminProfile from "./Pages/Admin/AdminProfile/AdminProfile";
import Testimonials from "./Pages/Admin/Testimonials/Testimonials";
import Faqs from "./Pages/Admin/Faqs/Faqs";
import ServiceProviderLogin from "./Pages/ServiceProvider/ServiceProviderLogin/ServiceProviderLogin";
import ServiceProviderProperties from "./Pages/ServiceProvider/ServiceProviderProperties/ServiceProviderProperties";
import ServiceProviderProfile from "./Pages/ServiceProvider/ServiceProviderProfile/ServiceProviderProfile";
import UserProfile from "./Pages/User/UserProfile/UserProfile";
import Cases from "./Pages/Admin/Cases/Cases";
import AdminForgotPassword from "./Pages/Admin/AdminFogotPassword/AdminForgotPassword";
import ServiceProviderForgotPassword from "./Pages/ServiceProvider/ServiceProviderForgotPassword/ServiceProviderForgotPassword";
import PropertyDetails from "./Pages/Admin/Properties/PropertyDetails";
import UserDetails from "./Pages/Admin/Users/UserDetails";
import ServiceProviderVerification from "./Pages/ServiceProvider/ServiceProviderVerification/ServiceProviderVerification";
import Verification from "./Pages/Auth/Verification";
import TermsConditons from "./Pages/TermsConditions/TermsConditons";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "./Pages/AboutUs/AboutUs";
import UserFaqs from "./Pages/UserFaqs/UserFaqs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import GeneralEnquiries from "./Pages/Admin/GeneralEnquiries/GeneralEnquiries";
import RealEstateEnquiries from "./Pages/Admin/RealEstateEnquiries/RealEstateEnquiries";
import Success from "./Pages/Services/Financial/PlanDetail/Success";
import CustomPayments from "./Pages/Admin/CustomPayments/CustomPayments";
import Transactions from "./Pages/Admin/Transactions/Transactions";
import ReadyPage from "./Pages/Services/RealEstate/Sell Property/ReadyPage";
import PropertyDetail from "./Pages/Services/RealEstate/Sell Property/SellPropertyDetail";
import SellPropertyDetail from "./Pages/Services/RealEstate/Sell Property/SellPropertyDetail";
import ViewDetails from "./Pages/Services/RealEstate/Sell Property/ViewDetails";
import Propertylist from "./Pages/User/viewProperies/properlist/Propertylist";
import Propertydetail from "./Pages/User/viewProperies/propertydetail/Propertydetail";
import ServiceProviderPropertyDetails from "./Pages/ServiceProvider/ServiceProviderProperties/ServiceProviderPropertyDetails";
import ServiceProviderUserProperties from "./Pages/ServiceProvider/ServiceProviderUserProperties/ServiceProviderUserProperties";
import ServiceProviderUserPropertyDetail from "./Pages/ServiceProvider/ServiceProviderUserProperties/ServiceProviderUserPropertyDetail";
import PriceConsultation from "./Pages/Admin/PriceConsultation/PriceConsultation";
import EditUserproperty from "./Pages/User/viewProperies/properlist/editProperty/EditUserproperty";

const AllRoutes = ({ admin, serviceProvider, user }) => {
  const location = useLocation().pathname;

  useEffect(() => {
    useLocalStorage.setBacktrackUrl(location);
    window.scroll(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/forgotPassword" element={<ForgotPassword />} />
      <Route exact path="/users/verification" element={<Verification />} />
      <Route exact path="/termsAndConditions" element={<TermsConditons />} />
      <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
      <Route exact path="/aboutUs" element={<AboutUs />} />
      <Route exact path="/contactUs" element={<ContactUs />} />
      <Route exact path="/faqs/:id" element={<UserFaqs />} />
      <Route exact path="/faqs/plan/:id" element={<UserFaqs />} />
      <Route exact path="/propertylists" element={<Propertylist />} />

      {(user.isAuthenticated || useLocalStorage.getUser()) && (
        <>
          <Route exact path="/profile" element={<UserProfile />} />
          {/* NITIN WORK THESE ROUTES */}

          <Route exact path="/propertylists" element={<Propertylist />} />
          <Route exact path="/propertylists/:id" element={<Propertydetail />} />
          <Route exact path="/editproperties/:id" element={<EditUserproperty />} />
        </>
      )}
      <Route exact path="/services/realestate" element={<RealEstate />} />
      <Route
        exact
        path="/services/realestate/properties"
        element={<RealEstate />}
      />
      <Route
        exact
        path="/services/realestate/propertyDetails/:id"
        element={<RealEstate />}
      />
      <Route exact path="/services/financial" element={<Financial />} />
      <Route
        exact
        path="/services/financial/planDetails/:id"
        element={<Financial />}
      />
      <Route exact path="/services/financial/success" element={<Success />} />
      {/* chaitanya work */}
      <Route
        exact
        path="/services/realestate/sellproperty"
        element={<ReadyPage />}
      />
      {/* <Route
        exact
        path="/services/realestate/sellproperty/propertyDetail"
        element={<SellPropertyDetail />}
      /> */}
      <Route
        exact
        path="/services/realestate/sellproperty/confirm"
        element={<ViewDetails />}
      />
      {/* admin routes */}
      <Route
        exact
        path="/admin"
        element={
          admin.isAuthenticated || useLocalStorage.getAdmin() ? (
            <Navigate replace to="/admin/dashboard" />
          ) : (
            <Navigate replace to="/admin/login" />
          )
        }
      />
      <Route
        exact
        path="/admin/*"
        element={
          admin.isAuthenticated || useLocalStorage.getAdmin() ? (
            <Navigate
              replace
              to={
                useLocalStorage.getBacktrackUrl() && location.includes("admin")
                  ? `${useLocalStorage.getBacktrackUrl()}`
                  : "/admin/dashboard"
              }
            />
          ) : (
            <Navigate replace to="/admin/login" />
          )
        }
      />
      <Route exact path="/admin/login" element={<AdminLogin />} />
      <Route
        exact
        path="/admin/forgotPassword"
        element={<AdminForgotPassword />}
      />
      {(admin.isAuthenticated || useLocalStorage.getAdmin()) && (
        <>
          {admin.data.role === 1 && (
            <Route exact path="/admin/superAdmin" element={<SuperAdmin />} />
          )}
          {(admin.data.role === 1 || admin.data.role === 2) && (
            <>
              <Route exact path="/admin/admins" element={<Admins />} />
              <Route
                exact
                path="/admin/serviceProviders"
                element={<ServiceProviders />}
              />
              <Route exact path="/admin/builders" element={<Builders />} />
              <Route
                exact
                path="/admin/propertyTypes"
                element={<PropertyType />}
              />
              <Route
                exact
                path="/admin/propertyServices"
                element={<PropertyServices />}
              />
              <Route
                exact
                path="/admin/financial/plans"
                element={<FinancialPlan />}
              />
              <Route
                exact
                path="/admin/financial/categories"
                element={<FinancialPlanCategory />}
              />
              <Route
                exact
                path="/admin/financial/addons"
                element={<FinancialPlanAddOn />}
              />
              <Route
                exact
                path="/admin/financial/pricings"
                element={<FinancialPlanPricings />}
              />
              <Route exact path="/admin/schedules" element={<Schedules />} />
              <Route exact path="/admin/filters" element={<Filters />} />
              <Route exact path="/admin/cases" element={<Cases />} />
              <Route
                exact
                path="/admin/customPayments"
                element={<CustomPayments />}
              />
              <Route
                exact
                path="/admin/transactions"
                element={<Transactions />}
              />
              <Route
                exact
                path="/admin/testimonials"
                element={<Testimonials />}
              />
              <Route exact path="/admin/faqs" element={<Faqs />} />
              <Route
                exact
                path="/admin/generalEnquiries"
                element={<GeneralEnquiries />}
              />
            </>
          )}
          {(admin.data.role === 1 ||
            admin.data.role === 2 ||
            (admin.data.role === 3 &&
              admin.data.service_ids.split(",").indexOf("1") !== -1)) && (
              <>
                <Route exact path="/admin/properties" element={<Properties />} />
                <Route
                  exact
                  path="/admin/properties/propertyDetails/:id"
                  element={<PropertyDetails />}
                />
                <Route
                  exact
                  path="/admin/appointments"
                  element={<Appointments />}
                />
                <Route
                  exact
                  path="/admin/realestateEnquiries"
                  element={<RealEstateEnquiries />}
                />
                <Route
                  exact
                  path="/admin/priceconsultation"
                  element={<PriceConsultation />}
                />
              </>
            )}
          <Route exact path="/admin/dashboard" element={<Dashboard />} />
          <Route exact path="/admin/users" element={<Users />} />
          <Route
            exact
            path="/admin/users/userDetails/:id"
            element={<UserDetails />}
          />
          <Route exact path="/admin/profile" element={<AdminProfile />} />
        </>
      )}
      {/* service provider routes */}
      <Route
        exact
        path="/serviceProvider"
        element={
          serviceProvider.isAuthenticated &&
            useLocalStorage.getServiceProvider() ? (
            <Navigate replace to="/serviceProvider/properties" />
          ) : (
            <Navigate replace to="/serviceProvider/login" />
          )
        }
      />
      <Route
        exact
        path="/serviceProvider/*"
        element={
          serviceProvider.isAuthenticated &&
            useLocalStorage.getServiceProvider() ? (
            <Navigate replace to="/serviceProvider/properties" />
          ) : (
            <Navigate replace to="/serviceProvider/login" />
          )
        }
      />
      <Route
        exact
        path="/serviceProvider/login"
        element={<ServiceProviderLogin />}
      />

      <Route
        exact
        path="/serviceProvider/forgotPassword"
        element={<ServiceProviderForgotPassword />}
      />
      <Route
        exact
        path="/serviceProvider/verification"
        element={<ServiceProviderVerification />}
      />
      {(serviceProvider.isAuthenticated ||
        useLocalStorage.getServiceProvider()) && (
          <>
            {serviceProvider.data.role === 4 && (
              <>
                <Route
                  exact
                  path="/serviceProvider/properties"
                  element={<ServiceProviderProperties />}
                />
                <Route
                  exact
                  path="/serviceProvider/userproperty"
                  element={<ServiceProviderUserProperties />}
                />
                <Route
                  exact
                  path="/serviceProvider/properties/:id"
                  element={<ServiceProviderPropertyDetails />}
                />
                <Route
                  exact
                  path="/serviceProvider/userproperty/:id"
                  element={<ServiceProviderUserPropertyDetail />}
                />
                <Route
                  exact
                  path="/serviceProvider/profile"
                  element={<ServiceProviderProfile />}
                />
              </>
            )}
          </>
        )}
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  serviceProvider: state.serviceProvider,
  user: state.user,
});

export default connect(mapStateToProps, null)(AllRoutes);
