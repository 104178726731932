import React, { useCallback, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './user.css'
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { userChangePassword } from '../../../Api/user';
import useLocalStorage from '../../../utils/useLocalStorage';


const ChangePassword = ({ hidePasswordModal}) => {

  const navigate = useNavigate();
  const [showOldPassword, setOldPassword] = useState(false);
  const [showPassword, setPassword] = useState(false);
  const [form, setForm] = useState({
    old_password: "",
    password: ""
})
  const [error, setError] = useState(false);

  const changeHandler = useCallback((e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      })
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    const cleanData = {
        old_password: form.old_password.trim(),
        password: form.password.trim()
    }
    if(cleanData.old_password && cleanData.password){
    if (cleanData.password.length < 8) {
        setError('password must contain atleast 8 charachters')
    }else if (cleanData.old_password === cleanData.password) {
        setError('old and new password cannot be same')
    }else {
        setError(false);
        const response = await userChangePassword(cleanData);
        if (response) {
            if (response === 401) {
              useLocalStorage.removeUser();
            } else {
              setForm({
                old_password: "",
                password: ""
              })
            }
            hidePasswordModal();
          } else {
            setForm({
              ...cleanData
            })
          }
    }
    }else{
       setError('Please fill all mandatory fields')
    }
}, [form, hidePasswordModal])

  const showPasswordHandler = useCallback(() => {
    setOldPassword(!showOldPassword)
}, [showOldPassword])

const showConfirmPasswordHandler = useCallback(() => {
    setPassword(!showPassword)
}, [showPassword])


  return (
    <div className='modal-container'>
      <div className='modal-popup'>
          <div className='header'>
            <h3>Change Password</h3>
            <button className='close-btn' onClick={hidePasswordModal}>
              <FaTimes color='#000000' size={16} />
            </button>
          </div>
          <div className='content'>
          <form onSubmit={submitHandler}>
              <div className='modal-input-box'>
                    <label>Old Password</label>
                    <input onChange={changeHandler} value={form.old_password} name='old_password' type={showOldPassword ? 'text' : 'password'} />
                    <button type='button' onClick={showPasswordHandler}>{showOldPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
              </div>
              <div className='modal-input-box'>
                    <label>New Password</label>
                    <input onChange={changeHandler} value={form.password} name='password' type={showPassword ? 'text' : 'password'} />
                    <button type='button' onClick={showConfirmPasswordHandler}>{showPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
              </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button className={(form.old_password && form.password)  ? 'active' : 'save'}>Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(ChangePassword)  