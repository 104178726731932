import React from "react";
import "./confirmpage.css";
import { useLocation, useNavigate } from "react-router-dom";

function ConfirmPage() {
  const navigate = useNavigate();
  return (
    <>
      <div className="head">
        <p>Congratulations !</p>
        <p>
          On Successfully posting your property, Your Property will be listed
          shortly !
        </p>
      </div>
      <div className="outer-box">
        <div className="box">
          <div className="image">
            <div className="load"></div>
          </div>
          <div className="info">
            <p>Your property will be approved in 24 hours by Super Admin </p>
            <p>
              This is to ensure quality of the property ads and avoid abuse of
              the platform
            </p>
            <p>You can check verification status in your account</p>
            <div className="btn">
              <button type="button" onClick={() => navigate("/propertylists")}>
                Check Properties
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmPage;
