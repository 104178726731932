import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { loadAdminRequest } from '../../../Actions/admin';
import { activateDeactivateFinancialPlanAddon, fetchFinancialPlansAddEditData, fetchFinancialPlansAddons, fetchFinancialPlansAddonsAddEditData } from '../../../Api/financialPlans';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { GoSearch } from 'react-icons/go';
import useLocalStorage from '../../../utils/useLocalStorage';
import AddPlanAddOn from '../../../Components/Popup/Admins/AddPlanAddOn';
import EditPlanAddOn from '../../../Components/Popup/Admins/EditPlanAddOn';

const FinancialPlanAddOn = () => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        request_type: 0,
        offset: 0,
        category_id: 0,
        plan_id: 0,
        search: ''
    });

    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState('');
    const [plans, setPlans] = useState([]);
    const [addons, setAddons] = useState([]);
    const [categories, setCategories] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentpage] = useState(1);
    const [search, setSearch] = useState("");
    const addModalHandler = () => {
        setAddModal(!addModal)
    }

    const editModalHandler = (data) => {
        setEditModal(!editModal)
        setEditData(data)
    }

    useEffect(() => {
        fetchData()
    }, [state])

    const fetchData = useCallback(async () => {
        const apiResponse = await fetchFinancialPlansAddons(state);
        const response = await fetchFinancialPlansAddEditData();
        if (apiResponse || response) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                setAddons([...apiResponse.financial_plans_addons]);
                setCategories([...response.financial_plans_categories])
                if (apiResponse.total_financial_plans_addons > 0) {
                    if (apiResponse.total_financial_plans_addons % 10 > 0) {
                        setTotalPages(parseInt(apiResponse.total_financial_plans_addons / 10) + 1);
                    } else {
                        setTotalPages(parseInt(apiResponse.total_financial_plans / 10))
                    }
                }
            }
        } else {
            setAddons([]);
            setTotalPages(0)
        }
    }, [state])


    const activateDeactivateHandler = async (data) => {
        let status = 0;
        if (data.is_active === 0) {
            status = 1;
        }
        const apiResponse = await activateDeactivateFinancialPlanAddon(data.id, { request_type: status });
        if (apiResponse) {
            if (apiResponse === 401) {
                useLocalStorage.removeAdmin();
                navigate('/admin/login')
            } else {
                fetchData();
            }
        }
    }

    const requestTypeHandler = useCallback((e) => {
        setState({
            ...state,
            request_type: e.target.value
        })
    }, [state])

    const searchHandler = useCallback((e) => {
        setSearch(e.target.value)
    }, [search])

    const submitSearchHandler = useCallback(() => {
        setState({
            ...state,
            offset: 0,
            search: search
        })
    }, [search, state])

    const CategoryTypeHandler = useCallback(async (e) => {
        setState({
            ...state,
            category_id: e.target.value
        })
    }, [state])

    const FinancialPlanTypeHandler = useCallback((e) => {
        setState({
            ...state,
            plan_id: e.target.value
        })
    }, [state])

    const currentPageHandler = useCallback((pageNumber) => {
        setCurrentpage(pageNumber);
        setState({
            ...state,
            offset: ((pageNumber - 1) * 10)
        })
    }, [])

    const fetchPlansData = useCallback(async () => {
        if (state.category_id !== 0) {
            const response = await fetchFinancialPlansAddonsAddEditData({ category_id: state.category_id });
            if (response) {
                if (response === 401) {
                    useLocalStorage.removeAdmin();
                    navigate('/admin/login')
                } else {
                    setPlans([...response.financial_plans]);
                }
            } else {
                setPlans([]);
            }
        }
    }, [state.category_id])

    useEffect(() => {
        fetchPlansData()
    }, [state.category_id])

    return (
        <>
            {addModal && <AddPlanAddOn fetchData={fetchData} hideAddModal={addModalHandler} />}
            {editModal && <EditPlanAddOn fetchData={fetchData} data={editData} hideEditModal={editModalHandler} />}

            <div className='admin-container'>
                <div className="breadcrumbs">
                    <p>Admin /Financial Services <b>/Plans Addons</b> </p>
                    <span>Plans Addons</span>
                </div>

                <div className='content-wrapper'>
                    <div className='table-view'>
                        <div className='header'>
                            <h1>Plans Addons</h1>
                            <button className='add-btn' onClick={addModalHandler}>Add</button>
                        </div>
                        <div className='table-filters'>
                            <div className='search'>
                                <input placeholder='Search...' type='text' value={search} onChange={searchHandler} />
                                <button onClick={submitSearchHandler}><GoSearch size={16} /></button>
                            </div>
                            <div className='dropdown'>
                                <label>Category:</label>
                                <select value={state.category_id} onChange={CategoryTypeHandler}>
                                    <option value={0}>All</option>
                                    {(categories && categories.length > 0) &&
                                        categories.map(item => (
                                            <option value={item.id}>{item.name}</option>
                                        ))}
                                </select>
                            </div>
                            <div className='dropdown'>
                                <label>Plan:</label>
                                <select value={state.plan_id} onChange={FinancialPlanTypeHandler}>
                                    <option value={0}>All</option>
                                    {(plans && plans.length > 0) &&
                                        plans.map(item => (
                                            <option value={item.id}>{item.name}</option>
                                        ))}
                                </select>
                            </div>
                            <div className='dropdown'>
                                <label>Status:</label>
                                <select value={state.request_type} onChange={requestTypeHandler}>
                                    <option value={0}>All</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Not active</option>
                                </select>
                            </div>
                        </div>

                        {(addons && addons.length > 0) && (
                            <>
                                <div className='table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Description</th>
                                                <th>Price</th>
                                                <th>Plan</th>
                                                <th>Date Added</th>
                                                <th>Date Modified</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {addons.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.price}</td>
                                                    <td className='text-capitalize'>{item.plan}</td>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.updated_at}</td>
                                                    <td>
                                                        <span className={item.is_active === 1 ? 'active' : 'deactive'} >
                                                            {item.is_active === 1 ? 'Active' : 'Not Active'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className='table-actions'>
                                                            <button className={item.is_active === 0 ? 'activate-btn' : 'deactivate-btn'} onClick={() => activateDeactivateHandler(item)}>
                                                                {item.is_active === 0 ? "Activate" : "Deactivate"}
                                                            </button>
                                                            {item.is_active === 1 ? <button className='edit-btn' onClick={() => editModalHandler({ id: item.id, name: item.name, description: item.description, benefits: item.benefits, price: item.price, category_id: item.category_id, plan_id: item.plan_id })}>
                                                                Edit
                                                            </button>
                                                                : <></>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {(totalPages && totalPages > 1) ?
                                    <div className='admin-pagination'>
                                        <button type='button' className={currentPage === 1 ? 'disabled' : ''} onClick={currentPage === 1 ? null : () => currentPageHandler(currentPage - 1)}>
                                            <AiFillCaretLeft size={16} />
                                        </button>
                                        <span>{`Page ${currentPage} / ${totalPages}`}</span>
                                        <button type='button' className={currentPage === totalPages ? 'disabled' : ''} onClick={currentPage === totalPages ? null : () => currentPageHandler(currentPage + 1)}>
                                            <AiFillCaretRight size={16} />
                                        </button>
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                        )
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout: loadAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(FinancialPlanAddOn)