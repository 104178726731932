import React from "react";
import styles from './Addphotos.module.css'
import Addicon from '../../../../../Assets/Images/addphoto.png'
import { IoAdd } from "react-icons/io5";
import { useState } from "react";
import { useCallback } from "react";
import cancel from '../../../../../Assets/Images/cancelicon.png'
import { addImages } from "../../../../../Api/user";
import useLocalStorage from "../../../../../utils/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "../../../../../Components/LoadingBar/LoadingBar";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Addphotos = ({ handleAddImage, data, setEdit, setAddImage, fetchData }) => {
    const [allimages, setAllimages] = useState([])
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSelectImage = useCallback(
        (e) => {
            // const MIN_FILE_SIZE = 50 // kb
            const MAX_FILE_SIZE = 20000// kb
            const img = e.target.files[0];
            const fileSizeKiloBytes = img.size / 1024
            if (e.target && e.target.files && e.target.files.length > 0) {
                if (allimages.length >= 15) {
                    toast.error("Can't slect more than 15 Photo")
                } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    toast.error("File size is very large")
                } else {
                    // const srcData = form.imagesSrc;
                    const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
                    setAllimages((prevImages) => prevImages.concat(filesArray));
                    if (!Array.isArray(data.new_images)) {
                        const img = []
                        for (let i = 0; i < e.target.files.length; i++) {
                            img.push(e.target.files[i]);
                        }
                        setEdit({ ...data, new_images: img })
                    } else {
                        const new_images = [...data.new_images];
                        for (let i = 0; i < e.target.files.length; i++) {
                            // srcData.push(URL.createObjectURL(e.target.files[i]));
                            new_images.push(e.target.files[i]);
                        }
                        setEdit({ ...data, new_images: new_images })
                    }

                }
            }
        },
        [allimages]
    );
    const handleRemoveImage = (e) => {
        const id = e.target.id
        const image = [...allimages]
        image.splice(id, 1)
        setAllimages(image)
        const imgs = [...data.new_images]
        const thumb = [...data.new_images]
        const thumbnail = thumb.splice(parseInt(id) + 1, 1)
        imgs.splice(id, 1)
        setEdit({ ...data, new_images: imgs, thumbnail: thumbnail })
    }
    const handleSubmitImages = useCallback(async (val) => {
        if (val?.new_images) {
            if (val?.new_images?.length === 0) {
                toast.error("Please Select atleast One photo")
            } else if (val?.thumbnail.length === 0) {
                toast.error("Please Select Your Thumbnail")
            } else {
                setLoading(true);
                const apiResponse = await addImages(data?.id, val);
                if (apiResponse) {
                    if (apiResponse === 401) {
                        useLocalStorage.removeUser();
                        navigate('/login')
                    } else {
                        setAddImage(false);
                        setLoading(false);
                        fetchData();
                    }
                }
            }
        }
        else {
            toast.error("Please Select atleast One photo")
        }
    }, [])

    const handleThumbnail = useCallback((i, dt) => {
        const imgs = [...dt.new_images]
        const thumbnail = imgs.splice(i, 1)
        setEdit({ ...dt, thumbnail: thumbnail });
    }, [])

    const renderPhotos = (source) => {
        return source.map((photo, i) => (
            <div className={styles.img_preview_card}>
                <img className={styles.cancel_icon} onClick={handleRemoveImage} id={i} src={cancel} alt='img' />
                <img className={styles.imgs} src={photo} alt="" height="200px" width="100%" key={photo} />
                <div className={styles.radio_btn_div}>
                    <input type='radio' id={i} onClick={() => handleThumbnail(i, data)} value={data.thumbnail} name="thumbnail" />
                    <label htmlFor={i}>Set as Default</label>
                </div>
            </div>
        ));
    };
    return (
        <div className={styles.container}>
            {/* <p className={styles.back_btn} onClick={handleAddImage}><BiArrowBack />Back</p> */}
            <button className={styles.back_btn} onClick={handleAddImage}>
                <AiOutlineArrowLeft size={16} />
                Back to Properties
            </button>
            <h2 className={styles.main_head}>Add & Upload Photos</h2>
            <p className={styles.subhead}>Properties with images has high chance of getting responses. </p>
            <h6 className={styles.location}>{data && data?.name}</h6>
            <div className={styles.primary_img_box}>
                <label className={styles.add_img_btn} htmlFor="add_image" >
                    <img src={Addicon} alt="img" />
                    <input type='file' name="add_image" id="add_image" accept="image/*" hidden multiple onChange={handleSelectImage} />
                    <label htmlFor="add_image" style={{ cursor: "pointer" }}>Add Photo</label>
                </label>
            </div>
            <p className={styles.info}>You can upload upto 15 photos.</p>
            <p className={styles.info}>Formats accepted: .jpeg, .gif, .bmp & .png.</p>
            <p className={styles.info}>Maximum size for each photo should not exceed 20MB</p>

            {loading ? <div className={styles.upload_image_status} ><LoadingBar /> </div>
                : <div className={styles.upload_image_status} onClick={() => handleSubmitImages
                    (data)}>{allimages.length + "/15 Uploaded"}</div>

            }
            {/* <LoadingBar /> */}
            <div className={styles.preview_img_div}>{renderPhotos(allimages)}</div>
            <label htmlFor="add_image" onClick={handleSelectImage}><IoAdd size={20} />Add More Photos</label>
            <label onClick={handleAddImage}>Cancel</label>
        </div >
    );
};

export default Addphotos;
