import React from "react";

const Services = () => {
  return (
    <div className="wrapper landing-services">
      <div className="content-wrapper">
        <section>
          <h2>We bring to you a world-class product tailor-made for NRIs</h2>
          <p>
            Discover the assurance of reliable NRI services with transparent
            pricing, unwavering legal compliance, and over 15 years of seasoned
            expertise.
          </p>
        </section>
        <section>
          <ul>
            <li>
              <span>7000+</span>
              <h4>Questions Answered</h4>
            </li>
            <li>
              <span>1200+</span>
              <h4>Happy Customers</h4>
            </li>
            <li>
              <span>6+</span>
              <h4>Countries Served</h4>
            </li>
            <li>
              <span>15+</span>
              <h4>Tailor-made plans</h4>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Services;
