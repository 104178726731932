import React, { useState, useEffect, useCallback } from "react";
import { RiDoubleQuotesL } from "react-icons/ri";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { showTestimonials } from "../../Api/testimonial";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    const apiResponse = await showTestimonials();
    if (apiResponse) {
      setTestimonials([...apiResponse]);
    } else {
      setTestimonials([]);
    }
    setCurrentIndex(0);
  }, []);

  const activeTestimonialHandler = useCallback((index) => {
    setCurrentIndex(index);
  }, []);

  return (
    <>
      {testimonials && testimonials.length > 0 && (
        <div className="wrapper wrapper-border-top landing-testimonials">
          <div className="wrapper-header">What our customers say about us</div>
          <div className="content-wrapper">
            <div className="testimonial-card">
              <RiDoubleQuotesL size={64} />
              <p>{testimonials[currentIndex].comment}</p>
            </div>
            <div className="testimonial-users">
              <ul>
                {testimonials.map((testimonial, index) => (
                  <li
                    key={"testimonial--" + testimonial.id}
                    className={currentIndex === index ? "active" : ""}
                  >
                    <button
                      type="button"
                      onClick={() => activeTestimonialHandler(index)}
                    >
                      <img
                        src={`https://nriconnect.io/realEstate/Uploads/${testimonial.image}`}
                      />
                    </button>
                  </li>
                ))}
              </ul>
              {testimonials.length > 1 && (
                <div className="testimonials-actions">
                  <button
                    type="button"
                    onClick={
                      currentIndex !== 0
                        ? () => activeTestimonialHandler(currentIndex - 1)
                        : null
                    }
                    className={currentIndex !== 0 ? "active" : ""}
                  >
                    <AiOutlineLeft size={20} />
                  </button>
                  <button
                    type="button"
                    onClick={
                      currentIndex !== testimonials.length - 1
                        ? () => activeTestimonialHandler(currentIndex + 1)
                        : null
                    }
                    className={
                      currentIndex !== testimonials.length - 1 ? "active" : ""
                    }
                  >
                    <AiOutlineRight size={20} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Testimonials;
