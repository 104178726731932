import React from "react";
import TopProperties from "./TopProperties";
import Works from "./Works";
import SellProperty from "./Sell Property/SellProperty";

const Details = (props) => {
  return (
    <div className="realestate-data-container">
      {props.selectedTab === "buy" ? <TopProperties /> : <SellProperty />}
      <Works />
    </div>
  );
};

export default Details;
