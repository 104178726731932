import React, { useCallback, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLocalStorage from '../../../utils/useLocalStorage';
import { editPropertyService } from '../../../Api/propertyServices';
import { useNavigate } from 'react-router-dom';

const EditPropertyService = ({ data, fetch, hideEditModal, logout }) => {
  const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;
  const navigate = useNavigate();
  const [form, setForm] = useState({
    ...data,
    imageSrc: `${BASE_URL}${data.icon}`
  })
  const [error, setError] = useState(false)

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }, [form])

  const uploadIcon = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      setForm({
        ...form,
        icon: e.target.files[0],
        imageSrc: URL.createObjectURL(e.target.files[0])
      })
    }
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    setError(false)
    const cleanData = {
      name: form.name.toLowerCase().trim(),
      icon: form.icon
    }
    if (cleanData.name || cleanData.icon) {
      let formData = new FormData();
      formData.append('name', cleanData.name);
      formData.append('icon', cleanData.icon);
      const apiResponse = await editPropertyService(data.id, formData);
      if (apiResponse) {
        if (apiResponse === 401) {
          useLocalStorage.removeAdmin();
          logout();
          navigate('/admin/login')
        } else {
          setForm({
            id: "",
            name: "",
            icon: "",
            imageSrc: ""
          })
        }
        hideEditModal();
        fetch();
      } else {
        setForm({
          ...cleanData,
          id: form.id,
          imageSrc: form.imageSrc
        })
      }
    } else {
      setError('please fill all the fields')
      setForm({
        ...cleanData,
        imageSrc: ""
      })
    }
  }, [form, hideEditModal, logout])

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Edit Property Service</h3>
          <button className='close-btn' onClick={hideEditModal} >
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='image-box'>
                <div className='image-wrapper'>
                  {form.imageSrc && (<img src={form.imageSrc} alt='' />)}
                </div>
                <div className='image-box-actions'>
                  <label htmlFor='icon'>Upload<span className='mandatory-field'>&#42;</span></label>
                  <input onChange={uploadIcon} name="icon" id='icon' type='file' />
                </div>
              </div>
              <div className='modal-input-box'>
                <label>Name<span className='mandatory-field'>&#42;</span></label>
                <input className='text-capitalize' name="name" value={form.name} onChange={changeHandler} required />
              </div>
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(EditPropertyService)