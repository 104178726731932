import React, { useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Navigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { login } from '../../Api/user';
import { loadUserRequest } from '../../Actions/user';
import './auth.css'

const Login = ({ loadUser }) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [form, setForm] = useState({
        email: "",
        password: ""
    })
    const search = useLocation().search
    const id = search.includes('?') ? search.split('?')[1].split('=')[1] : '';
    console.log(id,"id")
    

    const [error, setError] = useState(false);

    const changeHandler = useCallback((e) => {
        setForm(data => ({
            ...data,
            [e.target.name]: e.target.value
        }))
    }, [form])

    const submitHandler = async (e) => {
        e.preventDefault();
        setError(false)
        const cleanData = {
            email: form.email.trim(),
            password: form.password.trim()
        }

        if (cleanData.email && cleanData.password) {
            const response = await login(cleanData);
            if (response) {
                loadUser(response);
                setForm({
                    email: "",
                    password: ''
                })
                if(id){
                   navigate(`/services/financial/planDetails/${id}`) 
                }else{
                   navigate('/');
                }
            }
        } else {
            setForm(cleanData);
            setError('please fill all the mandatory fields');
        }
    }

    const showPasswordHandler = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])

    return (
        <div className='auth-container'>
            <div className='auth-background'></div>
            <div className='auth-wrapper'>
                <section>
                    <h1>Get Solutions to your Problems!</h1>
                    <Link to="">Get Started anyway</Link>
                </section>
                <section>
                    <div className='auth-box'>
                        <h2>Welcome</h2>
                        <p>Login to your account | <Link to="/signup">Sign up</Link></p>
                        <form onSubmit={submitHandler}>
                            <div className={form.email ? 'input-box active' : 'input-box'}>
                                <label>Email</label>
                                <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required onChange={changeHandler} value={form.email} name='email' placeholder='Enter your email' />
                            </div>
                            <div className={form.password ? 'input-box active' : 'input-box'}>
                                <label>Password</label>
                                <input required onChange={changeHandler} value={form.password} name='password' type={showPassword ? 'text' : 'password'} placeholder='Enter your password' />
                                <button type='button' onClick={showPasswordHandler}>{showPassword ? <FiEye size={16} /> : <FiEyeOff size={16} />}</button>
                            </div>
                            <div className='login-form-footer'>
                                <label className='checkbox-container'>Remember Me
                                    <input type='checkbox' />
                                    <span className='checkmark' />
                                </label>
                                <Link to="/forgotPassword">Forgot Password?</Link>
                            </div>
                            {error && (<p className='error-message'>{error}</p>)}
                            <button type='submit'>Login</button>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    loadUser: loadUserRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(Login)