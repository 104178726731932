import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import useLocalStorage from "../../../utils/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { MdOutlineBrokenImage } from "react-icons/md";
import { fetchPropertyById } from "../../../Api/serviceProvider";

const ServiceProviderPropertyDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [propertyData, setPropertyData] = useState(null);
  const [imageMain, setImageMain] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const apiResponse = await fetchPropertyById(id);
    if (apiResponse) {
        if (apiResponse === 401) {
          useLocalStorage.removeServiceProvider();
          navigate("/serviceProvider/login");
        } else {
            setPropertyData(apiResponse);
        }
    } else {
        setPropertyData(null)
    }
} 

  const setAsMainImage = (image) => {
    setImageMain(image);
  };

  return (
    <>
      <div className="admin-container">
        <div className="breadcrumbs">
          <p>
            ServiceProvider
            <b>
              / <Link to="/serviceProvider/properties">Properties</Link>/
              Property Details
            </b>
          </p>
          <span>Property Details</span>
        </div>
        <div className="content-wrapper">
          {propertyData && (
            <>
              <div className="table-view">
                <section>
                  <div className="image">
                    {imageMain ? (
                      <img
                        src={`https://nriconnect.io/realEstate/Uploads/${imageMain}`}
                      />
                    ) : (
                      <img
                        src={`https://nriconnect.io/realEstate/Uploads/${propertyData.thumbnail}`}
                      />
                    )}
                  </div>
                  {propertyData.images && (
                    <ul className="images">
                      <li>
                        <img
                          src={`https://nriconnect.io/realEstate/Uploads/${propertyData.thumbnail}`}
                          onClick={() => setAsMainImage(propertyData.thumbnail)}
                        />
                      </li>
                      {propertyData.images.split(",").map((image, index) => {
                        return (
                          <li>
                            <img
                              src={`https://nriconnect.io/realEstate/Uploads/${image}`}
                              onClick={() => setAsMainImage(image)}
                            />
                          </li>
                        );
                      })}
                      <span>
                        <MdOutlineBrokenImage size={16} />
                        {propertyData.images.split(",").length}
                      </span>
                    </ul>
                  )}
                </section>
                <section>
                  <div className="modal-grid">
                    <div className="modal-box">
                      <label>Name</label>
                      <p>{propertyData.name}</p>
                    </div>
                    <div className="modal-box">
                      <label>Price</label>
                      <p>{propertyData.price}</p>
                    </div>
                  </div>
                  <div className="modal-box">
                    <label>Location</label>
                    <textarea rows={6}>{propertyData.location_url}</textarea>
                  </div>
                  <div className="modal-grid">
                    <div className="modal-box">
                      <label>lattitude</label>
                      <p>{propertyData.lattitude}</p>
                    </div>
                    <div className="modal-box">
                      <label>longitude</label>
                      <p>{propertyData.longitude}</p>
                    </div>
                    <div className="modal-box">
                      <label>Address</label>
                      <p>
                        {propertyData.address}, {propertyData.city},{" "}
                        {propertyData.state}, {propertyData.country},{" "}
                        {propertyData.zipcode}
                      </p>
                    </div>
                    <div className="modal-box">
                      <label>Contact Email</label>
                      <p>{propertyData.contact_email}</p>
                    </div>
                    <div className="modal-box">
                      <label>Contact Number</label>
                      <p>{propertyData.contact_number}</p>
                    </div>
                    <div className="modal-box">
                      <label>Bedrooms</label>
                      <p>{propertyData.bedrooms}</p>
                    </div>
                    <div className="modal-box">
                      <label>Bathrooms</label>
                      <p>{propertyData.bathrooms}</p>
                    </div>
                    <div className="modal-box">
                      <label>Units</label>
                      <p>{propertyData.units}</p>
                    </div>
                    <div className="modal-box">
                      <label>Propery Type</label>
                      <p>{propertyData.property_type_name}</p>
                    </div>
                    <div className="modal-box">
                      <label>Builders:</label>
                      <p>{propertyData.builder_name}</p>
                    </div>
                    <div className="modal-box">
                      <label>Admins:</label>
                      <p>{propertyData.admin_name}</p>
                    </div>
                  </div>
                  <div className="modal-box">
                    <label>Services Available:</label>
                    <p>{propertyData.services}</p>
                  </div>
                  <div className="modal-box">
                    <label>Description</label>
                    <textarea rows={6}>{propertyData.description}</textarea>
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceProviderPropertyDetails;
