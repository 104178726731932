import axios from 'axios';
import useLocalStorage from "./useLocalStorage";

// const API_BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_API_BASE_URL : process.env.REACT_APP_DEVELOPMENT_API_BASE_URL;
const API_BASE_URL =  process.env.REACT_APP_PRODUCTION_API_BASE_URL ;

const api = {
    multipartApi: (url, method, data, userType) => {
        // userType --> 1-admin, 2 - service provider, 3 - end user
        let token = "";

        if (userType) {
            switch (userType) {
                case 1: {
                    token = useLocalStorage.getAdmin();
                    break;
                }
                case 2: {
                    token = useLocalStorage.getServiceProvider();
                    break;
                }
                case 3: {
                    token = useLocalStorage.getUser();
                    break;
                }
                default: {
                    token = "";
                    break;
                }
            }
        }

        try {
            return axios(`${API_BASE_URL}${url}`, {
                method: method,
                headers: {
                    'authorization': token,
                    'Content-Type': "multipart/form-data",
                },
                data: data
            }).then(result => {
                return result;
            }).catch(err => {
                return err.response;
            })
        } catch (error) {
            return false
        }
    },
    applicationApi: async (url, method, data, userType) => {
        // userType --> 1-admin, 2 - service provider, 3 - end user

        let token = "";

        if (userType) {
            switch (userType) {
                case 1: {
                    token = useLocalStorage.getAdmin();
                    break;
                }
                case 2: {
                    token = useLocalStorage.getServiceProvider();
                    break;
                }
                case 3: {
                    token = useLocalStorage.getUser();
                    break;
                }
                default: {
                    token = "";
                    break;
                }
            }
        }

        try {
            return axios(`${API_BASE_URL}${url}`, {
                method: method,
                headers: {
                    'authorization': token,
                    'Content-Type': "application/json",
                },
                data: data || {}
            }).then(result => {
                return result;
            }).catch(err => {
                return err.response;
            })
        } catch (error) {
            return false
        }

        // return await fetch(`http://83.136.219.147:8000/v1/api/${url}`, {
        //     method: method,
        //     mode: 'cors',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': token
        //     },
        //     body: data ? JSON.stringify(data) : ""
        // });
    }
}

export default api;