import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { logoutAdminRequest } from '../../../Actions/admin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLocalStorage from '../../../utils/useLocalStorage';
import { editFaq } from '../../../Api/faqs';
import { fetchFinancialPlansAddEditData, fetchFinancialPlansAddonsAddEditData } from '../../../Api/financialPlans';

const EditFaq = ({ hideEditModal, data, fetchData, logout }) => {

  const navigate = useNavigate();

  const [categories, setCategories] = useState([])
  const [plans, setPlans] = useState([]);
  const [form, setForm] = useState({ ...data })
  const [error, setError] = useState(false)

  const changeHandler = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }, [form])

  const submitHandler = useCallback(async (e) => {
    e.preventDefault();
    setError(false)
    const cleanData = {
      question: form.question.toLowerCase().trim(),
      answer: form.answer.toLowerCase().trim(),
      service_id: form.service_id,
      plan_id: form.plan_id
    }
    if (cleanData.question && cleanData.answer && cleanData.service_id) {
      const apiResponse = await editFaq(data.id, cleanData);
      if (apiResponse) {
        if (apiResponse === 401) {
          useLocalStorage.removeAdmin();
          logout();
          navigate('/admin/login')
        } else {
          setForm({
            question: "",
            answer: "",
            service_id: "",
            plan_id: 0,
            category_id: 0
          })
        }
        hideEditModal();
        fetchData()
      } else {
        setForm({
          ...cleanData,
          plan_id: form.plan_id,
          category_id: form.category_id
        })
      }
    } else {
      setError('please fill all the fields')
      setForm({
        ...cleanData,
        plan_id: form.plan_id,
        category_id: form.category_id
      })
    }
  }, [form, hideEditModal, logout])

  const fetchCategories = useCallback(async () => {
    const apiResponse = await fetchFinancialPlansAddEditData();
    if (apiResponse) {
      if (apiResponse === 401) {
        useLocalStorage.removeAdmin();
        logout();
        navigate('/admin/login');
      } else {
        if (apiResponse.financial_plans_categories) {
          setCategories([...apiResponse.financial_plans_categories])
        } else {
          setCategories([]);
        }
      }
    } else {
      setCategories([]);
    }
    setPlans([]);
    setForm({
      ...form
    })
  }, [categories, form])

  const fetchPlans = useCallback(async () => {
    if (form.category_id !== 0) {
      const response = await fetchFinancialPlansAddonsAddEditData({ category_id: form.category_id });
      if (response) {
        if (response === 401) {
          useLocalStorage.removeAdmin();
          navigate('/admin/login')
        } else {
          setPlans([...response.financial_plans])
        }
      } else {
        setPlans([]);
        setForm({
          ...form,
          plan_id: 0
        })
      }
    } else {
      setPlans([]);
      setForm({
        ...form,
        plan_id: 0
      })
    }
  }, [form, plans])

  useEffect(() => {
    if (form.service_id == 2) {
      fetchCategories();
    }
  }, [form.service_id])

  useEffect(() => {
    if (form.category_id != 2) {
      fetchPlans();
    }
  }, [form.category_id])
  console.log(form)

  return (
    <div className='modal-container'>
      <div className='modal'>
        <div className='header'>
          <h3>Add FAQ</h3>
          <button className='close-btn' onClick={hideEditModal} >
            <FaTimes size={16} />
          </button>
        </div>
        <div className='content'>
          <form onSubmit={submitHandler}>
            <div className='modal-input-grid'>
              <div className='modal-input-box'>
                <label>Service<span className='mandatory-field'>&#42;</span></label>
                <select name='service_id' value={form.service_id} onChange={changeHandler}>
                  <option value={0} disabled style={{ display: "none" }}></option>
                  <option value={1}>RealEstate</option>
                  <option value={2}>Financial</option>
                  <option value={3}>General</option>
                </select>
              </div>
            </div>
            {form.service_id == 2 && (
              <div className='modal-input-grid'>
                <div className='modal-input-box'>
                  <label>Category<span className='mandatory-field'>&#42;</span></label>
                  <select name="category_id" value={form.category_id} onChange={changeHandler}>
                    <option value={0} disabled selected style={{ display: 'none' }}>None</option>
                    {(categories && categories.length > 0) &&
                      categories.map(item => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                {(form.category_id != 0) && (
                  <div className='modal-input-box'>
                    <label>Plan<span className='mandatory-field'>&#42;</span></label>
                    <select name="plan_id" value={form.plan_id} onChange={changeHandler}>
                      <option value={0} disabled selected style={{ display: 'none' }}>None</option>
                      {(plans && plans.length > 0) &&
                        plans.map(item => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                )}
              </div>
            )}
            <div className='modal-input-box'>
              <label>Question<span className='mandatory-field'>&#42;</span></label>
              <textarea name="question" value={form.question} onChange={changeHandler} rows={6} required>{form.question}</textarea>
            </div>
            <div className='modal-input-box'>
              <label>Answer<span className='mandatory-field'>&#42;</span></label>
              <textarea name="answer" value={form.answer} onChange={changeHandler} rows={6} required>{form.answer}</textarea>
            </div>
            <div className='action-box'>
              {error && (<p className='error-message'>{error}</p>)}
              <button>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAdminRequest
}, dispatch)

export default connect(null, mapDispatchToProps)(EditFaq)