import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { MdOutlineBrokenImage } from "react-icons/md";
import { showProperty } from "../../../../Api/properties";
import GoogleMapReact from "google-map-react";
import "./property.css";
import country from "./../../../../Country";
import Select from "react-select";
import { realEstateEnquireNow } from "../../../../Api/realestateEnquiries";
import { BsCheck } from "react-icons/bs";

const Property = () => {
  const BASE_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_FILE_BASE_URL
      : process.env.REACT_APP_DEVELOPMENT_FILE_BASE_URL;

  const { id } = useParams();
  const [propertyData, setPropertyData] = useState(null);
  const [error, setError] = useState(false);
  const [imageMain, setImageMain] = useState("");
  const [services, setServices] = useState([]);
  const [images, setImages] = useState([]);

  const [form, setForm] = useState({
    email: "",
    phone: "",
    name: "",
    country: "",
  });

  const onChangeHandler = useCallback(
    (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form]
  );
  const fetchData = async () => {
    const apiResponse = await showProperty(id);
    if (apiResponse) {
      setPropertyData(apiResponse);
      if (apiResponse.services) {
        const temp = apiResponse.services.split(",");
        if (!temp[0]) {
          temp.splice(0, 1);
        }
        setServices(temp);
      } else {
        setServices([]);
      }
      if (apiResponse.images) {
        console.log(apiResponse)
        const temp = apiResponse?.images?.split(",");
        if (!temp[0]) {
          temp.splice(0, 1);
        }
        setImages(temp);
      } else {
        setImages([]);
      }
    } else {
      setServices([]);
      setImages([]);
      setPropertyData(null);
    }
  };

  var imageUrls = propertyData?.services?.split(",");

  const serviceNames = propertyData?.services_name?.split(",");
  useEffect(() => {
    fetchData();
  }, []);

  const enquiryHandler = useCallback(async () => {
    const cleanData = {
      email: form.email.toLowerCase().trim(),
      phone: form.phone,
      name: form.name,
      country: form.country,
      property_id: id,
    };
    if (cleanData.email && cleanData.phone) {
      if (cleanData.phone.length !== 10) {
        setError("please enter a valid phone number");
      } else {
        setError(false);
        const apiResponse = await realEstateEnquireNow(cleanData);
        if (apiResponse) {
          setForm({
            email: "",
            phone: "",
            name: "",
            country: "",
          });
        }
      }
    } else {
      setError("Please fill all mandatory fields");
    }
  }, [form]);

  const setAsMainImage = (image) => {
    setImageMain(image);
  };

  return (
    <>
      <div className="realestate-data-container">
        <div className="wrapper property">
          <div className="content-wrapper">
            {propertyData && (
              <>
                <section>
                  <div className="details">
                    <div className="image">
                      <span>Sale</span>
                      {imageMain ? (
                        <img src={`${BASE_URL}${imageMain}`} />
                      ) : (
                        <img src={`${BASE_URL}${propertyData.thumbnail}`} />
                      )}
                    </div>
                    <ul className="header">
                      <li>
                        <h3>{propertyData.name}</h3>
                        <span>{`${propertyData.city}, ${propertyData.state}`}</span>
                      </li>
                      <li>
                        <span>$ {propertyData.price}</span>
                      </li>
                    </ul>
                    {/* {propertyData.services && (
                      <ul className="services">
                        {services.map((service, index) => (
                          <li key={`property-service-${index}`}>
                            <img src={`${BASE_URL}${service}`} />
                          </li>
                        ))}
                      </ul>
                    )} */}
                    <p>
                      Description:
                      <br />
                      <br />
                      {propertyData.description}
                    </p>
                    <p>
                      {propertyData.rera
                        ? `RERA No.: ${propertyData.rera}`
                        : ""}
                    </p>
                    <p>Services:</p>
                    <div className="service-grid">
                      {imageUrls && imageUrls.map((imageUrl, i) => (
                        <div className="service-card">
                          <React.Fragment key={i}>
                            <img
                              src={`${BASE_URL}${imageUrl}`}
                              alt={`Service ${i + 1}`}
                            />
                            <h3>{serviceNames[i]}</h3>
                          </React.Fragment>
                        </div>
                      ))}
                    </div>
                    {propertyData.brochure ? (
                      <button type="button">
                        <a
                          href={`${BASE_URL}${propertyData.brochure}`}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download Brochure
                        </a>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div style={{ height: "100vh", width: "100%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_LOCATION_API_KEY,
                      }}
                      defaultCenter={{
                        lat: parseFloat(propertyData?.lattitude === null ? 0 : propertyData.lattitude),
                        lng: parseFloat(propertyData?.longitude === null ? 0 : propertyData.longitude),
                      }}
                      defaultZoom={11}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#000",
                        }}
                        lat={parseFloat(propertyData?.lattitude === null ? 0 : propertyData.lattitude)}
                        lng={parseFloat(propertyData?.longitude === null ? 0 : propertyData.longitude)}
                        text="My Marker"
                      />
                    </GoogleMapReact>
                  </div>
                </section>
                <section>
                  {propertyData.images && (
                    <ul className="images">
                      <li>
                        <img
                          src={`${BASE_URL}${propertyData.thumbnail}`}
                          onClick={() => setAsMainImage(propertyData.thumbnail)}
                        />
                      </li>
                      {images.map((image, index) => {
                        return (
                          <li key={`property-image-${index}`}>
                            <img
                              src={`${BASE_URL}${image}`}
                              onClick={() => setAsMainImage(image)}
                            />
                          </li>
                        );
                      })}
                      <span>
                        <MdOutlineBrokenImage size={16} />
                        {images.length + 1}
                      </span>
                    </ul>
                  )}
                  <div className="enquire">
                    <input
                      placeholder="Name:"
                      value={form.name}
                      name="name"
                      onChange={onChangeHandler}
                    />
                    <input
                      placeholder="Email:"
                      value={form.email}
                      name="email"
                      onChange={onChangeHandler}
                    />
                    <input
                      placeholder="Contact no:"
                      value={form.phone}
                      name="phone"
                      onChange={onChangeHandler}
                    />
                    <select
                      name="country"
                      value={form.country}
                      onChange={onChangeHandler}
                    >
                      <option value={0}>Country:</option>
                      {country &&
                        country.length > 0 &&
                        country.map((item) => (
                          <option value={item.label}>{item.label}</option>
                        ))}
                    </select>
                    <textarea>Hi, I am interested in this property.</textarea>
                    {error && <p className="error-message">{error}</p>}
                    <button type="button" onClick={enquiryHandler}>
                      Enquire Now
                    </button>
                  </div>
                </section>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Property;
