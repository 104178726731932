import { LOGOUT_SERVICE_PROVIDER, LOAD_SERVICE_PROVIDER } from '../utils/actionTypes';

const initialState = {
    isAuthenticated: false,
    data: {}
}

const serviceProvider = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SERVICE_PROVIDER: {
            return {
                isAuthenticated: true,
                data: action.payload
            }
        }
        case LOGOUT_SERVICE_PROVIDER: {
            return {
                isAuthenticated: false,
                data: {}
            }
        }
        default: {
            return state;
        }
    }
}

export default serviceProvider;