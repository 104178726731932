import React, { useCallback, useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import "./uploaddocs.css";
import SignDocs from "./SignDocs";
import TitleDeed from "../../../../Components/Popup/User/TitleDeed";

function UploadDocs({ form, setForm, onFilechangeHandler, onBackClick }) {
  const [nextStep, setNextStep] = useState(false);
  const [doc, setDoc] = useState(null);
  const [error, setError] = useState("");
  const [popup, setPopup] = useState(false);
  const handleSubmit = () => {
    if (!form.title_deed) {
      // If the Title Deed field is empty, set the generic error message
      setError("Please upload the Title Deed.");
    } else {
      onBackClick(4);
    }
  };
  const onUploadDocs = () => {
    setNextStep(false);
  };
  const removeFile = useCallback((fieldName) => {
    setForm((prevForm) => ({
      ...prevForm,
      [fieldName]: "", // Clear the specified field
    }));
  }, []);

  const confirmBoxHandler = () => {
    setPopup(!popup);
  };
  return (
    <div className="property-detail">
      {popup ? <TitleDeed hideConfirmBox={confirmBoxHandler} /> : ""}
      {/* {nextStep ? (
        <SignDocs
          form={form}
          setForm={setForm}
          onFilechangeHandler={onFilechangeHandler}
          onBackClick={onUploadDocs}
        />
      ) : ( */}

      {/* <div className="heading">
          <p>Upload Documents</p>
          <p>Step 3/4</p>
        </div> */}
      <div className="details">
        <div className="note">
          <p>
            <span>Note* </span>: Required documents to be used only for property
            verification.
          </p>
        </div>
        <div className="title-deed">
          <div className="heading">
            <p>Title Deed</p>
            <AiOutlineInfoCircle onClick={() => setPopup(true)} />
          </div>
          <div className="upload-data">
            <div className="file-box-actions">
              {form.title_deed ? (
                <div className="file-detail1">
                  <p>{form.title_deed.name}</p>
                  <div
                    className="cancel-btn"
                    onClick={() => removeFile("title_deed")}
                  ></div>
                </div>
              ) : (
                <>
                  <label htmlFor="title_deed">
                    <div className="data"></div>
                  </label>
                  <input
                    onChange={onFilechangeHandler}
                    name="title_deed"
                    id="title_deed"
                    type="file"
                    hidden
                  />
                  <div className="file-detail">
                    <p>Title Deed</p>
                    <p>Drop or upload file here</p>
                    <p>Allowed files pdf, docs, jpg, svg within 2mb</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="fard">
          <div className="heading">
            <p>Fard (optional)</p>
            <AiOutlineInfoCircle />
          </div>
          <div className="upload-data">
            <div className="file-box-actions">
              {form.fard ? (
                <div className="file-detail1">
                  <p>{form.fard.name}</p>
                  <div
                    className="cancel-btn"
                    onClick={() => removeFile("fard")}
                  ></div>
                </div>
              ) : (
                <>
                  <label htmlFor="fard">
                    <div className="data"></div>
                  </label>
                  <input
                    onChange={onFilechangeHandler}
                    name="fard"
                    id="fard"
                    type="file"
                    hidden
                  />
                  <div className="file-detail">
                    <p>Fard</p>
                    <p>Drop or upload file here</p>
                    <p>Allowed files pdf, docs, jpg, svg within 2mb</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <p className="error-message">{error && <p>{error}</p>}</p>
        <div className="footer-btn">
          <button>
            <p onClick={() => onBackClick(2)}>Back</p> <AiOutlineArrowLeft />
          </button>
          <button type="button" onClick={handleSubmit}>
            <p>Continue</p> <AiOutlineArrowRight />
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

export default UploadDocs;
