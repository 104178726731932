import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Search from "./Search";

const Hero = (props) => {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate(-1);
  };

  const findPlan = () => {
    navigate("/services/financial?search=&sort=0");
  };
  const handleTabChange = (tab) => {
    props.setSelectedTab(tab);
  };
  return (
    <div
      className={
        path.includes("searchResults")
          ? "wrapper realestate-hero"
          : path.includes("/realestate/propertyDetails")
          ? "wrapper realestate-hero short"
          : "wrapper realestate-hero realestate-hero-background"
      }
    >
      <div className="content-wrapper">
        {!path.includes("/realestate/propertyDetails") && (
          <>
            <ul className="services-tabs">
              <li>
                <div />
                <h3>Real Estate</h3>
              </li>
              <li onClick={findPlan}>
                <div />
                <h3>Financial Services</h3>
              </li>
              {/* <li>
                            <div />
                            <h3>Medical Services</h3>
                        </li> */}
            </ul>
            <ul className="services-tabs">
              <li
                className={props.selectedTab === "buy" ? "active" : ""}
                onClick={() => handleTabChange("buy")}
              >
                <p>Buy Property</p>
              </li>
              <li
                className={props.selectedTab === "sell" ? "active" : ""}
                onClick={() => handleTabChange("sell")}
              >
                <p>Sell Property</p>
              </li>
            </ul>
          </>
        )}
        {path.includes("/realestate/propertyDetails") && (
          <button className="back-btn" onClick={goBackHandler}>
            <AiOutlineArrowLeft size={16} />
            Back to Find Property
          </button>
        )}
        {props.selectedTab === "buy" ? <Search /> : ""}
      </div>
    </div>
  );
};

export default Hero;
