import { combineReducers } from 'redux';
import sidebar from "./sidebar";
import user from './user';
import admin from './admin';
import serviceProvider from './serviceProvider';


const rootReducer = combineReducers({
    sidebar,
    user,
    admin,
    serviceProvider,
});

export default rootReducer;